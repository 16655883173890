import { styled, Box } from "@mui/material";

export const ProjectWrapper = styled(Box)(({ theme }) => ({
  width: "calc( 100vw - 64px)",
  marginLeft: "64px",
  height: "100%",
  // overflowX: "hidden",
  [theme.breakpoints.down("sm")]: {
    width: "100vw",
    marginLeft: 0,
  },
}));

export const SectionOneWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  height: "auto",
  marginTop: theme.spacing(20),
  marginLeft: theme.spacing(10),
  textTransform: "uppercase",
  "& h1": {
    fontFamily: "Mohave",
    fontStyle: "italic",
    fontWeight: 700,
    fontSize: "90px",
    transition: "0.5s",
  },
  [theme.breakpoints.down("md")]: {
    marginLeft: theme.spacing(5),
    "& h1": {
      fontSize: "60px",
    },
    "& p": {
      fontSize: "22px",
    },
  },
  [theme.breakpoints.down("sm")]: {
    marginLeft: theme.spacing(2.5),
    "& h1": {
      fontSize: "40px",
    },
    "& p": {
      fontSize: "15px",
    },
  },
}));

export const SectionTwoWrapper = styled(Box)(({ theme }) => ({
  // position: "relative",
  height: "auto",
  marginTop: theme.spacing(7),
  marginLeft: theme.spacing(5),
  // overflowX: "hidden",
  display: "flex",
}));

export const SectionThreeWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(15),
  position: "relative",
  width: "100%",
  maxWidth: "960px",
  marginLeft: theme.spacing(7.5),
  "& p": {
    lineHeight: "30px",
    color: "#000",
  },
  "@keyframes rotation": {
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: "rotate(360deg)",
    },
  },
  [theme.breakpoints.down("lg")]: {
    width: "90%",
    marginLeft: theme.spacing(5),
  },
  [theme.breakpoints.down("md")]: {
    width: "90%",
    marginLeft: theme.spacing(5),
  },
  [theme.breakpoints.down("sm")]: {
    width: "95%",
    marginLeft: theme.spacing(2),
  },
}));
export const SectionFourWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(15),
  width: "100%",
  maxWidth: "960px",
  marginLeft: theme.spacing(7.5),
  "& p": {
    lineHeight: "30px",
    color: "#000",
  },
  ".lineAnimation.active": {
    animation: "pencil 1.5s forwards",
    animationDelay: "1s",
  },
  "@keyframes pencil": {
    "0%": {
      transform: "translateX(0)",
    },
    "100%": {
      transform: "translateX(320px)",
    },
  },
  "@keyframes pencil2": {
    "0%": {
      transform: "translateX(0)",
    },
    "100%": {
      transform: "translateX(-320px)",
    },
  },
  [theme.breakpoints.down("lg")]: {
    width: "90%",
    marginLeft: theme.spacing(5),
  },
  [theme.breakpoints.down("md")]: {
    width: "90%",
    marginLeft: theme.spacing(5),
  },
  [theme.breakpoints.down("sm")]: {
    width: "95%",
    marginLeft: theme.spacing(2),
  },
}));

export const SectionFiveWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(15),
  width: "100%",
  maxWidth: "960px",
  height: "auto",
  marginLeft: theme.spacing(7.5),
  "& .opacity1": {
    opacity: "1 !important",
  },
  "& p": {
    lineHeight: "30px",
    color: "#000",
  },
  [theme.breakpoints.down("lg")]: {
    width: "90%",
    marginLeft: theme.spacing(5),
  },
  [theme.breakpoints.down("md")]: {
    width: "90%",
    marginLeft: theme.spacing(5),
  },
  [theme.breakpoints.down("sm")]: {
    width: "95%",
    marginLeft: theme.spacing(2),
  },
}));

export const SectionSeventeenWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(15),
  width: "100%",
  maxWidth: "960px",
  position: "relative",
  height: "160vh",
  marginLeft: theme.spacing(7.5),
  [theme.breakpoints.down("lg")]: {
    width: "90%",
    height: "100%",
    marginLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  [theme.breakpoints.down("md")]: {
    width: "90%",
    marginLeft: theme.spacing(5),
  },
  [theme.breakpoints.down("sm")]: {
    width: "95%",
    marginLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export const SectionEighteenWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(15),
  width: "calc(100vw - 64px)",
  position: "relative",
  height: "auto",
  marginLeft: theme.spacing(7.5),
  paddingRight: theme.spacing(7.5),
  "& *": {
    zIndex: 0,
  },
  "& img": {
    transform: "scale(1)",
    transition: "0.3s",
    width: "436px",
    height: "auto",
    boxShadow: "4px 0px 20px 0px #66666640",
    margin: "30px",
    borderRadius: "12px",
    "&:hover": { transform: "scale(1.5)", zIndex: 99 },
  },
  [theme.breakpoints.down("md")]: {
    "& img": {
      transform: "scale(1)",
      transition: "0.3s",
      width: "250px",
      height: "auto",
      boxShadow: "4px 0px 20px 0px #66666640",
      margin: "12px",
      borderRadius: "12px",
      "&:hover": { transform: "scale(1.5)", zIndex: 99 },
    },
  },
}));
