export function scrollTrigger(selector: any) {
  let els: any = document.querySelectorAll(selector);
  els = Array.from(els);
  els.forEach((el: any) => {
    addObserver(el);
  });
}

export function stickyTrigger(selector: any) {
  let els: any = document.querySelectorAll(selector);
  els = Array.from(els);
  els.forEach((el: any) => {
    addStickyObserver(el);
  });
}
function addObserver(el: any) {
  // We are creating a new IntersectionObserver instance
  let observer = new IntersectionObserver((entries, observer) => {
    // This takes a callback function that receives two arguments: the elements list and the observer instance.
    entries.forEach((entry) => {
      // `entry.isIntersecting` will be true if the element is visible
      if (entry.isIntersecting) {
        entry.target.classList.add("active");
        // We are removing the observer from the element after adding the active class
        observer.unobserve(entry.target);
      }
    });
  });
  // Adding the observer to the element
  observer.observe(el);
}

function addStickyObserver(el: any) {
  var observer = new IntersectionObserver(
    function (entries) {
      entries.forEach((entry) => {
        // `entry.isIntersecting` will be true if the element is visible
        if (entry.boundingClientRect.y === 50) {
          entry.target.classList.add("opacity1");
          // We are removing the observer from the element after adding the active class
          observer.unobserve(entry.target);
        } else if (entry.intersectionRatio === 1) {
          entry.target.classList.remove("opacity1");
        }
      });
    },
    { threshold: [0, 1] }
  );
  observer.observe(el);
}
