import { useRef, useEffect, useState } from "react";
import {
  ProjectWrapper,
  SectionEightWrapper,
  SectionFifteenWrapper,
  SectionFourWrapper,
  SectionOneWrapper,
  SectionSeventeenWrapper,
  SectionSevenWrapper,
  SectionThirteenWrapper,
  SectionThreeWrapper,
  SectionTwoWrapper,
} from "./styled";

import { Box, Grid, IconButton, Theme, Typography } from "@mui/material";
import Databass1 from "../../Assets/images/databass1.png";
import Databass2 from "../../Assets/images/databass2.png";
import Databass3 from "../../Assets/images/databass3.png";
import Databass4 from "../../Assets/images/databass4.png";
import Databass5 from "../../Assets/images/databass5.png";
import Databass6 from "../../Assets/images/databass6.png";
import Databass7 from "../../Assets/images/databass7.png";
import Databass8 from "../../Assets/images/databass8.png";
import DatabassUser1 from "../../Assets/images/databass_user1.png";
import DatabassUser2 from "../../Assets/images/databass_user2.png";

import Ideas from "../../Assets/images/ideas.png";

import { AnimationOnScroll } from "react-animation-on-scroll";
import { East, PauseRounded, PlayArrowRounded } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";

import SmileIcon from "../../Assets/Icons/SmileIcon";
import Line2 from "../../Assets/Icons/Line2";
import { scrollTrigger } from "../../Helper/scrollAnimation";

import ToolTipIcons from "../../Assets/Icons/ToolTipIcons";
import CircleIcon from "../../Assets/Icons/CircleIcon";
import TriangleIcon from "../../Assets/Icons/TriangleIcon";
import SqaureIcon from "../../Assets/Icons/SqaureIcon";

import withAuth from "../../HOC/withAuth";
import SoundExample from "../../Assets/Icons/SoundExample";
import Lines from "../../Assets/Icons/Lines";
import Before from "../../Assets/sound/before.wav";
import After from "../../Assets/sound/after.wav";
import useSound from "use-sound";
import { AudioVisualizer } from "react-audio-visualize";

const Project4 = () => {
  const { pathname } = useLocation();

  const [playing, setPlaying] = useState(false);
  const [playing2, setPlaying2] = useState(false);
  const [blob, setBlob] = useState<any>();
  const [blob2, setBlob2] = useState<any>();
  const visualizerRef = useRef<HTMLCanvasElement>(null);
  const visualizer2Ref = useRef<HTMLCanvasElement>(null);
  const [play, { stop }] = useSound(Before);
  const [play2, playOptions] = useSound(After);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      scrollTrigger(".lineAnimation");
    });
    getFiles();
  }, []);

  const getFiles = async () => {
    const file = await fetch("/files/before.wav");
    file.blob().then((blobData) => setBlob(blobData));

    const file2 = await fetch("/files/after.wav");
    file2.blob().then((blobData) => setBlob2(blobData));
  };

  return (
    <ProjectWrapper>
      <Box
        sx={{
          position: "fixed",
          width: "1px",
          height: "1px",
          background: (theme: Theme) => theme.palette.primary.main,
          top: 0,
          zIndex: 999999999999,
          left: 0,
          borderRadius: "100%",
          animation: "animateCircle1 0.5s",
          animationIterationCount: 1,
          "@keyframes animateCircle1": {
            from: {
              transform: "scale(4000)",
            },
            to: { transform: "scale(0)" },
          },
        }}
      ></Box>
      <SectionOneWrapper>
        <Typography variant="h1">DATABASS AI</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { sm: "row", xs: "column" },
            "& p": {
              fontWeight: 500,
              fontSize: { md: "28px", xs: "22px" },
            },
          }}
        >
          <Typography>GENERATIVE AI FOR MUSIC PRODUCTION</Typography>
          <Typography sx={{ ml: { sm: 6 } }}>3-DAY HACKATHON</Typography>
        </Box>
      </SectionOneWrapper>
      <SectionTwoWrapper>
        <ParallaxProvider>
          <Parallax
            // speed={-10}
            translateX={["17", "-20"]}
            easing="easeOutQuad"
            endScroll={100}
          >
            <Box
              sx={{
                display: "flex",
                "& img": {
                  width: "auto",
                  height: { lg: "550px", sm: "350px", xs: "200px" },
                  boxShadow: "4px 0px 20px 0px #66666640",
                  borderRadius: "12px",
                },
                "& div": {
                  mx: 1.75,
                },
              }}
            >
              <Box>
                <img src={Databass1} alt="No Banner" />
              </Box>
              <Box>
                <img src={Databass2} alt="No Banner" />
              </Box>
              <Box>
                <img src={Databass3} alt="No Banner" />
              </Box>
            </Box>
          </Parallax>
        </ParallaxProvider>
      </SectionTwoWrapper>
      <SectionThreeWrapper id="section1">
        <Box>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
          >
            <Typography
              variant="h3"
              sx={{ fontSize: { md: "28px", xs: "22px" }, fontWeight: 600 }}
            >
              What is Databass AI?
            </Typography>
          </AnimationOnScroll>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
            delay={300}
          >
            <Typography sx={{ fontSize: { md: "22px", sm: "18px" }, mt: 2.5 }}>
              Databass AI is a generative solution for music production.
              Primarily geared towards music producers, it helps them generate
              the sample audio they need with just a text description,
              eliminating the need for them to browse hundreds of different
              samples.
            </Typography>
          </AnimationOnScroll>

          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
            delay={300}
          >
            <Box
              sx={{
                py: 3.5,
                px: 4,
                mt: 8,
                background: (theme: Theme) => theme.palette.secondary.main,
              }}
            >
              <Typography
                sx={{
                  fontSize: { md: "28px", xs: "22px" },
                  fontWeight: 600,
                  lineHeight: "33.89px",
                }}
              >
                Databass AI is already out in the market, which made it easier
                to gather insights from analytics and also talk to some of our
                current users.
              </Typography>
            </Box>
          </AnimationOnScroll>
        </Box>
        <Box sx={{ mt: 10 }}>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
          >
            <Typography
              variant="h3"
              sx={{ fontSize: { md: "28px", xs: "22px" }, fontWeight: 600 }}
            >
              Problem Statement/Description
            </Typography>
          </AnimationOnScroll>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
            delay={300}
          >
            <Typography sx={{ fontSize: { md: "22px", sm: "18px" }, mt: 2.5 }}>
              Everyone interprets music differently. Ex: EDM could mean
              different things to different people. Keeping that in mind,{" "}
              <b>
                how do we ensure that the AI model produces an output that the
                user wanted?
              </b>
            </Typography>
          </AnimationOnScroll>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
          >
            <Typography
              variant="h3"
              sx={{
                fontSize: { md: "28px", xs: "22px" },
                fontWeight: 600,
                mt: 6,
              }}
            >
              Example...
            </Typography>
          </AnimationOnScroll>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
            delay={300}
          >
            <Box
              sx={{
                mt: 2,
                "& svg": {
                  width: "fit-content",
                  maxWidth: "100%",
                  height: "fit-content",
                },
              }}
            >
              <SoundExample />
            </Box>
          </AnimationOnScroll>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
            delay={300}
          >
            <Box
              sx={{
                py: 3.5,
                px: 4,
                mt: 8,
                background: (theme: Theme) => theme.palette.secondary.main,
              }}
            >
              <Typography
                sx={{
                  fontSize: { md: "28px", xs: "22px" },
                  fontWeight: 600,
                  lineHeight: "33.89px",
                }}
              >
                How did we figure out that this was a problem to be solved? Ans.
                Poor feedback received on the generated audio. User feedback
                collected showcased that the generated audio was a hit-or-miss.
              </Typography>
            </Box>
          </AnimationOnScroll>
        </Box>
        <Box sx={{ mt: 10 }} id="section2">
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
          >
            <Typography
              variant="h3"
              sx={{
                fontSize: { md: "40px", sm: "32px", xs: "28px" },
                fontWeight: 700,
                fontFamily: "Mohave",
                fontStyle: "italic",
              }}
            >
              MY ROLE
            </Typography>
          </AnimationOnScroll>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
          >
            <Box sx={{ mt: 1.5, position: "relative" }}>
              <Box
                sx={{
                  position: "absolute",
                  left: "14%",
                  top: "21%",
                  zIndex: -1,
                }}
              >
                <CircleIcon />
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  left: "51%",
                  top: "-20%",
                  zIndex: -1,
                }}
              >
                <TriangleIcon />
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  right: "6%",
                  top: "21%",
                  zIndex: -1,
                }}
              >
                <SqaureIcon />
              </Box>
              <Typography
                variant="h3"
                sx={{ fontSize: { md: "28px", xs: "22px" }, fontWeight: 600 }}
              >
                Uncovering user motivations (Interviews) -&gt; Conceptualizing
                solutions -&gt; Designing -&gt; Usability Testing
              </Typography>
            </Box>
          </AnimationOnScroll>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
            delay={300}
          >
            <Box
              sx={{
                py: 3.5,
                px: 4,
                mt: 9,
                background: (theme: Theme) => theme.palette.secondary.main,
                display: "flex",
              }}
            >
              <Typography>
                <SmileIcon />
              </Typography>
              <Typography
                sx={{
                  fontSize: { md: "28px", xs: "22px" },
                  fontWeight: 600,
                  lineHeight: "33.89px",
                  ml: 2,
                }}
              >
                I worked directly with the co-founder of Databass AI on this
                project, thanks to the Designpreneurs Hackathon.
              </Typography>
            </Box>
          </AnimationOnScroll>
        </Box>
      </SectionThreeWrapper>
      <SectionFourWrapper>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: { md: "40px", sm: "32px", xs: "28px" },
              fontWeight: 700,
              fontFamily: "Mohave",
              fontStyle: "italic",
            }}
          >
            UNDERSTANDING USER BEHAVIOR
          </Typography>
        </AnimationOnScroll>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
          delay={300}
        >
          <Box
            sx={{
              py: 3.5,
              px: 4,
              mt: 9,
              background: (theme: Theme) => theme.palette.secondary.main,
            }}
          >
            <Typography
              sx={{
                fontSize: { md: "28px", xs: "22px" },
                fontWeight: 600,
                lineHeight: "33.89px",
                ml: 2,
              }}
            >
              We interviewed 2 users, one of them being an avid user, and the
              other one an occasional user, both of them use Databass for
              generating samples.
            </Typography>
          </Box>
        </AnimationOnScroll>
      </SectionFourWrapper>

      <SectionSevenWrapper>
        <Typography
          variant="h2"
          sx={{
            fontSize: { md: "28px", xs: "22px" },
            fontWeight: 600,
            lineHeight: "33.89px",
          }}
        >
          Uncovering Problems - Insights Captured from the interviews
        </Typography>

        <Box>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
            delay={300}
          >
            <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
              <Box color="#FFA800">
                <East color="inherit" />
              </Box>
              <Typography
                sx={{ ml: 1, fontSize: { md: "22px", sm: "18px", xs: "16px" } }}
              >
                Participants <b>currently use Ableton or FL Studio</b> for music
                production.
              </Typography>
            </Box>
          </AnimationOnScroll>

          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
            delay={300}
          >
            <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
              <Box color="#FFA800">
                <East color="inherit" />
              </Box>
              <Typography
                sx={{ ml: 1, fontSize: { md: "22px", sm: "18px", xs: "16px" } }}
              >
                They use Databass AI to{" "}
                <b>get inspired, or to build on an idea.</b>
              </Typography>
            </Box>
          </AnimationOnScroll>

          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
            delay={300}
          >
            <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
              <Box color="#FFA800">
                <East color="inherit" />
              </Box>
              <Typography
                sx={{ ml: 1, fontSize: { md: "22px", sm: "18px", xs: "16px" } }}
              >
                Even professional music producers do not describe music in
                specific terminologies like “A fusion of flamenco and
                electronic, with passionate guitar strumming and digital beats,
                evoking the modern vibes of Mediterranean nights.”.
              </Typography>
            </Box>
          </AnimationOnScroll>

          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
            delay={300}
          >
            <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
              <Box color="#FFA800">
                <East color="inherit" />
              </Box>
              <Typography
                sx={{ ml: 1, fontSize: { md: "22px", sm: "18px", xs: "16px" } }}
              >
                Instead, <b>they use natural language to describe music.</b>
              </Typography>
            </Box>
          </AnimationOnScroll>

          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
            delay={300}
          >
            <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
              <Box color="#FFA800">
                <East color="inherit" />
              </Box>
              <Typography
                sx={{ ml: 1, fontSize: { md: "22px", sm: "18px", xs: "16px" } }}
              >
                When asked how they customize the audio sample that they
                generate or find somewhere else, we discovered that{" "}
                <b>
                  BPM, Key, and Mode is what they fundamentally need to
                  customize an audio file.
                </b>
              </Typography>
            </Box>
          </AnimationOnScroll>

          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
            delay={300}
          >
            <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
              <Box color="#FFA800">
                <East color="inherit" />
              </Box>
              <Typography
                sx={{ ml: 1, fontSize: { md: "22px", sm: "18px", xs: "16px" } }}
              >
                They start the text-to-music process from the start if they
                aren’t satisfied with generated audio file.
              </Typography>
            </Box>
          </AnimationOnScroll>

          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
            delay={300}
          >
            <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
              <Box color="#FFA800">
                <East color="inherit" />
              </Box>
              <Typography
                sx={{ ml: 1, fontSize: { md: "22px", sm: "18px", xs: "16px" } }}
              >
                <b>
                  Getting recommendations is something that they greatly
                  appreciate,
                </b>{" "}
                since it helps them generate new ideas.
              </Typography>
            </Box>
          </AnimationOnScroll>
        </Box>
        <Box sx={{ mt: 10 }}>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
            delay={300}
          >
            <Typography
              variant="h3"
              sx={{
                fontSize: { md: "40px", sm: "32px", xs: "28px" },
                fontWeight: 700,
                fontFamily: "Mohave",
                fontStyle: "italic",
              }}
            >
              BRAINSTORMING SOLUTIONS
            </Typography>
          </AnimationOnScroll>
          <Grid container sx={{ mt: 3 }}>
            <Grid
              item
              md={4}
              sm={6}
              xs={12}
              alignItems={"center"}
              sx={{
                "& img": {
                  width: "100%",
                  height: "auto",
                },
              }}
            >
              <img src={Ideas} alt="ideas" />
            </Grid>
            <Grid item md={8} sm={6} xs={12}>
              <Box
                sx={{
                  py: 3.5,
                  px: 4,
                  mt: 9,
                  ml: { sm: 8, xs: 1 },
                  background: (theme: Theme) => theme.palette.secondary.main,
                }}
              >
                <Typography
                  sx={{
                    fontSize: { md: "28px", xs: "22px" },
                    fontWeight: 600,
                    lineHeight: "33.89px",
                    ml: { sm: 2, xs: 0 },
                  }}
                >
                  I validated the feasibility of all my ideas directly with the
                  co-founder. We needed to validate/debunk our ideas quickly
                  since it was only a 3-day hackathon.
                  <br /> <br />
                  We tested our solutions with users after we had a concept
                  ready.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </SectionSevenWrapper>
      <SectionEightWrapper id="section3">
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: { md: "40px", sm: "32px", xs: "28px" },
              fontWeight: 700,
              fontFamily: "Mohave",
              fontStyle: "italic",
            }}
          >
            THE SOLUTION
          </Typography>
        </AnimationOnScroll>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Typography
            sx={{
              fontSize: { md: "28px", sm: "18px" },
              fontWeight: 600,
              color: "text.primary",
              mt: 3,
            }}
          >
            We centered our solution around these 3 things...
          </Typography>
        </AnimationOnScroll>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: { sm: "row", xs: "column" },
            mt: 3.5,
            "& div": {
              fontSize: { md: "28px", sm: "18px" },
              fontWeight: 600,
              color: "#000",
              whiteSpace: "nowrap",
            },
          }}
        >
          <Box
            sx={{
              py: 3.5,
              px: 4,
              background: (theme: Theme) => theme.palette.secondary.main,
            }}
          >
            Personalization
          </Box>
          <Box sx={{ transform: { sm: "rotate(0deg)", xs: "rotate(90deg)" } }}>
            <Lines />
          </Box>
          <Box
            sx={{
              py: 3.5,
              px: 4,
              background: (theme: Theme) => theme.palette.secondary.main,
            }}
          >
            AI - Assistance
          </Box>
          <Box sx={{ transform: { sm: "rotate(0deg)", xs: "rotate(90deg)" } }}>
            <Lines />
          </Box>
          <Box
            sx={{
              py: 3.5,
              px: 4,
              background: (theme: Theme) => theme.palette.secondary.main,
            }}
          >
            Granular tweaking
          </Box>
        </Box>
      </SectionEightWrapper>

      <SectionThirteenWrapper>
        <Box
          sx={{
            position: { sm: "absolute", xs: "relative" },
            top: { xl: "22%", md: "10%", sm: "0%" },
            left: {
              lg: "calc(50% - 200px)",
              md: "calc(50% - 150px)",
              sm: "calc(50% - 220px)",
            },
            width: { lg: 350, xs: 300 },
            transform: { md: "translateX(-50%)" },
            display: { sm: "block", xs: "none" },
          }}
        >
          <AnimationOnScroll
            animateOnce={true}
            delay={500}
            animateIn="animate__flipInX"
          >
            <Box
              sx={{
                py: 2,
                px: 2.5,
                background: (theme: Theme) => theme.palette.secondary.main,
                fontSize: { md: "20px", sm: "15px" },
                fontWeight: 600,
                fontFamily: "Inter",
              }}
            >
              Understanding the style of a particular genre that a user likes -
              through artists
            </Box>
          </AnimationOnScroll>
        </Box>

        <Box
          sx={{
            position: "absolute",
            display: { sm: "block", xs: "none" },
            top: { xl: "75%", sm: "63%" },
            left: {
              lg: "calc(50% - 250px)",
              md: "calc(50% - 200px)",
              sm: "calc(50% - 270px)",
            },
            width: { lg: "450px", sm: "350px" },
            transform: { md: "translateX(-50%)" },
          }}
        >
          <AnimationOnScroll
            animateOnce={true}
            delay={500}
            animateIn="animate__flipInX"
          >
            Since there are different kinds of rap, users are prompted to select
            the artist(s) that reflect the rap genre the best according to them.
          </AnimationOnScroll>
        </Box>
        <Box
          className="lineAnimation"
          sx={{
            position: "absolute",
            display: { sm: "block", xs: "none" },
            top: { xl: "60%", xs: "40%" },
            left: { xl: "33.7%", md: "28.7%", sm: "45%" },
            width: "310px",
            height: "90px",
            background: "#fff",
          }}
        ></Box>
        <Box
          sx={{
            position: "absolute",
            display: { sm: "block", xs: "none" },
            top: { xl: "60%", xs: "40%" },
            left: { xl: "33.7%", md: "28.7%", sm: "45%" },
            zIndex: -1,
            transform: "rotateX(180deg)",
          }}
        >
          <Line2 />
        </Box>
        <AnimationOnScroll
          animateOnce={true}
          animateIn={"animate__fadeInRight"}
        >
          <Box
            sx={{
              textAlign: { sm: "right", xs: "center" },
              transform: {
                lg: "translateX(290px)",
                md: "translateX(350px)",
                sm: "translateX(400px)",
              },
              "& img": {
                width: { lg: "950px", md: "850px", sm: "650px", xs: "90%" },
                height: "auto",
                borderRadius: "12px",
                boxShadow: "-4px 4px 20px 0px #66666640",
              },
            }}
          >
            <img src={Databass4} alt="No Content" />
          </Box>
        </AnimationOnScroll>
        <Box
          sx={{
            position: "relative",
            width: "90%",
            mx: "auto",
            mt: 3,
            display: { sm: "none", xs: "block" },
          }}
        >
          <AnimationOnScroll
            animateOnce={true}
            delay={500}
            animateIn="animate__flipInX"
          >
            <Box
              sx={{
                py: 2,
                px: 2.5,
                background: (theme: Theme) => theme.palette.secondary.main,
                fontSize: { md: "20px", sm: "15px" },
                fontWeight: 600,
                fontFamily: "Inter",
              }}
            >
              Understanding the style of a particular genre that a user likes -
              through artists
            </Box>
          </AnimationOnScroll>
        </Box>

        <Box
          sx={{
            position: "relative",
            width: "90%",
            mx: "auto",
            mt: 3,
            display: { sm: "none", xs: "block" },
          }}
        >
          <AnimationOnScroll
            animateOnce={true}
            delay={500}
            animateIn="animate__flipInX"
          >
            Since there are different kinds of rap, users are prompted to select
            the artist(s) that reflect the rap genre the best according to them.
          </AnimationOnScroll>
        </Box>
      </SectionThirteenWrapper>

      <SectionThirteenWrapper>
        <Box
          sx={{
            position: "absolute",
            top: { xl: "22%", md: "5%", sm: "0%" },
            left: {
              lg: "calc(50% - 200px)",
              md: "calc(50% - 150px)",
              sm: "calc(50% - 220px)",
            },
            width: { lg: 350, sm: 300 },
            transform: { md: "translateX(-50%)" },
            display: { sm: "block", xs: "none" },
          }}
        >
          <AnimationOnScroll
            animateOnce={true}
            delay={500}
            animateIn="animate__flipInX"
          >
            <Box
              sx={{
                py: 2,
                px: 2.5,
                background: (theme: Theme) => theme.palette.secondary.main,
                fontSize: { md: "20px", sm: "15px" },
                fontWeight: 600,
                fontFamily: "Inter",
              }}
            >
              To help the AI model get a better understanding of a user’s
              musical inclinations, we prompt them to upload any audio file(s)
              that they like
            </Box>
          </AnimationOnScroll>
        </Box>

        <Box
          sx={{
            position: "absolute",
            display: { sm: "block", xs: "none" },
            top: { xl: "75%", sm: "63%" },
            left: {
              lg: "calc(50% - 250px)",
              md: "calc(50% - 200px)",
              sm: "calc(50% - 270px)",
            },
            width: { lg: "450px", sm: "350px" },
            transform: { md: "translateX(-50%)" },
          }}
        >
          <AnimationOnScroll
            animateOnce={true}
            delay={500}
            animateIn="animate__flipInX"
          >
            Uploading audio files that a user likes will assist the AI model in
            understanding what kind of music they like.
          </AnimationOnScroll>
        </Box>
        <Box
          className="lineAnimation"
          sx={{
            position: "absolute",
            display: { sm: "block", xs: "none" },
            top: { xl: "60%", xs: "40%" },
            left: { xl: "33.7%", md: "28.7%", sm: "45%" },
            width: "310px",
            height: "90px",
            background: "#fff",
          }}
        ></Box>
        <Box
          sx={{
            position: "absolute",
            display: { sm: "block", xs: "none" },
            top: { xl: "60%", xs: "40%" },
            left: { xl: "33.7%", md: "28.7%", sm: "45%" },
            zIndex: -1,
            transform: "rotateX(180deg)",
          }}
        >
          <Line2 />
        </Box>
        <AnimationOnScroll
          animateOnce={true}
          animateIn={"animate__fadeInRight"}
        >
          <Box
            sx={{
              textAlign: { sm: "right", xs: "center" },
              transform: {
                lg: "translateX(290px)",
                md: "translateX(350px)",
                sm: "translateX(400px)",
              },
              "& img": {
                width: { lg: "950px", md: "850px", sm: "650px", xs: "90%" },
                height: "auto",
                borderRadius: "12px",
                boxShadow: "-4px 4px 20px 0px #66666640",
              },
            }}
          >
            <img src={Databass5} alt="No Content" />
          </Box>
        </AnimationOnScroll>
        <Box
          sx={{
            position: "relative",
            width: "90%",
            mx: "auto",
            mt: 3,
            display: { sm: "none", xs: "block" },
          }}
        >
          <AnimationOnScroll
            animateOnce={true}
            delay={500}
            animateIn="animate__flipInX"
          >
            <Box
              sx={{
                py: 2,
                px: 2.5,
                background: (theme: Theme) => theme.palette.secondary.main,
                fontSize: { md: "20px", sm: "15px" },
                fontWeight: 600,
                fontFamily: "Inter",
              }}
            >
              To help the AI model get a better understanding of a user’s
              musical inclinations, we prompt them to upload any audio file(s)
              that they like
            </Box>
          </AnimationOnScroll>
        </Box>

        <Box
          sx={{
            position: "relative",
            width: "90%",
            mx: "auto",
            mt: 3,
            display: { sm: "none", xs: "block" },
          }}
        >
          <AnimationOnScroll
            animateOnce={true}
            delay={500}
            animateIn="animate__flipInX"
          >
            Uploading audio files that a user likes will assist the AI model in
            understanding what kind of music they like.
          </AnimationOnScroll>
        </Box>
      </SectionThirteenWrapper>
      <SectionEightWrapper>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: { md: "40px", sm: "32px", xs: "28px" },
              fontWeight: 700,
              fontFamily: "Mohave",
              fontStyle: "italic",
            }}
          >
            HOW WOULD THIS HELP?
          </Typography>
        </AnimationOnScroll>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Typography
            sx={{
              fontSize: { md: "28px", sm: "18px" },
              fontWeight: 600,
              color: "text.primary",
              mt: 3,
            }}
          >
            Here is an example...
          </Typography>
        </AnimationOnScroll>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Typography
            sx={{
              fontSize: { md: "28px", sm: "18px" },
              fontWeight: 600,
              color: "text.primary",
              mt: 5,
            }}
          >
            Same text prompt used to generate both the files below
          </Typography>
        </AnimationOnScroll>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Typography
            sx={{
              fontSize: { md: "18px", sm: "14px" },
              fontWeight: 600,
              color: "#000",
              border: "2.5px solid #0021F5",
              borderRadius: "12px",
              p: 1.5,
              mt: 3,
            }}
          >
            A fusion of flamenco and electronic, with passionate guitar
            strumming and digital beats, evoking the modern vibes of
            Mediterranean nights.
          </Typography>
        </AnimationOnScroll>
        <Box>
          <Box sx={{ my: 8 }}>
            <AnimationOnScroll
              duration={0.5}
              animateOnce={true}
              animateIn="animate__fadeInUp"
            >
              <Typography
                sx={{
                  fontSize: { md: "28px", sm: "18px" },
                  fontWeight: 600,
                  color: "text.primary",
                }}
              >
                Music generated by AI before the redesign
              </Typography>
            </AnimationOnScroll>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: { sm: "row", xs: "column" },
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  background: (theme) =>
                    !playing
                      ? theme.palette.secondary.main
                      : theme.palette.primary.main,
                  width: "fit-content",
                  borderRadius: "50px",
                }}
              >
                {playing ? (
                  <IconButton
                    onClick={() => {
                      stop();
                      setPlaying(false);
                    }}
                  >
                    <PauseRounded color="secondary" fontSize="large" />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={async () => {
                      play();
                      playOptions.stop();
                      setPlaying2(false);
                      setPlaying(true);
                    }}
                  >
                    <PlayArrowRounded color="primary" fontSize="large" />
                  </IconButton>
                )}
              </Box>
              <Box sx={{ mx: 3, lineHeight: 0 }}>
                {blob && (
                  <AudioVisualizer
                    ref={visualizerRef}
                    blob={blob}
                    width={300}
                    height={75}
                    barWidth={4}
                    gap={2}
                    barColor={"#0021F5"}
                    barPlayedColor={"#0021F599"}
                  />
                )}
              </Box>
            </Box>
            <Typography
              sx={{ fontSize: "16px", fontWeight: 600, color: "#000" }}
            >
              [NO KNOWLEDGE OF USER’S PREFERENCES]
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box sx={{ my: 8 }}>
            <AnimationOnScroll
              duration={0.5}
              animateOnce={true}
              animateIn="animate__fadeInUp"
            >
              <Typography
                sx={{
                  fontSize: { md: "28px", sm: "18px" },
                  fontWeight: 600,
                  color: "text.primary",
                }}
              >
                Music generated by AI after the redesign
              </Typography>
            </AnimationOnScroll>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: { sm: "row", xs: "column" },
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  background: (theme) =>
                    !playing2
                      ? theme.palette.secondary.main
                      : theme.palette.primary.main,
                  width: "fit-content",
                  borderRadius: "50px",
                }}
              >
                {playing2 ? (
                  <IconButton
                    onClick={() => {
                      playOptions.stop();
                      setPlaying2(false);
                    }}
                  >
                    <PauseRounded color="secondary" fontSize="large" />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={async () => {
                      play2();
                      stop();
                      setPlaying(false);
                      setPlaying2(true);
                    }}
                  >
                    <PlayArrowRounded color="primary" fontSize="large" />
                  </IconButton>
                )}
              </Box>
              <Box sx={{ mx: 3, lineHeight: 0 }}>
                {blob2 && (
                  <AudioVisualizer
                    ref={visualizer2Ref}
                    blob={blob2}
                    width={300}
                    height={75}
                    barWidth={4}
                    gap={2}
                    barColor={"#0021F5"}
                  />
                )}
              </Box>
            </Box>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 600,
                color: "#000",
                whiteSpace: { md: "nowrap", xs: "wrap" },
              }}
            >
              [WITH KNOWLEDGE OF USER’S PREFERENCES - HIP BEATS, MODERN]
            </Typography>
          </Box>
        </Box>
      </SectionEightWrapper>
      <Box
        sx={{
          mt: 12.5,
          width: "95%",
          mx: "auto",
          "& img": {
            maxWidth: "1536px",
            width: "100%",
            borderRadius: "12px",
            boxShadow: "4px 0px 20px 0px #66666640",
          },
        }}
      >
        <img src={Databass6} alt="no gif" />
      </Box>

      <SectionFifteenWrapper>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Typography sx={{ fontSize: { md: "22px", sm: "18px" }, mt: 7 }}>
            The entire text-to-music flow is{" "}
            <b>redesigned to act as a helping hand</b>&nbsp;through things like
            the ‘Tip’ tooltip, the ‘Prompts to inspire’ section, and the
            “Surprise me” button. Each of these intends to help the user get a
            better output.
          </Typography>
        </AnimationOnScroll>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Typography sx={{ fontSize: { md: "22px", sm: "18px" }, mt: 5 }}>
            <b>Tip</b> - Based on the text description entered by the user, the
            AI model understands what more information it would need to improve
            the accuracy of its output, and asks for exactly that info through
            this tooltip
          </Typography>
        </AnimationOnScroll>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Typography sx={{ fontSize: { md: "22px", sm: "18px" }, mt: 5 }}>
            <b>Prompts to inspire</b> - Not sure what you need? Check out the
            prompts to inspire section for ideas! Music producers are dreamers,
            they like suggestions and multiple samples so that they generate
            only the best and most relevant output.
          </Typography>
        </AnimationOnScroll>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Typography sx={{ fontSize: { md: "22px", sm: "18px" }, mt: 5 }}>
            <b>Surprise me</b> - Through the user interviews, we realized that
            producers don’t always have a concrete goal in mind. A lot of times,
            they just mess around with their tools to experiment and get
            different outputs. You never know what you might end up liking.
            In-line with the same behavior, the ‘Surprise me’ button takes the
            prompt into consideration, and significantly changes some aspects of
            it to generate a similar yet very different result.
          </Typography>
        </AnimationOnScroll>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Typography sx={{ fontSize: { md: "22px", sm: "18px" }, mt: 5 }}>
            <b>BPM, Key, Mode</b> - These 3 parameters are the most crucial for
            the AI to produce an accurate output. Users weren’t putting these in
            their prompts, so we now have it upfront without the need to rely
            solely on users’ natural language.
          </Typography>
        </AnimationOnScroll>
      </SectionFifteenWrapper>
      <SectionThirteenWrapper>
        <Box
          sx={{
            position: "absolute",
            top: { xl: "7%", sm: "0%" },
            left: {
              lg: "calc(50% - 200px)",
              md: "calc(50% - 150px)",
              sm: "calc(50% - 270px)",
            },
            width: { lg: 400, sm: 350 },
            transform: { md: "translateX(-50%)" },
            display: { sm: "block", xs: "none" },
          }}
        >
          <AnimationOnScroll
            animateOnce={true}
            delay={500}
            animateIn="animate__flipInX"
          >
            <Box
              sx={{
                py: 2,
                px: 2.5,
                background: (theme: Theme) => theme.palette.secondary.main,
                fontSize: { md: "20px", sm: "15px" },
                fontWeight: 600,
                fontFamily: "Inter",
              }}
            >
              Showing multiple results helps the AI model get better - When a
              user picks one of these options, it helps the model understand
              that for this particular prompt, users tend to prefer this one out
              of the three.
            </Box>
          </AnimationOnScroll>
        </Box>

        <Box
          sx={{
            position: "absolute",
            display: { sm: "block", xs: "none" },
            top: { md: "75%", sm: "70%" },
            left: {
              lg: "calc(50% - 250px)",
              md: "calc(50% - 200px)",
              sm: "5%",
            },
            width: { lg: "450px", sm: "400px" },
            transform: { md: "translateX(-50%)" },
          }}
        >
          <AnimationOnScroll
            animateOnce={true}
            delay={500}
            animateIn="animate__flipInX"
          >
            Making the modifying generated audio process easier by suggesting
            some possible changes like “Make it happier”, “Add more bass”, etc.
            - based on the audio files that the user had uploaded earlier, since
            the AI model knows what kind of music they like
          </AnimationOnScroll>
        </Box>
        <Box
          className="lineAnimation"
          sx={{
            position: "absolute",
            display: { sm: "block", xs: "none" },
            top: { md: "60%", xs: "48%" },
            left: { xl: "33.7%", md: "28.7%", sm: "45%" },
            width: "310px",
            height: "90px",
            background: "#fff",
          }}
        ></Box>
        <Box
          sx={{
            position: "absolute",
            display: { sm: "block", xs: "none" },
            top: { md: "60%", xs: "48%" },
            left: { xl: "33.7%", md: "28.7%", sm: "45%" },
            zIndex: -1,
            transform: "rotateX(180deg)",
          }}
        >
          <Line2 />
        </Box>
        <AnimationOnScroll
          animateOnce={true}
          animateIn={"animate__fadeInRight"}
        >
          <Box
            sx={{
              textAlign: { sm: "right", xs: "center" },
              transform: {
                lg: "translateX(290px)",
                md: "translateX(350px)",
                sm: "translateX(400px)",
              },
              "& img": {
                width: { lg: "950px", md: "850px", sm: "650px", xs: "90%" },
                height: "auto",
                borderRadius: "12px",
                boxShadow: "-4px 4px 20px 0px #66666640",
              },
            }}
          >
            <img src={Databass7} alt="No Content" />
          </Box>
        </AnimationOnScroll>
        <Box
          sx={{
            position: "relative",
            width: "90%",
            mx: "auto",
            mt: 3,
            display: { sm: "none", xs: "block" },
          }}
        >
          <AnimationOnScroll
            animateOnce={true}
            delay={500}
            animateIn="animate__flipInX"
          >
            <Box
              sx={{
                py: 2,
                px: 2.5,
                background: (theme: Theme) => theme.palette.secondary.main,
                fontSize: { md: "20px", sm: "15px" },
                fontWeight: 600,
                fontFamily: "Inter",
              }}
            >
              Showing multiple results helps the AI model get better - When a
              user picks one of these options, it helps the model understand
              that for this particular prompt, users tend to prefer this one out
              of the three.
            </Box>
          </AnimationOnScroll>
        </Box>

        <Box
          sx={{
            position: "relative",
            width: "90%",
            mx: "auto",
            mt: 3,
            display: { sm: "none", xs: "block" },
          }}
        >
          <AnimationOnScroll
            animateOnce={true}
            delay={500}
            animateIn="animate__flipInX"
          >
            Making the modifying generated audio process easier by suggesting
            some possible changes like “Make it happier”, “Add more bass”, etc.
            - based on the audio files that the user had uploaded earlier, since
            the AI model knows what kind of music they like
          </AnimationOnScroll>
        </Box>
      </SectionThirteenWrapper>

      <SectionThirteenWrapper>
        <Box
          sx={{
            position: "absolute",
            top: { lg: "5%", sm: "0%" },
            left: {
              xl: "calc(50% - 200px)",
              md: "calc(50% - 250px)",
              sm: "5%",
            },
            width: { lg: 450, sm: 400 },
            transform: { md: "translateX(-50%)" },
            display: { sm: "block", xs: "none" },
          }}
        >
          <AnimationOnScroll
            animateOnce={true}
            delay={500}
            animateIn="animate__flipInX"
          >
            <Box
              sx={{
                py: 2,
                px: 2.5,
                background: (theme: Theme) => theme.palette.secondary.main,
                fontSize: { md: "20px", sm: "15px" },
                fontWeight: 600,
                fontFamily: "Inter",
              }}
            >
              To eliminate the need to start the generation process from the
              beginning, we now added the ability for users to change certain
              key parameters of the generated audio file, like ‘bass’, ‘Reverb’,
              ‘Distortion’, and ‘Timber’. Looks kept similar to audio production
              tools to introduce a sense of familiarity.
            </Box>
          </AnimationOnScroll>
        </Box>
        <Box
          sx={{
            position: "absolute",
            display: { sm: "block", xs: "none" },
            top: { md: "75%", xs: "70%" },
            left: {
              lg: "calc(50% - 250px)",
              md: "calc(50% - 200px)",
              sm: "calc(50% - 250px)",
            },
            width: { lg: "450px", sm: "350px" },
            transform: { md: "translateX(-50%)" },
          }}
        >
          <AnimationOnScroll
            animateOnce={true}
            delay={500}
            animateIn="animate__flipInX"
          >
            Customize your generated audio through controls that are familiar to
            you, or simply describe the changes you want through text.
          </AnimationOnScroll>
        </Box>
        <Box
          className="lineAnimation"
          sx={{
            position: "absolute",
            display: { sm: "block", xs: "none" },
            top: { md: "60%", xs: "48%" },
            left: { xl: "33.7%", md: "28.7%", sm: "45%" },
            width: "310px",
            height: "90px",
            background: "#fff",
          }}
        ></Box>
        <Box
          sx={{
            position: "absolute",
            display: { sm: "block", xs: "none" },
            top: { md: "60%", xs: "48%" },
            left: { xl: "33.7%", md: "28.7%", sm: "45%" },
            zIndex: -1,
            transform: "rotateX(180deg)",
          }}
        >
          <Line2 />
        </Box>
        <AnimationOnScroll
          animateOnce={true}
          animateIn={"animate__fadeInRight"}
        >
          <Box
            sx={{
              textAlign: { sm: "right", xs: "center" },
              transform: {
                lg: "translateX(290px)",
                md: "translateX(350px)",
                sm: "translateX(400px)",
              },
              "& img": {
                width: { lg: "950px", md: "850px", sm: "650px", xs: "90%" },
                height: "auto",
                borderRadius: "12px",
                boxShadow: "-4px 4px 20px 0px #66666640",
              },
            }}
          >
            <img src={Databass8} alt="No Content" />
          </Box>
        </AnimationOnScroll>{" "}
        <Box
          sx={{
            position: "relative",
            width: "90%",
            mx: "auto",
            mt: 3,
            display: { sm: "none", xs: "block" },
          }}
        >
          <AnimationOnScroll
            animateOnce={true}
            delay={500}
            animateIn="animate__flipInX"
          >
            <Box
              sx={{
                py: 2,
                px: 2.5,
                background: (theme: Theme) => theme.palette.secondary.main,
                fontSize: { md: "20px", sm: "15px" },
                fontWeight: 600,
                fontFamily: "Inter",
              }}
            >
              To eliminate the need to start the generation process from the
              beginning, we now added the ability for users to change certain
              key parameters of the generated audio file, like ‘bass’, ‘Reverb’,
              ‘Distortion’, and ‘Timber’. Looks kept similar to audio production
              tools to introduce a sense of familiarity.
            </Box>
          </AnimationOnScroll>
        </Box>
        <Box
          sx={{
            position: "relative",
            width: "90%",
            mx: "auto",
            mt: 3,
            display: { sm: "none", xs: "block" },
          }}
        >
          <AnimationOnScroll
            animateOnce={true}
            delay={500}
            animateIn="animate__flipInX"
          >
            Customize your generated audio through controls that are familiar to
            you, or simply describe the changes you want through text.
          </AnimationOnScroll>
        </Box>
      </SectionThirteenWrapper>

      <SectionSeventeenWrapper id="section4">
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: { md: "40px", sm: "32px", xs: "28px" },
              fontWeight: 700,
              fontFamily: "Mohave",
              fontStyle: "italic",
              width: "fit-content",
              // borderBottom: "4px solid",
            }}
          >
            IMPACT
          </Typography>
        </AnimationOnScroll>

        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Typography
            sx={{ fontSize: { md: "28px", xs: "22px" }, fontWeight: 600 }}
          >
            Lots of happy faces...
          </Typography>
        </AnimationOnScroll>
        <Box sx={{ mt: 3 }}>
          <Box
            sx={{
              width: { lg: "640px", xs: "100%" },
              px: 3,
              py: 3.5,
              background: (theme: Theme) => theme.palette.secondary.main,
            }}
          >
            <Typography
              sx={{ fontSize: "24px", fontWeight: 600, color: "#000" }}
            >
              “Prompts to inspire is really useful for my workflow because I
              spend a lot of time finding inspirations”
            </Typography>
          </Box>
          <Box
            sx={{
              width: { lg: "640px", xs: "100%" },
              pl: 3,
              pr: 1,
              py: 1.5,
              background: "#000",
            }}
          >
            <Typography
              sx={{
                fontSize: { lg: "20px", xs: "16px" },
                fontWeight: 400,
                color: (theme: Theme) => theme.palette.secondary.main,
              }}
            >
              -Name is confidential, Music Producer, Los Angeles
            </Typography>
          </Box>
          <Box sx={{ ml: 6, mt: -1 }}>
            <ToolTipIcons />
          </Box>
          <Box sx={{ mt: 3 }}>
            <img src={DatabassUser1} alt="no user" />
          </Box>
        </Box>
        <Box
          sx={{
            top: { lg: "60%", xs: "80%" },
            position: { lg: "absolute" },
            right: 0,
          }}
        >
          <Box
            sx={{
              width: { lg: "640px", xs: "100%" },
              px: 3,
              py: 3.5,
              mt: { lg: 0, xs: 5 },
              background: (theme: Theme) => theme.palette.secondary.main,
            }}
          >
            <Typography
              sx={{ fontSize: "24px", fontWeight: 600, color: "#000" }}
            >
              “Wow! I love that it can understand my personal preferences!”
            </Typography>
          </Box>
          <Box
            sx={{
              width: { lg: "640px", xs: "100%" },
              pl: 3,
              pr: 1,
              py: 1.5,
              background: "#000",
            }}
          >
            <Typography
              sx={{
                fontSize: { lg: "20px", xs: "16px" },
                fontWeight: 400,
                color: (theme: Theme) => theme.palette.secondary.main,
              }}
            >
              -Name is confidential, Music Producer, Los Angeles
            </Typography>
          </Box>
          <Box sx={{ mr: 6, mt: -1, textAlign: "right" }}>
            <ToolTipIcons />
          </Box>
          <Box sx={{ mt: 3, textAlign: "right" }}>
            <img src={DatabassUser2} alt="no user" />
          </Box>
        </Box>
      </SectionSeventeenWrapper>

      <Box sx={{ maxWidth: "1000px", mx: { md: 7.5, xs: 2 }, my: 15 }}>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: { md: "40px", sm: "32px", xs: "28px" },
              fontWeight: 700,
              fontFamily: "Mohave",
              fontStyle: "italic",
              width: "fit-content",
              // borderBottom: "4px solid",
            }}
          >
            LIMITATION
          </Typography>
        </AnimationOnScroll>
        <Typography
          sx={{ fontSize: { md: "22px", sm: "18px" }, color: "#000", mt: 3 }}
        >
          While all the concepts that we designed are technically feasible to
          implement, however, due to the timeline of the design activity (3
          days), we could not build the AI model and test it. As an alternate,
          we tested the concept.
        </Typography>
      </Box>
    </ProjectWrapper>
  );
};

export default withAuth(Project4);
