import React from "react";
const ArrowIcon = () => {
  return (
    <svg
      width="149"
      height="74"
      viewBox="0 0 149 74"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="31" width="138" height="13" />
      <rect
        x="111.39"
        width="51.5014"
        height="13.2791"
        transform="rotate(45 111.39 0)"
      />
      <rect
        x="148.557"
        y="37.1398"
        width="51.5014"
        height="12.9256"
        transform="rotate(135 148.557 37.1398)"
      />
    </svg>
  );
};

export default ArrowIcon;
