import React from "react";

const BulbIcon = () => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.8198 6.23565L6.81978 6.23564L5.24128 4.65739C5.24127 4.65738 5.24127 4.65738 5.24126 4.65737C4.93102 4.34712 4.41984 4.34681 4.1058 4.66084C3.79078 4.97583 3.78856 5.48282 4.1023 5.79632C4.10234 5.79636 4.10238 5.7964 4.10242 5.79644L5.68057 7.37484L5.68058 7.37485C5.99095 7.68525 6.50233 7.68503 6.81622 7.37149L6.81632 7.37139C7.13143 7.05631 7.13346 6.54932 6.8198 6.23565ZM21.8743 7.37488L21.8743 7.37487L23.4525 5.79637C23.4525 5.79636 23.4526 5.79635 23.4526 5.79634C23.7628 5.4861 23.7631 4.97491 23.4491 4.66088C23.1341 4.34586 22.6271 4.34364 22.3136 4.65739C22.3136 4.65742 22.3135 4.65746 22.3135 4.6575L20.7351 6.23566L20.9118 6.41245L20.7351 6.23566C20.4247 6.54603 20.4249 7.05741 20.7384 7.37131L20.7385 7.3714C21.0536 7.68651 21.5606 7.68855 21.8743 7.37488ZM4.58317 13.7773C4.58317 13.3317 4.22626 12.9717 3.78284 12.9717H1.55032C1.11139 12.9717 0.75 13.3334 0.75 13.7773C0.75 14.2228 1.10691 14.5828 1.55032 14.5828H3.78284C4.22177 14.5828 4.58317 14.2211 4.58317 13.7773ZM26.2487 13.7773C26.2487 13.3317 25.8918 12.9717 25.4484 12.9717H23.2159C22.777 12.9717 22.4156 13.3334 22.4156 13.7773C22.4156 14.2228 22.7725 14.5828 23.2159 14.5828H25.4484C25.8874 14.5828 26.2487 14.2211 26.2487 13.7773ZM13.7773 4.58317C14.2228 4.58317 14.5828 4.22626 14.5828 3.78284V1.55032C14.5828 1.11139 14.2211 0.75 13.7773 0.75C13.3317 0.75 12.9717 1.10691 12.9717 1.55032V3.78284C12.9717 4.22177 13.3334 4.58317 13.7773 4.58317Z"
        fill="white"
        stroke="white"
        stroke-width="0.5"
      />
      <path
        d="M15.3598 18.7647L15.1931 18.8237V19.0005V20.1934H11.8044V19.0005V18.8237L11.6378 18.7647C9.46897 17.9982 7.91574 15.9298 7.91574 13.4993C7.91574 10.4158 10.4153 7.91623 13.4988 7.91623C16.5823 7.91623 19.0818 10.4158 19.0818 13.4993C19.0818 15.9298 17.5286 17.9982 15.3598 18.7647ZM15.1931 24.0265V24.6376H11.8044V24.0265H15.1931ZM15.1931 22.4155H11.8044V21.8044H15.1931V22.4155ZM13.7765 6.30518C9.5256 6.30518 6.30469 9.52609 6.30469 13.777C6.30469 16.2722 7.88032 18.7293 10.1934 20.0354V24.8876C10.1934 25.2613 10.3881 25.6002 10.6363 25.8386C10.8831 26.0755 11.2198 26.2486 11.5544 26.2486H15.4577C16.154 26.2486 16.8042 25.6358 16.8042 24.8876V20.0354C19.1172 18.7293 20.6929 16.2722 20.6929 13.777C20.6929 9.53929 17.4848 6.30518 13.7765 6.30518Z"
        fill="white"
        stroke="white"
        stroke-width="0.5"
      />
    </svg>
  );
};

export default BulbIcon;
