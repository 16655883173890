import React from "react";

const SadIcon = () => {
  return (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5 2.51661C21.1079 2.51661 26.4838 7.89387 26.4838 14.5016C26.4838 21.1094 21.1079 26.4842 14.5 26.4842C7.89214 26.4842 2.51621 21.1094 2.51621 14.501C2.51621 7.89312 7.89214 2.51604 14.5 2.51604M14.5 0C6.49163 0 0 6.4922 0 14.5012C0 22.5084 6.49163 29 14.5 29C22.5078 29 29 22.5078 29 14.5012C29 6.4922 22.5078 0 14.5 0ZM11.6374 11.4556C11.6374 12.5205 10.7741 13.3838 9.70913 13.3838C8.64419 13.3838 7.78132 12.5205 7.78132 11.4556C7.78132 10.3906 8.64465 9.5273 9.70913 9.5273C10.7745 9.52753 11.6374 10.3906 11.6374 11.4556ZM19.2908 9.54554C18.2256 9.54554 17.3625 10.4089 17.3625 11.4738C17.3625 12.5367 18.2258 13.4023 19.2908 13.4023C20.3557 13.4023 21.2186 12.5367 21.2186 11.4738C21.2188 10.4089 20.3557 9.54554 19.2908 9.54554ZM10.1512 22.3579C11.3341 21.2449 12.8786 20.6315 14.5001 20.6315C16.1224 20.6315 17.6682 21.2451 18.8511 22.3579C19.3557 22.8335 20.1518 22.8077 20.6296 22.3042C21.1043 21.7967 21.081 21.0012 20.5737 20.5256C18.9232 18.9711 16.7651 18.1159 14.5004 18.1159C12.2365 18.1159 10.0792 18.9711 8.42705 20.5259C7.92155 21.0015 7.89728 21.7967 8.37288 22.3044C8.62102 22.566 8.95547 22.7 9.29012 22.7C9.59895 22.6996 9.90959 22.5858 10.1517 22.3579"
        fill="black"
      />
    </svg>
  );
};

export default SadIcon;
