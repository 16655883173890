import { useEffect, useRef } from "react";
import {
  Engine,
  Render,
  Bodies,
  Composite,
  Runner,
  Body,
  Events,
  Mouse,
  MouseConstraint,
} from "matter-js";
import { randomElement } from "../../../Utils";
import { fullHeight, fullWidth } from "../../../LayoutContainer";
interface CanvasProps {
  status: boolean;
}
const Canvas = ({ status }: CanvasProps) => {
  const scene = useRef<any>();
  const engine = Engine.create();
  const box = useRef<Body>();
  const triangle = useRef<Body>();
  const circle = useRef<Body>();
  const temporaryWall = useRef<Body>();
  const sideWall2 = useRef<Body>();
  const mouseLocation = useRef<MouseEvent>();
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;
  useEffect(() => {
    window.addEventListener("mousemove", (e: MouseEvent) => {
      mouseLocation.current = e;
    });
    const render = Render.create({
      element: scene.current,
      engine: engine,
      options: {
        wireframes: false,
        background: "transparent",
        width: windowWidth,
        height: windowHeight,
      },
    });
    box.current = Bodies.rectangle(
      windowWidth / 1.32,
      windowHeight / 2,
      36,
      36,
      {
        render: { fillStyle: "#FFF844" },
      }
    );

    circle.current = Bodies.circle(windowWidth / 1.26, windowHeight / 2, 18, {
      render: { fillStyle: "#F845E6" },
    });
    triangle.current = Bodies.polygon(
      windowWidth / 1.375,
      windowHeight / 2,
      3,
      30,
      {
        render: { fillStyle: "#FF1E1E" },
        //   isStatic: true,
      }
    );
    var baseGround = Bodies.rectangle(
      windowWidth / 2,
      windowHeight + 5,
      windowWidth,
      10,
      {
        isStatic: true,
        render: {
          fillStyle: "transparent",
        },
      }
    );
    var leftWall = Bodies.rectangle(0, 0, 10, windowHeight * 2, {
      isStatic: true,
      render: {
        fillStyle: "transparent",
      },
    });
    var rightWall = Bodies.rectangle(windowWidth, 0, 10, windowHeight * 2, {
      isStatic: true,
      render: {
        fillStyle: "transparent",
      },
    });
    var topWall = Bodies.rectangle(0, 0, windowWidth * 2, 10, {
      isStatic: true,
      render: {
        fillStyle: "transparent",
      },
    });
    temporaryWall.current = Bodies.rectangle(
      windowWidth / 1.32,
      windowHeight / 1.7,
      200,
      10,
      {
        isStatic: true,
        render: {
          fillStyle: "transparent",
        },
      }
    );
    sideWall2.current = Bodies.rectangle(950, 0, 10, 1200, {
      isStatic: true,
      render: {
        fillStyle: "transparent",
      },
    });
    var ramp = Bodies.rectangle(950, 300, 60, 500, {
      isStatic: true,
      render: {
        fillStyle: "transparent",
      },
      angle: 20,
    });

    if (box) {
      Composite.add(engine.world, [
        box.current,
        circle.current,
        triangle.current,
        baseGround,
        leftWall,
        rightWall,
        topWall,
        temporaryWall.current,
        // sideWall2.current,
        // ramp,
      ]);
    }

    Render.run(render);
    var runner = Runner.create();

    Runner.run(runner, engine);

    // add mouse control
    var mouse = Mouse.create(render.canvas),
      mouseConstraint: any = MouseConstraint.create(engine, {
        mouse: mouse,
        constraint: {
          stiffness: 0.2,
          render: {
            visible: false,
          },
        },
      });
    mouseConstraint.mouse.element.removeEventListener(
      "mousewheel",
      mouseConstraint.mouse.mousewheel
    );
    mouseConstraint.mouse.element.removeEventListener(
      "DOMMouseScroll",
      mouseConstraint.mouse.mousewheel
    );
    Composite.add(engine.world, mouseConstraint);

    // keep the mouse in sync with rendering
    render.mouse = mouse;

    // fit the render viewport to the scene
    Render.lookAt(render, {
      min: { x: 0, y: 0 },
      max: { x: windowWidth, y: windowHeight },
    });
    var counter = 0;
    Events.on(runner, "tick", (e) => {
      counter += 1;
      if (counter >= 30 * 1.5) {
        if (mouseConstraint.body && mouseLocation.current) {
          let temp: Body;
          if (mouseConstraint.body.label === "Rectangle Body") {
            temp = Bodies.rectangle(
              mouseLocation.current.pageX,
              mouseLocation.current.pageY,
              36,
              36,
              {
                render: { fillStyle: "#FFF844" },
              }
            );
          } else if (mouseConstraint.body.label === "Circle Body") {
            temp = Bodies.circle(
              mouseLocation.current.pageX,
              mouseLocation.current.pageY,
              18,
              {
                render: { fillStyle: "#F845E6" },
              }
            );
          } else {
            temp = Bodies.polygon(
              mouseLocation.current.pageX,
              mouseLocation.current.pageY,
              3,
              30,
              {
                render: { fillStyle: "#FF1E1E" },
                //   isStatic: true,
              }
            );
          }
          counter = 0;

          Composite.add(engine.world, [temp]);
        }
      }
    });
    // Events.on(runner, "afterTick", function () {
    //   counter += 1;
    //   if (
    //     counter >= 120 * 1.5 &&
    //     triangle.current &&
    //     circle.current &&
    //     box.current
    //   ) {
    //     Body.setVelocity(triangle.current, { x: 0, y: -5 });
    //     Body.setVelocity(box.current, { x: 0, y: -3.8 });
    //     // Body.setAngularVelocity(box.current, 0.1);
    //     Body.setVelocity(circle.current, { x: 0, y: -2.5 });

    //     // reset counter
    //     counter = 0;
    //   }
    //   // console.log("status", status);
    // });
  }, []);

  useEffect(() => {
    if (status) {
      animation(status);
    }
  }, [status]);

  const animation = (status: boolean) => {
    if (temporaryWall.current && status) {
      Body.setPosition(temporaryWall.current, {
        x: windowWidth + 100,
        y: windowHeight + 100,
      });
    }
  };
  return (
    <div
      ref={scene}
      style={{
        width: fullWidth,
        height: fullHeight,
        // zIndex: -1,
        position: "relative",
      }}
    />
  );
};

export default Canvas;
