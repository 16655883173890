import { Box, styled } from "@mui/material";

export const SideComponentWrapper = styled(Box)(({ theme }) => ({
  width: "calc(100vh - 64px)",
  height: "64px",
  position: "fixed",
  background: "#fff",
  transformOrigin: "left top",
  zIndex: 999,
  transform: "rotate(-90deg) translate(-100.2%,-1%)",
  overflow: "hidden",
  [theme.breakpoints.down("sm")]: {
    width: "100vh",
  },
}));

export const SideComponentChild = styled(Box)(({ theme }) => ({
  //   transform: "rotate(90deg)",
  //   marginTop: theme.spacing(8),
  //   position: "absolute",
  //   display: "flex",
  //   width: "100%",
  //   //   marginLeft: "64px",
  //   justifyContent: "space-evenly",
  //   alignItems: "center",
  display: "flex",
  flexDirection: "row-reverse",
  justifyContent: "space-evenly",
  "& *": {
    fontSize: "20px",
    fontWeight: 500,
    flexGrow: 1,
    textAlign: "center",
    borderRight: "1px solid",
    float: "right",
    transform: "rotate(180deg)",
    height: "100%",
    lineHeight: "64px",
  },
  "& .active": {
    background: theme.palette.primary.main,
    color: "#fff",
    cursor: "pointer",
  },
}));
