import React, { useEffect, useState } from "react";
import {
  ProjectWrapper,
  SectionEightWrapper,
  SectionFifteenWrapper,
  SectionFourteenWrapper,
  SectionOneWrapper,
  SectionThirteenWrapper,
  SectionThreeWrapper,
  SectionTwelveWrapper,
  SectionTwoWrapper,
} from "./styled";

import { Box, Button, Theme, Typography } from "@mui/material";
import Neuma1 from "../../Assets/images/Neuma1.png";
import Neuma2 from "../../Assets/images/Neuma2.png";
import Neuma3 from "../../Assets/images/Neuma3.png";
import Neuma4 from "../../Assets/images/Neuma4.png";
import Neuma5 from "../../Assets/images/Neuma5.png";
import Neuma6 from "../../Assets/images/Neuma6.png";
import Neuma7 from "../../Assets/images/Neuma7.png";
import NeumaProject1 from "../../Assets/images/NeumaProject1.png";
import NeumaProject2 from "../../Assets/images/NeumaProject2.png";
import NeumaProject3 from "../../Assets/images/NeumaProject3.png";
import NeumaProject4 from "../../Assets/images/NeumaProject4.png";
import NeumaS1 from "../../Assets/images/NeumaS1.png";
import NeumaS2 from "../../Assets/images/NeumaS2.png";
import NeumaS3 from "../../Assets/images/NeumaS3.png";
import NeumaS4 from "../../Assets/images/NeumaS4.png";
import NeumaS5 from "../../Assets/images/NeumaS5.png";
import NeumaS6 from "../../Assets/images/NeumaS6.png";
import NeumaS7 from "../../Assets/images/NeumaS7.png";
import NeumaS8 from "../../Assets/images/NeumaS8.png";
import NeumaS9 from "../../Assets/images/NeumaS9.png";
import NeumaS10 from "../../Assets/images/NeumaS10.png";
import NeumaGIF from "../../Assets/images/NeumaGIF.gif";
import This from "../../Assets/images/this.png";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Link, useLocation } from "react-router-dom";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";

import Line1 from "../../Assets/Icons/Line1";
import { scrollTrigger } from "../../Helper/scrollAnimation";
import ToolTipIcons from "../../Assets/Icons/ToolTipIcons";
import Marquee from "react-fast-marquee";
import CircleIcon from "../../Assets/Icons/CircleIcon";
import TriangleIcon from "../../Assets/Icons/TriangleIcon";
import SqaureIcon from "../../Assets/Icons/SqaureIcon";
import Bulb2Icon from "../../Assets/Icons/Bulb2Icon";
import withAuth from "../../HOC/withAuth";
const Project = () => {
  const { pathname } = useLocation();

  const [isPlaying, setIsPlaying] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      scrollTrigger(".lineAnimation");
    });
  }, []);

  return (
    <ProjectWrapper>
      <SectionOneWrapper>
        <Typography variant="h1">NEUMA</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { sm: "row", xs: "column" },
            "& p": {
              fontWeight: 500,
              fontSize: { md: "28px", xs: "20px" },
            },
          }}
        >
          <Typography>MOBILE REMOTE FOR SMART MATTRESS</Typography>
          <Typography sx={{ ml: { sm: 6, xs: 0 } }}>2 MONTHS</Typography>
        </Box>
      </SectionOneWrapper>
      <SectionTwoWrapper>
        <ParallaxProvider>
          <Parallax
            // speed={-10}
            translateX={["15", "-20"]}
            easing="easeOutQuad"
            endScroll={100}
          >
            <Box
              sx={{
                display: "flex",
                "& img": {
                  width: { md: "285px", xs: "150px" },
                  height: "auto",
                  boxShadow: "4px 0px 20px 0px #66666640",
                  borderRadius: "12px",
                  mx: 1.75,
                },
              }}
            >
              <img src={Neuma1} alt="No Banner" />

              <img src={Neuma2} alt="No Banner" />

              <img src={Neuma3} alt="No Banner" />

              <img src={Neuma4} alt="No Banner" />

              <img src={Neuma5} alt="No Banner" />

              <img src={Neuma6} alt="No Banner" />

              <img src={Neuma7} alt="No Banner" />
            </Box>
          </Parallax>
        </ParallaxProvider>
      </SectionTwoWrapper>
      <SectionThreeWrapper id="section1">
        <Box>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
          >
            <Typography
              variant="h3"
              sx={{ fontSize: { md: "28px", xs: "20px" }, fontWeight: 600 }}
            >
              What is Neuma?
            </Typography>
          </AnimationOnScroll>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
            delay={300}
          >
            <Typography sx={{ fontSize: { md: "22px", xs: "18px" }, mt: 2.5 }}>
              Neuma is an app that acts as a remote controller for the Neuma
              Smart Mattress. The app has premium and minimal visuals, so as to
              fit in-line with how the mattress is positioned in the market.
              Neuma is also the first-ever smart mattress for the Indian market.
            </Typography>
          </AnimationOnScroll>
        </Box>
        <Box sx={{ mt: 10 }}>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
          >
            <Typography
              variant="h3"
              sx={{ fontSize: { md: "28px", xs: "20px" }, fontWeight: 600 }}
            >
              Problem Statement/Description
            </Typography>
          </AnimationOnScroll>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
            delay={300}
          >
            <Typography sx={{ fontSize: { md: "22px", xs: "18px" }, mt: 2.5 }}>
              Design an easy-to-use app which will be used to control firmness
              of the mattress, while making the app feel and look premium.
            </Typography>
          </AnimationOnScroll>
        </Box>
        <Box sx={{ mt: 10 }} id="section2">
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
          >
            <Typography
              variant="h3"
              sx={{
                fontSize: { md: "40px", sm: "32px", xs: "28px" },
                fontWeight: 700,
                fontFamily: "Mohave",
                fontStyle: "italic",
              }}
            >
              MY ROLE
            </Typography>
          </AnimationOnScroll>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
          >
            <Box sx={{ mt: 1.5, position: "relative" }}>
              <Box
                sx={{
                  position: "absolute",
                  left: "14%",
                  top: "21%",
                  zIndex: -1,
                }}
              >
                <CircleIcon />
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  left: "51%",
                  top: "-35%",
                  zIndex: -1,
                }}
              >
                <TriangleIcon />
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  right: "15%",
                  top: "40%",
                  zIndex: -1,
                }}
              >
                <SqaureIcon />
              </Box>
              <Typography
                variant="h3"
                sx={{ fontSize: { md: "28px", xs: "20px" }, fontWeight: 600 }}
              >
                Designing user flows -&gt; Exploring visual options -&gt;
                Designing
              </Typography>
            </Box>
          </AnimationOnScroll>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
            delay={300}
          >
            <Box
              sx={{
                py: 3.5,
                px: 4,
                mt: 9,
                background: (theme: Theme) => theme.palette.secondary.main,
              }}
            >
              <Typography
                sx={{
                  fontSize: { md: "28px", xs: "20px" },
                  fontWeight: 600,
                  lineHeight: "33.89px",
                }}
              >
                Since this was a fairly simple app with no complicated flows, I
                started out by exploring visual options for the main goal of the
                app, which is, changing the firmness of the mattress, alongside
                some other visually-heavy screens.
              </Typography>
            </Box>
          </AnimationOnScroll>
        </Box>
      </SectionThreeWrapper>
      <SectionTwelveWrapper>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: { md: "40px", sm: "32px", xs: "28px" },
              fontWeight: 700,
              fontFamily: "Mohave",
              fontStyle: "italic",
              width: "fit-content",
              // borderBottom: "4px solid",
            }}
          >
            INITIAL EXPLORATIONS
          </Typography>
        </AnimationOnScroll>
      </SectionTwelveWrapper>
      <SectionThirteenWrapper
        sx={{
          "& > div > div": {
            flexGrow: 1,
            maxWidth: "380px",
            "& p": {
              mt: { md: 9 },
              ml: { md: 1 },
            },
          },
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "left", width: "100%" }}>
          <Box sx={{ position: "relative" }}>
            <img src={NeumaProject1} alt="No content" />

            <Box
              className="lineAnimation"
              sx={{
                position: "absolute",
                display: { md: "block", xs: "none" },
                top: "0%",
                left: "50%",
                background: "#fff",
                width: "320px",
                height: "85px",
                zIndex: -1,
              }}
            ></Box>
            <Box
              sx={{
                position: "absolute",
                display: { md: "block", xs: "none" },
                top: "0%",
                left: "50%",
                zIndex: -2,
                transform: "rotate(150deg)",
              }}
            >
              <Line1 />
            </Box>

            <Typography
              sx={{
                position: { md: "absolute", xs: "relative" },
                top: { md: "5%" },
                left: { md: "80%" },
                width: { md: "100%" },
              }}
            >
              <AnimationOnScroll
                animateOnce={true}
                delay={500}
                animateIn="animate__flipInX"
              >
                Waves representing firmness (from low to high). Curvier waves
                indicate lower firmness, and straighter waves represent higher
                firmness
              </AnimationOnScroll>
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { md: "row", xs: "column" },
            justifyContent: "right",
            alignItems: { md: "flex-start", xs: "flex-end" },
            width: "100%",
            mt: { md: "0", xs: 5 },
          }}
        >
          <Box
            sx={{
              position: "relative",
              "& p": {
                ml: "25px !important",
              },
              ".lineAnimation.active": {
                animation: "firstPencil2 2s forwards",
                animationDelay: "2s",
              },
              "@keyframes firstPencil2": {
                "0%": {
                  transform: "translateX(0)",
                },
                "100%": {
                  transform: "translateX(-380px)",
                },
              },
            }}
          >
            <img src={NeumaProject2} alt="No content" />
            <Box
              className="lineAnimation"
              sx={{
                position: "absolute",
                display: { md: "block", xs: "none" },
                top: "35%",
                left: "-55%",
                background: "#fff",
                width: "320px",
                height: "85px",
                zIndex: -1,
              }}
            ></Box>
            <Box
              sx={{
                position: "absolute",
                display: { md: "block", xs: "none" },
                top: "35%",
                left: "-50%",
                zIndex: -2,
                transform: "rotateX(180deg) rotateZ(-30deg)",
              }}
            >
              <Line1 />
            </Box>
            <Typography
              sx={{
                position: { md: "absolute", xs: "relative" },
                top: { md: "37%" },
                left: { md: "-85%" },
                width: { md: "100%" },
              }}
            >
              <AnimationOnScroll
                animateOnce={true}
                delay={500}
                animateIn="animate__flipInX"
              >
                <Typography
                  component="span"
                  sx={{
                    textDecoration: "underline",
                    fontFamily: "caveat",
                    color: "#1A25AA",
                    "&:before": {
                      content: "url(" + This + ")",
                      position: "absolute",
                      top: -50,
                      left: -50,
                      display: "none",
                      zIndex: -1,
                    },
                    "&:hover": {
                      cursor: "pointer",
                      "&:before": {
                        display: "block",
                      },
                    },
                  }}
                >
                  This
                </Typography>{" "}
                is how the real controller looks.
                <br /> Why not make it look similar? :P
              </AnimationOnScroll>
            </Typography>
          </Box>
        </Box>
      </SectionThirteenWrapper>
      <SectionFourteenWrapper>
        <Box sx={{ display: "flex", justifyContent: "left", width: "100%" }}>
          <Box
            sx={{
              position: "relative",
              "& p": {
                ml: "25px !important",
              },
            }}
          >
            <img src={NeumaProject3} alt="No content" />
            <Box
              className="lineAnimation"
              sx={{
                position: "absolute",
                display: { md: "block", xs: "none" },
                top: "35%",
                left: "100%",
                background: "#fff",
                width: "200px",
                height: "85px",
                zIndex: -1,
              }}
            ></Box>
            <Box
              sx={{
                position: "absolute",
                display: { md: "block", xs: "none" },
                top: "35%",
                left: "50%",
                zIndex: -2,
                transform: "rotate(150deg)",
              }}
            >
              <Line1 />
            </Box>
            <Typography
              sx={{
                position: { md: "absolute", xs: "relative" },
                top: { md: "50%" },
                left: { md: "100%" },
                width: { md: "100%" },
              }}
            >
              <AnimationOnScroll
                animateOnce={true}
                delay={500}
                animateIn="animate__flipInX"
              >
                Using illustrations to make it
                <br /> easy-to-understand
              </AnimationOnScroll>
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "right",

            mt: { md: "0", xs: 5 },
            flexDirection: { md: "row", xs: "column" },
            alignItems: { md: "flex-start", xs: "flex-end" },
            width: "100%",
          }}
        >
          <Box
            sx={{
              position: "relative",
              ".lineAnimation.active": {
                animation: "pencil2 2s forwards",
                animationDelay: "2s",
              },

              "@keyframes pencil2": {
                "0%": {
                  transform: "translateX(0)",
                },
                "100%": {
                  transform: "translateX(-220px)",
                },
              },
            }}
          >
            <img src={NeumaProject4} alt="No content" />
            <Box
              className="lineAnimation"
              sx={{
                position: "absolute",
                display: { md: "block", xs: "none" },
                top: "13%",
                left: "-56%",
                background: "#fff",
                width: "200px",
                height: "85px",
                zIndex: -1,
              }}
            ></Box>
            <Box
              sx={{
                position: "absolute",
                display: { md: "block", xs: "none" },
                top: "13%",
                left: "-50%",
                zIndex: -2,
                transform: "rotateZ(215deg) rotateY(180deg)",
              }}
            >
              <Line1 />
            </Box>

            <Typography
              sx={{
                maxWidth: "250px",
                position: { md: "absolute", xs: "relative" },
                top: { md: "25%" },
                left: { md: "-100%" },
              }}
            >
              <AnimationOnScroll
                animateOnce={true}
                delay={500}
                animateIn="animate__flipInX"
              >
                Depiction of a bed consistently remains throughout the app
              </AnimationOnScroll>
            </Typography>
          </Box>
        </Box>
      </SectionFourteenWrapper>
      <SectionFifteenWrapper>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: { md: "40px", sm: "32px", xs: "28px" },
              fontWeight: 700,
              fontFamily: "Mohave",
              fontStyle: "italic",
              width: "fit-content",
              ml: { xl: 7.5, md: 5, xs: 2 },
              // borderBottom: "4px solid",
            }}
          >
            VISUAL EXPLORATIONS
          </Typography>
        </AnimationOnScroll>
        <Box
          sx={{
            mt: 5,
            "&  div": {
              overflowX: "visible !important",
              zIndex: 0,
            },
            "&>div:hover": {
              zIndex: 1,
            },
            "& img": {
              width: { lg: "250px", md: "200px", xs: "150px" },
            },
          }}
        >
          <Box
            onMouseEnter={() => setIsPlaying(false)}
            onMouseLeave={() => setIsPlaying(true)}
          >
            <Marquee play={isPlaying} gradient={false} speed={50}>
              <img src={NeumaS1} alt="No Info" />

              <img src={NeumaS2} alt="No Info" />

              <img src={NeumaS3} alt="No Info" />

              <Box>
                <img
                  src={NeumaS4}
                  alt="No Info"
                  style={{ marginBottom: "4px" }}
                />
                <Box
                  sx={{
                    px: { sm: 3, xs: 1 },
                    py: { sm: 3.5, xs: 1.5 },
                    background: (theme: Theme) => theme.palette.secondary.main,
                    fontSize: { sm: "24px", xs: "14px" },
                    fontWeight: 600,
                    color: "#000",
                    position: "absolute",
                    marginTop: 2,
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: { sm: "-30px", xs: "-20px" },
                      left: "100px",
                      transform: "rotate(180deg)",
                      "& *": {
                        fill: (theme: Theme) => theme.palette.secondary.main,
                      },
                    }}
                  >
                    <ToolTipIcons />
                  </Box>
                  Inspired by Nest Thermostat
                </Box>
              </Box>
              <img src={NeumaS5} alt="No Info" />

              <img src={NeumaS6} alt="No Info" />
            </Marquee>
          </Box>
          <Box
            onMouseEnter={() => setIsPlaying(false)}
            onMouseLeave={() => setIsPlaying(true)}
          >
            <Marquee
              style={{ marginTop: "160px" }}
              play={isPlaying}
              gradient={false}
              speed={50}
              direction="right"
            >
              <img src={NeumaS6} alt="No Info" />
              <img src={NeumaS7} alt="No Info" />

              <img src={NeumaS8} alt="No Info" />
              <img src={NeumaS9} alt="No Info" />
              <img src={NeumaS5} alt="No Info" />
              <img src={NeumaS10} alt="No Info" />
            </Marquee>
          </Box>
        </Box>
      </SectionFifteenWrapper>
      <SectionEightWrapper>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: { md: "40px", sm: "32px", xs: "28px" },
              fontWeight: 700,
              fontFamily: "Mohave",
              fontStyle: "italic",
            }}
          >
            HOW I STEERED THROUGH SO MANY OPTIONS
          </Typography>
        </AnimationOnScroll>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Typography
            sx={{
              fontSize: { md: "22px", xs: "18px" },
              mt: 3,
              width: { lg: "70%", xs: "100%" },
            }}
          >
            The goal throughout the process of designing this app was to keep it
            easy-to-use. To determine what’s easy-to-use and what’s not, I
            handpicked a few variations from the exploration done earlier, based
            on accessibility, and balanced visuals.
          </Typography>
        </AnimationOnScroll>

        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
          delay={300}
        >
          <Box
            sx={{
              py: 3.5,
              px: 4,
              mt: 10,
              width: { lg: "72%", xs: "100%" },
              background: (theme: Theme) => theme.palette.secondary.main,
            }}
          >
            <Typography
              sx={{
                fontSize: { md: "28px", xs: "20px" },
                fontWeight: 600,
                lineHeight: "33.89px",
              }}
            >
              Some of the explorations were now shared with my colleagues (who
              weren’t involved in this project), to understand the general
              sentiment around each design variation.
            </Typography>
          </Box>
        </AnimationOnScroll>
      </SectionEightWrapper>
      <SectionEightWrapper id="section3">
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: { md: "40px", sm: "32px", xs: "28px" },
              fontWeight: 700,
              fontFamily: "Mohave",
              fontStyle: "italic",
            }}
          >
            THE RESULT?
          </Typography>
        </AnimationOnScroll>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Typography
            sx={{
              fontSize: { md: "22px", xs: "18px" },
              mt: 3,
              width: { lg: "70%", xs: "100%" },
            }}
          >
            While most people appreciated the unique design variations, the
            general sentiment still leaned towards either a strictly vertical,
            or a strictly horizontal design.
          </Typography>
        </AnimationOnScroll>
        <Box sx={{ mt: 10 }}>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
          >
            <Typography
              variant="h3"
              sx={{ fontSize: { md: "28px", xs: "20px" }, fontWeight: 600 }}
            >
              Which is why.....
            </Typography>
          </AnimationOnScroll>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
            delay={300}
          >
            <Typography sx={{ fontSize: { md: "22px", xs: "18px" }, mt: 2.5 }}>
              I created interactive prototypes for both, vertical, and a
              horizontal approach.
            </Typography>
          </AnimationOnScroll>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
            delay={300}
          >
            <Box
              sx={{
                mt: 2.5,
                "& p": {
                  fontSize: { md: "22px", xs: "18px" },
                  color: "#000",
                },
                display: "flex",
                alignItems: { md: "center", xs: "left" },
                flexDirection: { md: "row", xs: "column" },
              }}
            >
              <Typography>Prototype links - </Typography>
              <Box
                sx={{
                  mt: { md: 0, xs: 5 },
                  ml: { sm: 3.5, xs: 1 },
                  display: "flex",
                  "& button": {
                    px: 1.5,
                    py: 1.25,
                    borderRadius: 0,
                    transition: "0.2s",
                    zIndex: 0,
                    "& *": {
                      color: "#fff",
                      textDecoration: "none",
                    },
                    "&:hover": {
                      background: "#0021F5",
                    },
                    "&:before": {
                      content: "''",
                      width: "100%",
                      height: "100%",
                      background: "transparent",
                      border: "2px solid #0021F5",
                      top: 4,
                      left: 4,
                      zIndex: -1,
                      transition: "0.2s",
                      position: "absolute",
                    },
                    "&:active": {
                      transition: "0.2s",
                      "&:before": {
                        transform: "translate(-4px,-4px)",
                        zIndex: -1,
                      },
                      transform: "translate(4px,4px)",
                      zIndex: 0,
                    },
                  },
                }}
              >
                <Button variant="contained">
                  <Link
                    to="/files/Vertical_Interactive.html"
                    target="_blank"
                    download
                    rel="noopener noreferrer"
                  >
                    VERTICAL APPROACH
                  </Link>
                </Button>
                <Button sx={{ ml: 5 }} variant="contained">
                  <Link
                    to="/files/Horizontal_Interactive.html"
                    target="_blank"
                    download
                    rel="noopener noreferrer"
                  >
                    HORIZONTAL APPROACH
                  </Link>
                </Button>
              </Box>
            </Box>
          </AnimationOnScroll>
        </Box>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
          delay={300}
        >
          <Box
            sx={{
              py: 3.5,
              px: 4,
              mt: 10,
              width: { lg: "72%", xs: "100%" },
              background: (theme: Theme) => theme.palette.secondary.main,
            }}
          >
            <Typography
              sx={{
                fontSize: { md: "28px", xs: "20px" },
                fontWeight: 600,
                lineHeight: "33.89px",
              }}
            >
              The interactive prototypes were now shared with another unique set
              of people - Unmoderated A/B Testing
            </Typography>
          </Box>
        </AnimationOnScroll>
      </SectionEightWrapper>
      <SectionEightWrapper>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: { md: "40px", sm: "32px", xs: "28px" },
              fontWeight: 700,
              fontFamily: "Mohave",
              fontStyle: "italic",
            }}
          >
            FINAL OUTCOME
          </Typography>
        </AnimationOnScroll>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
          delay={300}
        >
          <Box
            sx={{
              py: 3.5,
              px: 4,
              mt: 10,
              width: { lg: "72%", xs: "100%" },
              display: "flex",
              alignItems: "flex-start",
              background: (theme: Theme) => theme.palette.secondary.main,
            }}
          >
            <Box
              sx={{
                mr: 1,
              }}
            >
              <Bulb2Icon />
            </Box>
            <Typography
              sx={{
                fontSize: { md: "28px", xs: "20px" },
                fontWeight: 600,
                lineHeight: "33.89px",
              }}
            >
              Majority of the people preferred the "vertical version". Why? -
              “It resembles the volume bar in my phone, and I’m used to
              interacting with it”
            </Typography>
          </Box>
        </AnimationOnScroll>
        <Box
          sx={{
            mt: { md: 10, xs: 5 },
            position: "relative",
            "& img": {
              borderRadius: "12px",
              boxShadow: "4px 0px 20px 0px #66666640",
            },
            "& p": {
              fontFamily: "Caveat",
              color: "#000",
              fontSize: { md: "22px", xs: "18px" },
            },
          }}
        >
          <img src={NeumaGIF} alt="no gif" />
          <Box
            sx={{
              position: { md: "absolute", xs: "relative" },
              display: { md: "block", xs: "none" },
              top: { md: "15%" },
              left: { md: "20%" },
              zIndex: -1,
              transform: "rotate(150deg)",
            }}
          >
            <Line1 />
          </Box>
          <Typography
            sx={{
              position: { md: "absolute", xs: "relative" },
              top: { md: "25%" },
              left: { lg: "35%", md: "48%" },
              mt: 2,
            }}
          >
            On scroll, arrows change weight to <br />
            indicate scope of further runway
          </Typography>
          <Box
            sx={{
              position: { md: "absolute", xs: "relative" },
              top: { md: "55%" },
              display: { md: "block", xs: "none" },
              left: { md: "20%" },
              zIndex: -1,
              transform: "rotateY(180deg) rotateZ(-27deg)",
            }}
          >
            <Line1 />
          </Box>
          <Typography
            sx={{
              position: { md: "absolute", xs: "relative" },
              top: { md: "50%" },
              left: { lg: "35%", md: "48%" },
              mt: 2,
            }}
          >
            Waves on bed change curve to indicate <br />
            firmness (wavier and straighter)
          </Typography>
        </Box>
      </SectionEightWrapper>
      <Box
        sx={{
          position: "fixed",
          width: "1px",
          height: "1px",
          background: (theme: Theme) => theme.palette.primary.main,
          top: 0,
          zIndex: 999999999999,
          left: 0,
          borderRadius: "100%",
          animation: "animateCircle1 0.5s",
          animationIterationCount: 1,
          "@keyframes animateCircle1": {
            from: {
              transform: "scale(4000)",
            },
            to: { transform: "scale(0)" },
          },
        }}
      ></Box>
    </ProjectWrapper>
  );
};

export default withAuth(Project);
