const withAuth = (WrappedComponent: any) => {
  return (props: any) => {
    // const user = localStorage.getItem("user");
    // if (!user) {
    //   window.location.replace("/");
    //   return null;
    // }
    return <WrappedComponent {...props} />;
  };
};

export default withAuth;
