import { FC, useState } from "react";
import { useRoutes } from "react-router-dom";
import LayoutContainer from "../LayoutContainer";
import About from "../Pages/About";
import Home from "../Pages/Home";
import LockScreen from "../Pages/LockScreen";
import NotFound from "../Pages/NotFound";
import Project from "../Pages/Project";
import Project2 from "../Pages/Project2";
import Project3 from "../Pages/Project3";
import Project4 from "../Pages/Project4";
import Project5 from "../Pages/Project5";

const AppRoutes: FC = () => {
  const [playOff, setPlayOff] = useState(false);

  const Routes = useRoutes([
    {
      path: "/",
      element: (
        <LayoutContainer setPlayOff={(value: any) => setPlayOff(value)} />
      ),
      children: [
        {
          index: true,
          element: <Home />,
        },
        {
          path: "/project",
          element: <Project />,
        },
        {
          path: "/project2",
          element: <Project2 />,
        },
        {
          path: "/project3",
          element: <Project3 />,
        },
        {
          path: "/project4",
          element: <Project4 />,
        },
        {
          path: "/project5",
          element: <Project5 playOff />,
        },
        {
          path: "/about",
          element: <About />,
        },
      ],
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);
  return Routes;
};

export default AppRoutes;
