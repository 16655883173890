import React from "react";

const SmileIcon = () => {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5 1.00012C11.6543 1.00012 7.9663 2.52786 5.24696 5.24708C2.52774 7.9663 1 11.6547 1 15.5001C1 19.3455 2.52774 23.0338 5.24696 25.7532C7.96618 28.4724 11.6546 30.0001 15.5 30.0001C19.3454 30.0001 23.0337 28.4724 25.753 25.7532C28.4723 23.0339 30 19.3455 30 15.5001C29.9957 11.6558 28.4667 7.96987 25.7483 5.25168C23.03 2.5335 19.3441 1.00432 15.4999 1L15.5 1.00012ZM15.5 28.2959C12.1065 28.2959 8.85184 26.9477 6.45242 24.5482C4.05284 22.1486 2.70468 18.8939 2.70468 15.5006C2.70468 12.1072 4.05284 8.85241 6.45242 6.453C8.85201 4.05341 12.1066 2.70525 15.5 2.70525C18.8934 2.70525 22.1482 4.05341 24.5476 6.453C26.9472 8.85259 28.2953 12.1072 28.2953 15.5006C28.292 18.8932 26.9427 22.1459 24.544 24.5447C22.1451 26.9434 18.8925 28.2927 15.4999 28.296L15.5 28.2959Z"
        fill="black"
        stroke="black"
        stroke-width="1.2"
      />
      <path
        d="M11.8353 12.771C11.8353 13.7375 11.0518 14.5208 10.0855 14.5208C9.11923 14.5208 8.33594 13.7375 8.33594 12.771C8.33594 11.8048 9.11923 11.0215 10.0855 11.0215C11.0518 11.0215 11.8353 11.8048 11.8353 12.771Z"
        fill="black"
      />
      <path
        d="M11.8353 12.771C11.8353 13.7375 11.0518 14.5208 10.0855 14.5208C9.11923 14.5208 8.33594 13.7375 8.33594 12.771C8.33594 11.8048 9.11923 11.0215 10.0855 11.0215C11.0518 11.0215 11.8353 11.8048 11.8353 12.771"
        stroke="black"
        stroke-width="1.2"
      />
      <path
        d="M22.6634 12.771C22.6634 13.7375 21.8801 14.5208 20.9139 14.5208C19.9476 14.5208 19.1641 13.7375 19.1641 12.771C19.1641 11.8048 19.9476 11.0215 20.9139 11.0215C21.8801 11.0215 22.6634 11.8048 22.6634 12.771Z"
        fill="black"
      />
      <path
        d="M22.6634 12.771C22.6634 13.7375 21.8801 14.5208 20.9139 14.5208C19.9476 14.5208 19.1641 13.7375 19.1641 12.771C19.1641 11.8048 19.9476 11.0215 20.9139 11.0215C21.8801 11.0215 22.6634 11.8048 22.6634 12.771"
        stroke="black"
        stroke-width="1.2"
      />
      <path
        d="M20.3626 19.6274C14.8558 24.361 10.8955 19.9142 10.7315 19.7209C10.525 19.5269 10.2359 19.448 9.95947 19.5103C9.68323 19.5723 9.45555 19.7674 9.35213 20.0311C9.24852 20.2947 9.28246 20.5926 9.44249 20.8261C10.8259 22.3358 12.7158 23.2841 14.7528 23.4908C14.9483 23.5059 15.1428 23.5133 15.3361 23.5133C17.4048 23.5133 19.467 22.6433 21.4773 20.9195C21.6796 20.7139 21.7646 20.4201 21.703 20.1383C21.6412 19.8564 21.4413 19.6247 21.1716 19.5224C20.9019 19.4199 20.5989 19.4608 20.3656 19.6307L20.3626 19.6274Z"
        fill="black"
        stroke="black"
        stroke-width="1.2"
      />
    </svg>
  );
};

export default SmileIcon;
