import { Box, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import MouseBox from "../MouseBox";

interface ZoomedTextProps {
  text: string;
  element: any;
  setStatus: Function;
}

const angle = (cx: number, cy: number, ex: number, ey: number) => {
  const dy = ey - cy;
  const dx = ex - cx;
  const rad = Math.atan2(dy, dx);
  const deg = (rad * 180) / Math.PI;
  return deg;
};

const ZoomedText = ({ text, element, setStatus, ...rest }: ZoomedTextProps) => {
  const box = useRef<any>();
  const header = useRef<any>();
  const [headerPosition, setHeaderPosition] = useState({
    left: 0,
    right: 0,
  });

  const r: any = document.querySelector(":root");

  useEffect(() => {
    if (header || box) {
      for (let i = 0; i < header.current.children.length; i++) {
        const ele: any = header.current.children[i];
        ele.addEventListener("mouseleave", (e: any) => {
          e.target.style.transform = "scaleY(1)";
        });
      }
      // window.addEventListener("mousemove", (e: any) => {});
    }
  }, []);

  const MouseMove = (e: any) => {
    if (window.innerWidth > 1024) {
      if (box.current) {
        if (element.current && e.pageY > element.current.offsetHeight) {
          box.current.style.visibility = "hidden";
          for (let i = 0; i < header.current.children.length; i++) {
            const ele: any = header.current.children[i];
            ele.style.transform = "scaleY(1)";
          }
        } else {
          box.current.style.visibility = "visible";

          const headerLeft =
            header.current.offsetLeft - header.current.offsetWidth / 2;
          const headerRight = headerLeft + header.current.offsetWidth;

          if (headerLeft && headerRight) {
            setHeaderPosition({ left: headerLeft, right: headerRight });
          }

          if (e.pageY < element.current.offsetHeight) {
            r.style.setProperty("--imageX", e.clientX + "px");
            r.style.setProperty("--imageY", e.clientY + "px");
            if (headerLeft - 100 < e.x || headerRight + 100 < e.x) {
              for (let i = 0; i < header.current.children.length; i++) {
                const ele: any = header.current.children[i];
                const eleRect = ele.getBoundingClientRect();
                if (eleRect.x - 200 < e.x && e.x < eleRect.x + 200) {
                  const scaleValue =
                    (ele.innerText === "o" ||
                    ele.innerText === "r" ||
                    ele.innerText === "y"
                      ? 6
                      : 5) -
                    (Math.abs(eleRect.x - e.x) / 100) * 2;
                  ele.style.transform = "scaleY(" + scaleValue + ")";
                } else {
                  ele.style.transform = "scaleY(1)";
                }
              }
            } else {
              for (let i = 0; i < header.current.children.length; i++) {
                const ele: any = header.current.children[i];

                ele.style.transform = "scaleY(1)";
              }
            }
            const centerX = window.innerWidth / 2;
            const centerY = window.innerHeight / 2;
            const mouseX = e.clientX;
            const mouseY = e.clientY;

            const angleDeg = angle(centerX, centerY, mouseX, mouseY) + 180;
            if (e.x > headerLeft && e.x < headerRight) {
              box.current.style.width = "300px";
              box.current.style.height = "80px";
              box.current.innerHtml = "";
              box.current.innerText = "CLICK TO EXPLORE";
              box.current.style.zIndex = 999;
              box.current.style.cursor = "pointer";
              box.current.style.fontSize = "32px";
              box.current.style.transition = "0.1s";
              box.current.style.background = "#ADFF00";
              box.current.style.transform =
                "translate(" +
                (e.x - box.current.offsetWidth / 2) +
                "px," +
                (e.y - box.current.offsetHeight / 2) +
                "px) rotate(0deg)";
            } else {
              box.current.style.fontSize = "0px";
              box.current.style.width = "104px";
              box.current.style.height = "65px";
              box.current.innerText = "";
              box.current.cursor = "none";
              box.current.style.zIndex = 0;
              box.current.style.transition = "0s";
              box.current.style.background = "url('files/arrow.svg')";
              box.current.style.transform =
                "translate(" +
                (e.x - box.current.offsetWidth / 2) +
                "px," +
                (e.y - box.current.offsetHeight / 2) +
                "px) rotate(" +
                angleDeg +
                "deg)";
            }
          }
        }
      }
    }
  };
  return (
    <>
      {/* <Box
        id="box"
        ref={box}
        onClick={(e: React.MouseEvent<HTMLDivElement>) => {
          if (
            e.clientX > headerPosition.left &&
            e.clientX < headerPosition.right
          ) {
            setStatus(true);
          }
        }}
        sx={{
          width: "10px",
          height: "10px",
          background: "#ADFF00",
          position: "fixed",
          fontFamily: "Mohave",
          fontSize: "0px",
          color: "#000",
          fontWeight: 700,
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          top: 0,
          left: 0,
          transition: "0.1s",
        }}
      ></Box> */}
      <MouseBox
        handleClick={() => {
          const boxPosition = box.current.getBoundingClientRect().x + 150;
          if (
            boxPosition > headerPosition.left &&
            boxPosition < headerPosition.right
          ) {
            setStatus(true);
          }
        }}
        MouseMove={MouseMove}
        boxRef={box}
      />
      <Typography
        id="text"
        ref={header}
        variant="h1"
        onClick={() => {
          setStatus(true);
        }}
        sx={{
          fontSize: { md: "190px", sm: "140px", xs: "60px" },
          fontFamily: "Mohave",
          fontWeight: 700,
          position: "absolute",
          //   border: "5px solid",
          left: { lg: "50%", md: "30%", sm: "40%", xs: "40%" },
          top: "50%",
          zIndex: -1,
          transform: {
            lg: "translate(-50%,-50%)",
            md: "translate(-20%,-50%)",
            sm: "translate(-30%,-50%)",
            xs: "translate(-30%,-70%)",
          },
          "& span": {
            transition: "2s cubic-bezier(0.075, 0.82, 0.165, 1)",
          },
        }}
      >
        {text.split("").map((i, index) => (
          <span key={index} style={{ display: "inline-block" }}>
            {i === " " ? "\u00A0" : i}
          </span>
        ))}
      </Typography>
    </>
  );
};

export default ZoomedText;
