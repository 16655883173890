import { Box, styled, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const NotFoundWrapper = styled(Box)({
  width: "100%",
  height: "calc(100vh - 254px)",
  display: "flex",
  padding: "20px 0",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

const NotFound = () => {
  return (
    <>
      <NotFoundWrapper>
        <Box sx={{ m: 5 }}>
          <Typography variant="h3">404 Not Found</Typography>
        </Box>
        <Link to="/">Back To Home Page</Link>
      </NotFoundWrapper>
    </>
  );
};

export default NotFound;
