import { Box, Theme, Toolbar } from "@mui/material";
import React, { useState, useEffect } from "react";
import { SideComponentChild, SideComponentWrapper } from "./styled";

// import Logo1 from "../../Assets/images/Logo1.png";
// import Logo2 from "../../Assets/images/Logo2.png";
// import Logo3 from "../../Assets/images/Logo3.png";
// import Logo4 from "../../Assets/images/Logo4.png";
// import Logo5 from "../../Assets/images/Logo5.png";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../../Assets/Icons/Logo";
const menuItems = [
  { name: "DESCRIPTION." },
  { name: "PROCESS." },
  { name: "SOLUTIONS." },
  { name: "IMPACT." },
];

const SideComponent = ({ setPlayOff }: { setPlayOff: Function }) => {
  // const [Logos, setLogos] = useState(Logo1);
  const handleScroll = (index: number) => {
    const section = document.getElementById("section" + index);
    const position = section?.offsetTop ?? 0;

    window.scrollTo({ top: position - 100 });
  };

  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === "/") {
      // setLogos(Logo1);
      // window.removeEventListener("scroll", setScrollLogos);
      window.removeEventListener("scroll", setNavActive);
    } else {
      // setLogos(Logo2);
      // window.addEventListener("scroll", setScrollLogos);
      window.addEventListener("scroll", setNavActive);
    }
  }, [pathname]); //eslint-disable-line

  // const setScrollLogos = (e: any) => {
  //   const TF = (document.body.scrollHeight * 25) / 100;
  //   const SZ = (document.body.scrollHeight * 70) / 100;
  //   const HD = document.body.scrollHeight - window.innerHeight - 100;
  //   if (window.scrollY < TF) {
  //     if (Logos !== Logo2) setLogos(Logo2);
  //   }
  //   if (window.scrollY > TF) {
  //     if (Logos !== Logo3) setLogos(Logo3);
  //   }
  //   if (window.scrollY > SZ) {
  //     if (Logos !== Logo4) setLogos(Logo4);
  //   }
  //   if (window.scrollY > HD) {
  //     if (Logos !== Logo5) setLogos(Logo5);
  //   }
  // };

  const setNavActive = () => {
    let currentMax = 0;
    for (let i = 1; i <= 4; i++) {
      const section = document.getElementById("section" + i);
      const position = section?.offsetTop ?? 0;
      if (position !== 0) {
        if (window.scrollY > position - 100) {
          currentMax = i;
        }
      }
    }
    const nav = document.getElementById("nav" + currentMax);
    if (nav) {
      nav.classList.add("active");
      for (let i = 1; i <= 4; i++) {
        if (i !== currentMax) {
          const navSection = document.getElementById("nav" + i);
          navSection && navSection.classList.remove("active");
        }
      }
    }
  };
  return (
    <Box>
      <Toolbar
        sx={{
          display: { sm: "block", xs: "none" },
          p: "0 !important",
          pl: "8px !important",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            "& img": {
              mt: 1.5,
              ml: -2.25,
              // animation: "1s imageAnimation ease-in-out",
            },
            "@keyframes imageAnimation": {
              "0%": {
                transform: "translateY(70px)",
              },
              "100%": {
                transform: "translateY(0px)",
              },
            },
          }}
          onClick={() => {
            if (pathname === "/project5") {
              setPlayOff(true);
            } else {
              setPlayOff(false);
            }
            navigate("/");
          }}
        >
          <Box
            sx={{
              ml: -1,
              lineHeight: 0,
              mt: 1.1,
              cursor: "pointer",
              "& svg": {
                width: "60px",
                height: "55px",
              },
            }}
          >
            <Logo />
          </Box>
        </Box>
      </Toolbar>
      {pathname !== "/" && pathname !== "/project5" && (
        <SideComponentWrapper>
          <SideComponentChild>
            {menuItems.map((item, index) => (
              <Box
                id={"nav" + (index + 1)}
                sx={{
                  background: "transparent",
                  color: "text.primary",
                  transition: "0s",
                  "&:hover": {
                    background: (theme: Theme) => theme.palette.primary.main,
                    color: "#fff",
                    cursor: "pointer",
                  },
                }}
                onClick={() => handleScroll(index + 1)}
              >
                {item.name}
              </Box>
            ))}
          </SideComponentChild>
        </SideComponentWrapper>
      )}
    </Box>
  );
};

export default SideComponent;
