import React from "react";

const TriangleIcon = () => {
  return (
    <svg
      width="34"
      height="29"
      viewBox="0 0 34 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.8"
        d="M17 0L33.4545 28.5H0.545517L17 0Z"
        fill="#FF1E1E"
      />
    </svg>
  );
};

export default TriangleIcon;
