import React from "react";
import { Paper, Theme, Box, Typography, Button } from "@mui/material";
import AboutLine from "../../Assets/Icons/AboutLine";
import Wave from "../../Assets/images/wave.png";
import Me from "../../Assets/images/me.jpg";
const About = () => {
  return (
    <Box
      sx={{
        width: "100vw",
        overflow: "hidden",
        height: "calc(100vh - 56px)",
        background: (theme: Theme) => theme.palette.primary.main,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Paper
        sx={{
          background: "transparent",
          boxShadow: "none",
          mt: 1.2,
          mr: -3,
          width: "450px",
          color: "#fff",
        }}
      >
        <Box
          sx={{
            py: 5,
            px: 3.5,
            borderBottom: "1px solid #fff",
          }}
        >
          <Typography
            sx={{
              fontSize: { md: "40px", sm: "32px", xs: "28px" },
              fontWeight: 700,
            }}
          >
            This is me
          </Typography>
          <Box
            sx={{
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "110px",
                height: "110px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                "& img": {
                  width: "100%",
                  height: "auto",
                  borderRadius: "100%",
                  border: "5px solid #ADFF00",
                  cursor: "pointer",
                  animation: "rotation 8s infinite linear ",
                  animationPlayState: "paused",
                  "&:hover": {
                    animationPlayState: "running",
                  },
                  "@keyframes rotation": {
                    from: {
                      transform: "rotate(0deg)",
                    },
                    to: {
                      transform: "rotate(360deg)",
                    },
                  },
                },
              }}
            >
              <img src={Me} alt="no content" />
              <Typography
                sx={{
                  fontSize: "12px",
                  fontFamily: "Mohave",
                  color: "#ADFF00",
                  fontWeight: 700,
                  fontStyle: "italic",
                  mt: 1,
                }}
              >
                SAIADITYA GARYALI
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontFamily: "Mohave",
                  color: "#ADFF00",
                  fontWeight: 700,
                  fontStyle: "italic",
                }}
              >
                1998 - PRESENT
              </Typography>
            </Box>
            <Box
              sx={{
                position: "relative",
                pl: 0.5,
                height: "110px",
                width: "250px",
              }}
            >
              <Box sx={{ position: "absolute", top: 0 }}>
                <AboutLine />
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  background: (theme: Theme) => theme.palette.primary.main,
                  width: "150px",
                  height: "70px",
                  animation: "aboutLine 1.5s forwards",
                  "@keyframes aboutLine": {
                    from: {
                      transform: "translate(0,0)",
                    },
                    to: {
                      transform: "translate(150px,0)",
                    },
                  },
                }}
              ></Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                }}
              >
                <Typography
                  sx={{
                    fontSize: { md: "22px", xs: "16px" },
                    fontWeight: 700,
                  }}
                >
                  Hey there!
                </Typography>
                <img src={Wave} alt="no wave" style={{ marginTop: -1 }} />
              </Box>
            </Box>
          </Box>
          <Typography
            sx={{
              fontSize: { md: "40px", sm: "32px", xs: "28px" },
              fontWeight: 700,
              mt: 10,
            }}
          >
            A bit more...
          </Typography>
          <Typography
            sx={{
              mt: 1.5,
              maxWidth: 350,
              fontSize: { md: "24px", xs: "16px" },
              fontWeight: 600,
            }}
          >
            explorer, designer, intj, learner, cyclist, meditator
          </Typography>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            sx={{
              fontSize: { lg: "20px", xs: "16px" },
              p: 3.5,
              fontWeight: 700,
            }}
          >
            My MBTI Personality Type
          </Typography>
          <Box
            sx={{
              mt: 0,
              ml: { sm: 3.5, xs: 1 },
              display: "flex",
              "& button": {
                px: 1.5,
                py: 1.25,
                borderRadius: 0,
                transition: "0.2s",
                zIndex: 0,
                background: "#ADFF00",
                fontWeight: 700,
                fontSize: "16px",
                color: "#000",
                "& *": {
                  textDecoration: "none",
                  color: "#000",
                },
                "&:hover": {
                  background: "#ADFF00",
                },
                "&:before": {
                  content: "''",
                  width: "100%",
                  height: "100%",
                  background: "transparent",
                  border: "2px solid #ADFF00",
                  top: 4,
                  left: 4,
                  zIndex: -1,
                  transition: "0.2s",
                  position: "absolute",
                },
                "&:active": {
                  transition: "0.2s",
                  "&:before": {
                    transform: "translate(-4px,-4px)",
                    zIndex: -1,
                  },
                  transform: "translate(4px,4px)",
                  zIndex: 0,
                },
              },
            }}
          >
            <Button variant="contained">
              <a
                href="https://www.16personalities.com/intj-personality"
                target="_blank"
                rel="noreferrer"
              >
                INTJ
              </a>
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default About;
