import { Typography, Box, Theme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import {
  HomeWrapper,
  ImageContent,
  ProjectSection,
  ProjectWrapper,
  ProjectWrapperParent,
  TitleWapper,
} from "./styled";
import Deloitte from "../../Assets/images/Deloitte.png";
import NYU from "../../Assets/images/nyu.png";
import Neuma from "../../Assets/images/neuma.png";
import AuthorCafe from "../../Assets/images/authorcafe.png";
import PharmaGiant from "../../Assets/images/phamagiant.png";
import DataBass from "../../Assets/images/databass.png";
import Coding from "../../Assets/images/coding.png";
import ArrowIcon from "../../Assets/Icons/ArrowIcon";
import Canvas from "../../Components/UI/Canvas";

import ZoomedText from "../../Components/UI/ZoomedText";
import { useLocation, useNavigate } from "react-router-dom";
import withAuth from "../../HOC/withAuth";

const projects = [
  {
    name: "AMERICAN PHARMA GIANT",
    image: PharmaGiant,
    imageContent: "Optimizing supply chain data entry & monitoring",
    link: "/project",
  },
  {
    name: "NEUMA",
    image: Neuma,
    imageContent: "First-ever smart mattress for India",
    link: "/project2",
  },
  {
    name: "AUTHORCAFÉ",
    image: AuthorCafe,
    imageContent: "Word-editor, for researchers",
    link: "/project3",
  },
  {
    name: "DATABASS AI",
    image: DataBass,
    imageContent: "Improving text-to-music accuracy through design",
    link: "/project4",
  },
  {
    name: "CREATIVE CODING",
    image: Coding,
    imageContent: "Some cool animations made in p5.js",
    link: "/project5",
  },
];

const Home = () => {
  const homeWrapper = useRef<any>();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [status, setStatus] = useState(false);
  const [scrollStatus, setScrollStatus] = useState(false);
  const [dropStatus, setDropStatus] = useState(false);
  const r: any = document.querySelector(":root");
  const lastPoint: any = { x: null, y: null };
  const [movePage, setMovePage] = useState(false);
  const [toolTip, setToolTip] = useState({
    image: "",
    content: "",
  });

  const handleScroll = () => {
    const section = document.getElementById("section1");
    const position = section?.offsetTop ?? 0;
    window.scrollTo({ top: position });
  };

  useEffect(() => {
    if (status) {
      handleScroll();
    }
  }, [status]);

  useEffect(() => {
    window.addEventListener("scroll", (e: Event) => {
      if (window.scrollY <= 10) {
        setStatus(false);
      }
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleClick = (link: string) => {
    setTimeout(() => {
      navigate(link);
      setMovePage(false);
    }, 300);
  };

  return (
    <Box
      sx={{
        height: { md: scrollStatus ? "" : "90vh" },
        overflow: { md: scrollStatus ? "" : "hidden" },
      }}
    >
      <div ref={homeWrapper}>
        <HomeWrapper>
          <ZoomedText
            text="My Work"
            element={homeWrapper}
            setStatus={() => {
              setStatus(true);
              setDropStatus(true);
              !scrollStatus && setScrollStatus(true);
            }}
          />
          <TitleWapper>
            <Box>
              <Typography
                sx={{
                  fontSize: { md: "22px", sm: "16px", xs: "16px" },
                  textTransform: "uppercase",
                  fontWeight: 600,
                  mb: { sm: -1.8 },
                }}
              >
                HI, I’M ADITYA (ADI)
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  gap: { sm: 3, xs: 1 },
                }}
              >
                <Typography
                  sx={{
                    my: { sm: 0, xs: 2 },
                    fontSize: { md: "22px", sm: "16px", xs: "16px" },
                    textTransform: "uppercase",
                    fontWeight: 600,
                  }}
                >
                  ENJOYING{" "}
                  <Typography
                    component={"span"}
                    sx={{
                      fontSize: { md: "22px", sm: "16px", xs: "16px" },
                      textTransform: "uppercase",
                      fontWeight: 600,
                      zIndex: 99999999,
                      "&:hover": {
                        color: "red",
                      },
                    }}
                  >
                    IDM
                  </Typography>{" "}
                  [MS] @
                </Typography>
                <Box
                  sx={{
                    lineHeight: 0,
                    "& img": {
                      width: { md: "120px", sm: "100px", xs: "80px" },
                      mb: 0.5,
                    },
                  }}
                >
                  <img src={NYU} alt="no brand" />
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "baseline" }}>
              <Typography
                sx={{
                  fontSize: { md: "22px", sm: "16px", xs: "16px" },
                  textTransform: "uppercase",
                  fontWeight: 600,
                  mr: 2.5,
                }}
              >
                PREV. UX DESIGNER AT
              </Typography>
              <Box
                sx={{
                  "& img": {
                    width: { md: "180px", sm: "140px", xs: "100px" },
                  },
                }}
              >
                <img src={Deloitte} alt="no brand" />
              </Box>
            </Box>
          </TitleWapper>
          <Box sx={{ display: { md: "block", xs: "none" } }}>
            <Canvas status={dropStatus} />
          </Box>
        </HomeWrapper>
      </div>
      <ProjectSection
        id="section1"
        sx={{ mb: 5 }}
        onMouseMove={(event: any) => {
          const element = event.target.parentElement.parentElement
            .children[0] as HTMLDivElement;
          if (element) {
            // element.style.transition = "0.3s cubic-bezier(.55,.46,.35,.9)";
            // element.style.top = "0";
            // element.style.left = "0";
            r.style.setProperty("--imageX", "0px");
            r.style.setProperty("--imageY", "0px");
            r.style.setProperty("--x", event.clientX - 170 + "px");
            r.style.setProperty("--y", event.clientY - 150 + "px");
            r.style.setProperty("--scale", 1);
            r.style.setProperty(
              "--transition",
              "0.1s cubic-bezier(.55,.46,.35,.9)"
            );
            r.style.setProperty(
              "--rotate",
              event.clientY > lastPoint.y
                ? "-2deg"
                : event.clientY < lastPoint.y
                ? "2deg"
                : 0
            );
            lastPoint.x = event.clientX;
            lastPoint.y = event.clientY;
          }
        }}
        onMouseLeave={(e: any) => {
          r.style.setProperty("--scale", 0);
          r.style.setProperty("--transition", "0s");
          r.style.setProperty("--imageX", e.clientX + "px");
          r.style.setProperty("--imageY", e.clientY + "px");
        }}
      >
        {projects.map((item, index) => (
          <ProjectWrapper
            key={index}
            sx={{
              borderBottom:
                index === projects.length - 1
                  ? (theme: Theme) =>
                      "1.5px solid " + theme.palette.primary.main
                  : "none",
            }}
          >
            <ProjectWrapperParent
              onMouseEnter={() => {
                setToolTip({ image: item.image, content: item.imageContent });
              }}
              onClick={() => {
                setMovePage(true);
                handleClick(item.link);
              }}
            ></ProjectWrapperParent>
            <Typography variant="h1">{item.name}</Typography>
            <Box
              className="Icon"
              sx={{
                opacity: 0,
                display: { md: "flex", xs: "none" },
                transition: "visibility 0s, opacity 0.5s linear",

                alignItems: "center",
                "& * ": {
                  fill: "#0021F5",
                },
              }}
            >
              <ArrowIcon />
            </Box>
          </ProjectWrapper>
        ))}
      </ProjectSection>
      <ImageContent className="imageContent">
        {toolTip.content && (
          <>
            <img src={toolTip.image} alt="No Content" />
            <Box>
              <Typography>{toolTip.content}</Typography>
            </Box>
          </>
        )}
      </ImageContent>
      <br />
      <br />
      {movePage && (
        <Box
          sx={{
            position: "fixed",
            width: "1px",
            height: "1px",
            background: (theme: Theme) => theme.palette.primary.main,
            bottom: 0,
            zIndex: 999999999999,
            right: 0,
            borderRadius: "100%",
            animation: "animateCircle 0.5s",
            "@keyframes animateCircle": {
              from: {
                transform: "scale(0)",
              },
              to: { transform: "scale(4000)" },
            },
          }}
        ></Box>
      )}
    </Box>
  );
};

export default withAuth(Home);
