import React from "react";

const ToolTipIcons = () => {
  return (
    <svg
      width="27"
      height="24"
      viewBox="0 0 27 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.5 24L0.0766068 0.75L26.9234 0.75L13.5 24Z" fill="black" />
    </svg>
  );
};

export default ToolTipIcons;
