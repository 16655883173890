import React from "react";

const RoundText = () => {
  return (
    <svg
      width="163"
      height="158"
      viewBox="0 0 163 158"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M56.5495 144.1L52.4561 153.423L46.6112 150.857L47.1428 149.646L51.5812 151.594L52.8284 148.754L48.695 146.939L49.2247 145.733L53.358 147.548L54.6112 144.693L50.1183 142.721L50.6499 141.51L56.5495 144.1Z"
        fill="black"
      />
      <path
        d="M41.1303 147.716L41.2215 143.048L41.1545 143.005L36.9576 145.05L35.4577 144.091L40.8716 141.509L40.9229 135.5L42.4311 136.464L42.3777 141.091L42.4448 141.134L46.6206 139.141L48.1288 140.104L42.6521 142.647L42.6302 148.674L41.1303 147.716Z"
        fill="black"
      />
      <path
        d="M38.639 133.517L31.8997 141.149L29.1792 138.747C28.5854 138.223 28.189 137.68 27.9898 137.12C27.7906 136.559 27.7597 136.006 27.8972 135.459C28.0325 134.916 28.3063 134.41 28.7188 133.943C29.1334 133.474 29.6045 133.138 30.132 132.936C30.6549 132.734 31.2091 132.698 31.7947 132.826C32.3756 132.954 32.9617 133.279 33.553 133.801L35.4238 135.453L34.5616 136.43L32.7952 134.87C32.42 134.539 32.0587 134.334 31.711 134.257C31.3612 134.183 31.0318 134.21 30.7229 134.34C30.4139 134.469 30.1322 134.678 29.8778 134.967C29.6233 135.255 29.452 135.559 29.3638 135.879C29.2757 136.199 29.2937 136.527 29.4179 136.862C29.5395 137.195 29.7916 137.53 30.1742 137.868L31.6202 139.145L37.4875 132.5L38.639 133.517Z"
        fill="black"
      />
      <path
        d="M31.3528 126.526L23.5871 133.111L22.5936 131.939L29.3507 126.209L26.3668 122.69L27.3754 121.835L31.3528 126.526Z"
        fill="black"
      />
      <path
        d="M16.6456 114.348C17.5733 113.781 18.4745 113.464 19.3494 113.395C20.2214 113.329 21.0175 113.481 21.7379 113.853C22.4537 114.223 23.0406 114.783 23.4986 115.533C23.9583 116.285 24.1905 117.066 24.1953 117.874C24.1956 118.682 23.9657 119.46 23.5056 120.207C23.0456 120.954 22.3531 121.61 21.4283 122.175C20.5007 122.742 19.6008 123.059 18.7288 123.126C17.854 123.194 17.0587 123.043 16.3429 122.673C15.6225 122.301 15.0325 121.739 14.5728 120.987C14.1148 120.237 13.8857 119.459 13.8854 118.651C13.8806 117.842 14.1082 117.064 14.5683 116.317C15.0255 115.571 15.718 114.915 16.6456 114.348ZM17.4389 115.646C16.7318 116.078 16.2065 116.557 15.8628 117.081C15.5146 117.605 15.3371 118.141 15.3301 118.689C15.3186 119.236 15.465 119.758 15.7691 120.256C16.075 120.756 16.4739 121.126 16.9658 121.366C17.4549 121.607 18.0127 121.693 18.6391 121.625C19.261 121.556 19.9254 121.305 20.6325 120.873C21.3395 120.441 21.8672 119.963 22.2154 119.44C22.559 118.915 22.7366 118.379 22.7481 117.832C22.7567 117.287 22.6081 116.764 22.3022 116.264C21.9981 115.766 21.6015 115.396 21.1124 115.155C20.6187 114.913 20.061 114.827 19.4391 114.896C18.8126 114.964 18.1459 115.214 17.4389 115.646Z"
        fill="black"
      />
      <path
        d="M19.9894 109.157L10.5454 112.963L9.18903 109.596C8.89423 108.865 8.77565 108.207 8.83326 107.622C8.88965 107.035 9.09241 106.532 9.44156 106.112C9.78764 105.694 10.2512 105.368 10.8322 105.134C11.4102 104.901 11.9679 104.818 12.5054 104.883C13.0385 104.947 13.5244 105.173 13.963 105.561C14.4003 105.946 14.7664 106.504 15.0612 107.236L16.0886 109.786L14.862 110.28L13.8866 107.859C13.7008 107.398 13.4835 107.049 13.2349 106.814C12.9851 106.575 12.7068 106.439 12.4002 106.405C12.0937 106.371 11.7636 106.426 11.4101 106.568C11.0534 106.712 10.7723 106.905 10.5668 107.149C10.36 107.39 10.2462 107.684 10.2256 108.032C10.2006 108.378 10.2828 108.786 10.4724 109.256L11.1933 111.045L19.4153 107.732L19.9894 109.157ZM13.8501 106.212L17.1709 102.162L17.8249 103.785L14.4855 107.789L13.8501 106.212Z"
        fill="black"
      />
      <path
        d="M16.1656 98.1546L16.5261 99.7449L5.7862 98.4219L5.39391 96.691L14.5141 90.8671L14.8745 92.4574L7.3844 97.1065L7.40199 97.1841L16.1656 98.1546ZM12.2166 98.7692L11.0474 93.6103L12.3081 93.3245L13.4772 98.4835L12.2166 98.7692Z"
        fill="black"
      />
      <path
        d="M5.57625 89.4361L4.25641 89.519L3.76252 81.6545L5.08236 81.5716L5.28148 84.7422L14.1234 84.1869L14.2194 85.7152L5.37745 86.2704L5.57625 89.4361Z"
        fill="black"
      />
      <path
        d="M4.13977 75.2398L14.2998 75.9052L14.1994 77.4381L4.03937 76.7727L4.13977 75.2398Z"
        fill="black"
      />
      <path
        d="M11.5494 61.4884C12.6151 61.7031 13.492 62.0827 14.1802 62.6271C14.8652 63.1709 15.3456 63.8239 15.6214 64.5861C15.8946 65.3444 15.9444 66.1541 15.7709 67.0151C15.5967 67.8793 15.2365 68.6097 14.6903 69.2063C14.1415 69.7989 13.4444 70.2129 12.599 70.4483C11.7537 70.6837 10.7998 70.6943 9.73733 70.4802C8.67164 70.2654 7.79631 69.8862 7.11134 69.3424C6.42313 68.798 5.94243 68.1467 5.66924 67.3883C5.39345 66.6261 5.34264 65.8129 5.5168 64.9486C5.69032 64.0876 6.05148 63.3608 6.6003 62.7682C7.14653 62.1716 7.84232 61.7556 8.68768 61.5203C9.52978 61.2843 10.4837 61.2736 11.5494 61.4884ZM11.2488 62.9797C10.4366 62.816 9.72613 62.8098 9.1175 62.961C8.50628 63.1083 8.01187 63.3806 7.63429 63.7778C7.25411 64.1712 7.0064 64.6538 6.89116 65.2256C6.77527 65.8007 6.8164 66.3432 7.01456 66.8531C7.20947 67.3623 7.55982 67.8048 8.06563 68.1806C8.56884 68.5525 9.22658 68.8203 10.0388 68.984C10.8511 69.1477 11.5629 69.1559 12.1741 69.0086C12.7827 68.8574 13.2771 68.5851 13.6573 68.1917C14.0342 67.7977 14.2806 67.3132 14.3965 66.7381C14.5118 66.1663 14.4719 65.6257 14.277 65.1165C14.0795 64.6033 13.7292 64.1608 13.226 63.7889C12.7202 63.4131 12.0611 63.1434 11.2488 62.9797Z"
        fill="black"
      />
      <path
        d="M10.9845 48.6315L20.4785 52.3104L19.9683 53.6269L11.1355 55.7546L11.1014 55.8427L18.0642 58.5408L17.5091 59.9732L8.01519 56.2943L8.52894 54.9685L17.3728 52.8398L17.407 52.7517L10.4349 50.05L10.9845 48.6315Z"
        fill="black"
      />
      <path
        d="M18.4876 40.7871C18.0558 40.5927 17.6306 40.5949 17.2121 40.7938C16.7908 40.991 16.4314 41.3405 16.1339 41.8422C15.921 42.2015 15.7937 42.546 15.7523 42.8759C15.708 43.2041 15.7411 43.4993 15.8516 43.7613C15.9609 44.0188 16.1396 44.2211 16.3877 44.3681C16.5958 44.4915 16.8041 44.5495 17.0127 44.5422C17.223 44.532 17.4267 44.4775 17.624 44.3787C17.8202 44.2753 18.0074 44.1513 18.1857 44.0065C18.3611 43.8601 18.5209 43.714 18.6651 43.5683L19.3946 42.8449C19.6286 42.606 19.8912 42.3744 20.1822 42.1501C20.4732 41.9258 20.7885 41.7448 21.1283 41.6071C21.4681 41.4695 21.8247 41.4074 22.1982 41.4207C22.5734 41.4313 22.9578 41.5532 23.3512 41.7864C23.8473 42.0806 24.2117 42.47 24.4444 42.9548C24.6789 43.4367 24.7636 43.9859 24.6986 44.6024C24.6354 45.2161 24.4009 45.865 23.9952 46.5493C23.6064 47.205 23.166 47.7107 22.6739 48.0662C22.1818 48.4218 21.6645 48.6218 21.1219 48.6661C20.5764 48.7088 20.0315 48.588 19.4872 48.3038L20.2732 46.9781C20.6048 47.14 20.9294 47.1938 21.2469 47.1392C21.5632 47.0802 21.8563 46.9399 22.1263 46.7185C22.3951 46.4926 22.6301 46.21 22.8312 45.8707C23.0526 45.4972 23.1911 45.1305 23.2466 44.7704C23.301 44.4057 23.2726 44.0767 23.1613 43.7835C23.0472 43.4885 22.8476 43.2565 22.5625 43.0874C22.3031 42.9336 22.0467 42.8818 21.7935 42.932C21.5419 42.9794 21.2876 43.0964 21.0305 43.2831C20.7751 43.4669 20.5131 43.6911 20.2445 43.9556L19.3487 44.8232C18.7418 45.4113 18.1314 45.8008 17.5174 45.9916C16.905 46.1795 16.2938 46.0926 15.6837 45.7309C15.1791 45.4317 14.8197 45.0337 14.6057 44.5369C14.3916 44.0401 14.3191 43.4962 14.3882 42.9053C14.4545 42.3126 14.6617 41.7226 15.0099 41.1353C15.3615 40.5423 15.7757 40.0809 16.2526 39.751C16.7311 39.4182 17.2306 39.2288 17.7511 39.1829C18.2687 39.1353 18.766 39.2452 19.2432 39.5127L18.4876 40.7871Z"
        fill="black"
      />
      <path
        d="M34.4942 33.0884L32.1372 35.3926L25.0197 28.1117L27.4514 25.7346C28.1648 25.0372 28.92 24.584 29.7169 24.3751C30.5115 24.1638 31.3103 24.1963 32.1135 24.4726C32.9166 24.7442 33.6878 25.2581 34.4269 26.0141C35.1683 26.7726 35.6668 27.5599 35.9223 28.3761C36.1803 29.1899 36.1922 29.9987 35.9581 30.8023C35.7217 31.6035 35.2337 32.3656 34.4942 33.0884ZM32.2974 33.3588L33.4955 32.1876C34.0501 31.6455 34.4091 31.0906 34.5726 30.5229C34.7337 29.9528 34.7109 29.3795 34.5042 28.8029C34.2951 28.224 33.9091 27.6466 33.3461 27.0706C32.7877 26.4994 32.2227 26.104 31.651 25.8842C31.0816 25.6621 30.5172 25.6229 29.9577 25.7665C29.3983 25.9102 28.8531 26.2415 28.3222 26.7605L27.0566 27.9977L32.2974 33.3588Z"
        fill="black"
      />
      <path
        d="M45.0791 18.4613C45.6959 19.3565 46.0623 20.239 46.1785 21.1088C46.2929 21.9759 46.1842 22.7792 45.8526 23.5188C45.5217 24.2538 44.9947 24.8705 44.2714 25.3687C43.5454 25.8689 42.7787 26.1435 41.9713 26.1925C41.1648 26.237 40.3759 26.05 39.6046 25.6315C38.8333 25.2129 38.1403 24.5574 37.5254 23.6649C36.9087 22.7696 36.5432 21.8885 36.4288 21.0214C36.3126 20.1517 36.4199 19.3493 36.7507 18.6143C37.0824 17.8747 37.6112 17.2548 38.3373 16.7546C39.0606 16.2564 39.8255 15.985 40.632 15.9405C41.4394 15.8915 42.2287 16.0762 43 16.4947C43.7694 16.9105 44.4624 17.566 45.0791 18.4613ZM43.8263 19.3243C43.3563 18.6419 42.8499 18.1436 42.3073 17.8291C41.7656 17.5101 41.2209 17.3621 40.6733 17.3851C40.1266 17.4036 39.613 17.5782 39.1326 17.9092C38.6495 18.242 38.3019 18.6605 38.0899 19.1648C37.876 19.6663 37.8202 20.228 37.9225 20.8498C38.0256 21.4669 38.3123 22.1167 38.7823 22.799C39.2524 23.4814 39.7583 23.9821 40.3001 24.3011C40.8427 24.6156 41.3874 24.7636 41.9341 24.7451C42.4789 24.724 42.9929 24.547 43.476 24.2142C43.9564 23.8832 44.3036 23.467 44.5175 22.9655C44.7323 22.4593 44.7881 21.8976 44.6849 21.2805C44.5826 20.6587 44.2964 20.0067 43.8263 19.3243Z"
        fill="black"
      />
      <path
        d="M53.6086 8.82571L57.8753 18.0704L56.5933 18.6621L48.765 14.0508L48.6793 14.0904L51.8085 20.8704L50.4136 21.5142L46.1469 12.2695L47.4379 11.6737L55.2749 16.2919L55.3607 16.2523L52.2273 9.46322L53.6086 8.82571Z"
        fill="black"
      />
      <path
        d="M61.1077 17.1157L58.3697 7.30888L64.5182 5.59234L64.8738 6.86608L60.205 8.16953L61.0392 11.1575L65.3871 9.94366L65.7414 11.2126L61.3935 12.4265L62.2317 15.4289L66.9579 14.1094L67.3135 15.3831L61.1077 17.1157Z"
        fill="black"
      />
      <path
        d="M79.2177 14L76.3938 3.81818L78.0096 3.81818L79.9933 11.7031H80.0877L82.1509 3.81818L83.7518 3.81818L85.815 11.7081H85.9094L87.8881 3.81818L89.5089 3.81818L86.68 14L85.1339 14L82.9911 6.37358H82.9116L80.7688 14L79.2177 14Z"
        fill="black"
      />
      <path
        d="M94.1582 4.84893L92.642 14.9172L91.1229 14.6885L92.6391 4.62017L94.1582 4.84893Z"
        fill="black"
      />
      <path
        d="M99.2935 7.17283L99.6559 5.90101L107.234 8.06044L106.872 9.33225L103.817 8.46167L101.389 16.9819L99.9162 16.5623L102.344 8.04205L99.2935 7.17283Z"
        fill="black"
      />
      <path
        d="M107.587 19.1711L111.952 9.97251L113.34 10.6311L111.443 14.6286L116.029 16.8048L117.925 12.8073L119.318 13.4681L114.953 22.6667L113.56 22.0059L115.464 17.995L110.878 15.8188L108.974 19.8298L107.587 19.1711Z"
        fill="black"
      />
      <path
        d="M135.951 25.8904L129.314 33.612L128.244 32.6916L129.186 23.6552L129.115 23.5936L124.247 29.2566L123.082 28.2552L129.719 20.5337L130.797 21.4605L129.852 30.5077L129.924 30.5693L134.797 24.8988L135.951 25.8904Z"
        fill="black"
      />
      <path
        d="M141.068 39.6779C140.23 40.3699 139.382 40.8115 138.526 41.0026C137.672 41.1916 136.862 41.153 136.097 40.8866C135.336 40.6208 134.676 40.1491 134.117 39.4717C133.556 38.7917 133.216 38.0517 133.097 37.2516C132.983 36.452 133.1 35.6499 133.451 34.8452C133.801 34.0406 134.394 33.2934 135.23 32.6035C136.068 31.9115 136.914 31.471 137.768 31.282C138.625 31.0909 139.433 31.1282 140.194 31.3941C140.96 31.6604 141.623 32.1336 142.184 32.8135C142.743 33.491 143.08 34.2295 143.194 35.0291C143.313 35.8292 143.197 36.6315 142.847 37.4362C142.5 38.2387 141.907 38.986 141.068 39.6779ZM140.1 38.5046C140.739 37.9772 141.192 37.4296 141.458 36.8618C141.729 36.2944 141.829 35.7389 141.758 35.1954C141.693 34.6523 141.474 34.1558 141.103 33.7059C140.73 33.2535 140.282 32.9435 139.762 32.7759C139.243 32.6063 138.679 32.5993 138.069 32.7551C137.463 32.9114 136.84 33.2532 136.201 33.7807C135.562 34.3081 135.107 34.8555 134.836 35.4228C134.57 35.9906 134.47 36.5461 134.535 37.0892C134.604 37.6301 134.825 38.1269 135.198 38.5793C135.569 39.0292 136.014 39.339 136.532 39.5087C137.055 39.6788 137.619 39.6857 138.225 39.5295C138.836 39.3736 139.461 39.032 140.1 38.5046Z"
        fill="black"
      />
      <path
        d="M151.438 55.783L150.801 54.3687C151.079 54.1782 151.3 53.9572 151.463 53.7057C151.626 53.4542 151.739 53.1851 151.802 52.8984C151.866 52.6117 151.879 52.317 151.841 52.0143C151.804 51.7147 151.719 51.4167 151.585 51.1206C151.344 50.5856 150.994 50.1672 150.535 49.8652C150.077 49.5662 149.533 49.4113 148.903 49.4006C148.274 49.3929 147.585 49.5577 146.835 49.8952C146.08 50.2354 145.497 50.6434 145.086 51.1192C144.676 51.5981 144.433 52.1089 144.358 52.6518C144.283 53.1946 144.364 53.7305 144.602 54.2594C144.734 54.5525 144.899 54.8129 145.096 55.0404C145.297 55.2696 145.524 55.4561 145.778 55.6C146.032 55.7438 146.305 55.8387 146.599 55.8847C146.897 55.9324 147.206 55.9183 147.528 55.8424L148.161 57.2588C147.67 57.3887 147.184 57.4313 146.703 57.3865C146.225 57.3434 145.771 57.2152 145.341 57.0019C144.915 56.7902 144.529 56.5023 144.183 56.1382C143.837 55.7741 143.55 55.3366 143.32 54.8259C142.958 54.022 142.826 53.22 142.923 52.4199C143.024 51.6185 143.349 50.8759 143.899 50.1921C144.451 49.5113 145.22 48.9485 146.209 48.5035C147.2 48.0572 148.132 47.8554 149.006 47.8983C149.882 47.9398 150.653 48.1892 151.316 48.6464C151.983 49.1021 152.497 49.7305 152.858 50.5314C153.079 51.024 153.215 51.5153 153.265 52.0054C153.319 52.4972 153.287 52.975 153.169 53.439C153.054 53.9016 152.852 54.3325 152.562 54.7317C152.276 55.1295 151.902 55.4799 151.438 55.783Z"
        fill="black"
      />
      <path
        d="M153.152 68.864C152.1 69.1353 151.145 69.176 150.288 68.986C149.435 68.7953 148.718 68.417 148.137 67.8513C147.561 67.288 147.163 66.581 146.944 65.7305C146.724 64.8768 146.729 64.0624 146.961 63.2874C147.196 62.5148 147.643 61.8383 148.302 61.2579C148.96 60.6775 149.814 60.2521 150.863 59.9816C151.916 59.7103 152.869 59.67 153.722 59.8608C154.579 60.0507 155.296 60.4273 155.872 60.9907C156.453 61.5564 156.853 62.2662 157.073 63.1199C157.292 63.9704 157.284 64.782 157.048 65.5546C156.817 66.3296 156.372 67.0073 155.714 67.5877C155.059 68.1672 154.205 68.5926 153.152 68.864ZM152.773 67.3908C153.575 67.184 154.217 66.8799 154.699 66.4785C155.185 66.0794 155.511 65.6188 155.678 65.0967C155.848 64.577 155.861 64.0347 155.715 63.4698C155.569 62.9017 155.295 62.4314 154.895 62.0589C154.497 61.6856 153.989 61.4401 153.37 61.3224C152.755 61.2071 152.046 61.2528 151.244 61.4596C150.442 61.6664 149.798 61.9693 149.312 62.3684C148.83 62.7698 148.504 63.2304 148.333 63.7501C148.165 64.269 148.155 64.8125 148.301 65.3806C148.447 65.9455 148.718 66.4146 149.116 66.7879C149.517 67.1636 150.026 67.4091 150.641 67.5244C151.26 67.6421 151.97 67.5976 152.773 67.3908Z"
        fill="black"
      />
      <path
        d="M159.262 80.1878L149.114 81.0158L148.999 79.6086L156.021 73.843L156.013 73.7488L148.571 74.3561L148.446 72.825L158.594 71.997L158.71 73.4141L151.678 79.1855L151.686 79.2796L159.139 78.6715L159.262 80.1878Z"
        fill="black"
      />
      <path
        d="M155.931 90.5186C156.404 90.5053 156.786 90.3179 157.076 89.9565C157.369 89.5954 157.54 89.1242 157.589 88.5429C157.624 88.1268 157.588 87.7612 157.482 87.4463C157.379 87.1316 157.22 86.8804 157.006 86.6927C156.796 86.5087 156.547 86.4045 156.259 86.3803C156.018 86.36 155.806 86.3986 155.621 86.4962C155.436 86.597 155.277 86.7349 155.142 86.9098C155.011 87.0883 154.896 87.2816 154.799 87.4896C154.705 87.6979 154.625 87.899 154.559 88.093L154.217 89.0621C154.111 89.3791 153.976 89.7019 153.812 90.0307C153.647 90.3595 153.443 90.6598 153.197 90.9318C152.951 91.2038 152.657 91.4152 152.315 91.566C151.973 91.7201 151.574 91.7779 151.118 91.7395C150.543 91.6911 150.046 91.4995 149.625 91.1647C149.204 90.8332 148.888 90.3758 148.678 89.7927C148.467 89.2128 148.395 88.5265 148.462 87.7339C148.526 86.9743 148.702 86.3272 148.99 85.7927C149.278 85.2581 149.656 84.8526 150.125 84.5762C150.597 84.3 151.14 84.1711 151.754 84.1896L151.625 85.7253C151.256 85.7242 150.94 85.8173 150.678 86.0048C150.419 86.1959 150.217 86.4499 150.07 86.7668C149.927 87.0873 149.839 87.4441 149.805 87.8371C149.769 88.2698 149.804 88.6602 149.911 89.0085C150.021 89.3604 150.19 89.6441 150.418 89.8595C150.65 90.0752 150.93 90.197 151.261 90.2249C151.561 90.2502 151.814 90.185 152.02 90.0294C152.226 89.8772 152.404 89.661 152.554 89.3809C152.704 89.1042 152.842 88.7882 152.968 88.4329L153.396 87.2616C153.686 86.4678 154.066 85.8512 154.535 85.4118C155.004 84.9757 155.592 84.7874 156.299 84.847C156.883 84.8962 157.38 85.0978 157.79 85.4516C158.199 85.8054 158.501 86.2632 158.697 86.8252C158.895 87.3874 158.966 88.0087 158.909 88.6891C158.851 89.376 158.679 89.9719 158.394 90.4767C158.108 90.9848 157.741 91.373 157.293 91.6412C156.848 91.9097 156.352 92.0276 155.806 91.9949L155.931 90.5186Z"
        fill="black"
      />
      <path
        d="M156.304 96.7219L157.587 97.0417L155.682 104.688L154.399 104.368L155.167 101.285L146.571 99.1433L146.941 97.6575L155.537 99.7996L156.304 96.7219Z"
        fill="black"
      />
      <path
        d="M144.564 105.43L153.925 109.436L152.497 112.773C152.186 113.498 151.804 114.046 151.349 114.418C150.893 114.792 150.394 115.004 149.85 115.052C149.31 115.102 148.751 115.004 148.176 114.757C147.603 114.512 147.15 114.176 146.817 113.749C146.486 113.326 146.303 112.822 146.269 112.238C146.233 111.657 146.37 111.003 146.68 110.278L147.762 107.751L148.978 108.271L147.951 110.67C147.755 111.127 147.662 111.527 147.67 111.87C147.677 112.215 147.777 112.509 147.97 112.75C148.162 112.991 148.434 113.186 148.784 113.336C149.137 113.487 149.473 113.55 149.791 113.523C150.107 113.5 150.396 113.373 150.657 113.142C150.919 112.916 151.151 112.57 151.35 112.103L152.109 110.33L143.96 106.843L144.564 105.43ZM146.81 111.858L141.597 112.364L142.285 110.755L147.479 110.295L146.81 111.858Z"
        fill="black"
      />
      <path
        d="M139.469 115.906L140.341 114.528L146.983 123.071L146.034 124.571L135.471 122.219L136.344 120.841L144.923 122.867L144.966 122.8L139.469 115.906ZM142.691 118.27L139.861 122.739L138.769 122.048L141.599 117.579L142.691 118.27Z"
        fill="black"
      />
      <path
        d="M140.515 131.832L132.679 125.33L133.66 124.148L141.496 130.65L140.515 131.832Z"
        fill="black"
      />
      <path
        d="M130.352 141.935L123.451 134.448L124.489 133.491L133.356 135.474L133.425 135.41L128.364 129.92L129.494 128.878L136.394 136.365L135.349 137.329L126.472 135.342L126.403 135.406L131.47 140.904L130.352 141.935Z"
        fill="black"
      />
      <path
        d="M125.967 143.486L126.691 144.593L120.096 148.906L119.372 147.8L122.031 146.061L117.182 138.646L118.463 137.808L123.313 145.223L125.967 143.486Z"
        fill="black"
      />
      <path
        d="M109.424 151.012C109.664 151.42 110.012 151.664 110.468 151.744C110.926 151.827 111.421 151.749 111.954 151.512C112.335 151.342 112.639 151.134 112.863 150.889C113.089 150.647 113.233 150.387 113.294 150.11C113.354 149.836 113.325 149.568 113.208 149.304C113.109 149.083 112.973 148.916 112.798 148.801C112.621 148.688 112.423 148.614 112.205 148.581C111.985 148.552 111.761 148.545 111.532 148.56C111.304 148.578 111.089 148.605 110.887 148.64L109.874 148.809C109.544 148.868 109.196 148.905 108.829 148.92C108.462 148.935 108.1 148.9 107.743 148.816C107.387 148.732 107.06 148.576 106.763 148.349C106.463 148.124 106.22 147.802 106.033 147.384C105.799 146.857 105.727 146.329 105.817 145.799C105.904 145.27 106.153 144.773 106.562 144.308C106.969 143.844 107.535 143.45 108.262 143.126C108.958 142.816 109.61 142.658 110.217 142.652C110.824 142.647 111.362 142.783 111.83 143.06C112.3 143.341 112.675 143.755 112.954 144.301L111.547 144.928C111.37 144.604 111.136 144.373 110.845 144.234C110.553 144.099 110.233 144.044 109.885 144.069C109.535 144.097 109.18 144.192 108.819 144.353C108.423 144.529 108.098 144.749 107.844 145.01C107.589 145.276 107.422 145.561 107.343 145.865C107.266 146.171 107.294 146.476 107.429 146.779C107.552 147.054 107.731 147.245 107.967 147.35C108.199 147.457 108.474 147.509 108.792 147.505C109.107 147.503 109.45 147.471 109.822 147.411L111.055 147.221C111.89 147.092 112.613 147.127 113.225 147.327C113.833 147.527 114.281 147.952 114.57 148.6C114.809 149.136 114.872 149.668 114.759 150.197C114.646 150.726 114.391 151.212 113.993 151.654C113.596 152.099 113.086 152.461 112.462 152.739C111.833 153.019 111.228 153.156 110.648 153.15C110.065 153.144 109.548 153.01 109.097 152.747C108.647 152.486 108.305 152.109 108.07 151.615L109.424 151.012Z"
        fill="black"
      />
    </svg>
  );
};

export default RoundText;
