import React from "react";

const SqaureIcon = () => {
  return (
    <svg
      width="30"
      height="28"
      viewBox="0 0 30 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect opacity="0.8" width="30" height="28" fill="#FFF844" />
    </svg>
  );
};

export default SqaureIcon;
