import React from "react";

const LBulbIcon = () => {
  return (
    <svg
      width="61"
      height="61"
      viewBox="0 0 61 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.79048 29.8225C7.53181 29.8225 8.13333 30.4241 8.13333 31.1781C8.13333 31.9267 7.52213 32.5336 6.79048 32.5336H1.34285C0.601526 32.5336 0 31.9321 0 31.1781C0 30.4295 0.611208 29.8225 1.34285 29.8225H6.79048ZM59.6572 29.8225C60.3985 29.8225 61 30.4241 61 31.1781C61 31.9267 60.3888 32.5336 59.6572 32.5336H54.2095C53.4682 32.5336 52.8667 31.9321 52.8667 31.1781C52.8667 30.4295 53.4779 29.8225 54.2095 29.8225H59.6572ZM32.5336 6.79048C32.5336 7.53181 31.9321 8.13333 31.1781 8.13333C30.4295 8.13333 29.8225 7.52213 29.8225 6.79048V1.34285C29.8225 0.601526 30.4241 0 31.1781 0C31.9267 0 32.5336 0.611208 32.5336 1.34285V6.79048ZM13.7697 13.207C14.2943 13.7317 14.2943 14.5825 13.7612 15.1156C13.2317 15.6445 12.3699 15.6415 11.8526 15.1241L8.00139 11.2723C7.47672 10.7482 7.47672 9.89737 8.00986 9.36429C8.53938 8.83477 9.40052 8.83841 9.91786 9.35581L13.7697 13.207ZM50.5044 15.1241C49.9798 15.6488 49.1289 15.6488 48.5958 15.1157C48.0669 14.5861 48.0699 13.7244 48.5873 13.207L52.4391 9.35586C52.9632 8.83119 53.8141 8.83119 54.3471 9.36433C54.8767 9.89385 54.873 10.755 54.3556 11.2723L50.5044 15.1241Z"
        fill="black"
      />
      <path
        d="M35.2436 43.9234C40.7718 41.9694 44.7325 36.6972 44.7325 30.4999C44.7325 22.6389 38.3601 16.2665 30.4991 16.2665C22.6381 16.2665 16.2658 22.6389 16.2658 30.4999C16.2658 36.6973 20.2265 41.9694 25.7547 43.9234V58.2888H35.2436V43.9234ZM13.5547 31.1776C13.5547 21.1417 21.141 13.5554 31.1769 13.5554C39.8573 13.5554 47.4436 21.1417 47.4436 31.1776C47.4436 37.1275 43.6026 43.0424 37.9547 46.0888V58.2888C37.9547 59.7714 36.6475 60.9999 35.2792 60.9999H25.7547C24.522 60.9999 23.0436 59.6885 23.0436 58.2888V46.0888C17.3956 43.0424 13.5547 37.1275 13.5547 31.1776ZM25.7547 47.4443H35.2436V50.1554H25.7547V47.4443ZM25.7547 52.8665H35.2436V55.5776H25.7547V52.8665Z"
        fill="black"
      />
    </svg>
  );
};

export default LBulbIcon;
