import { useEffect, useRef, useState } from "react";
import { ProjectWrapper, SectionOneWrapper } from "./styled";

import { Box, IconButton, Theme, Typography } from "@mui/material";

import { useLocation } from "react-router-dom";
import withAuth from "../../HOC/withAuth";
import Two from "../../Assets/images/2.jpg";
import NYC from "../../Assets/images/nyc_map.png";
import SubWay from "../../Assets/images/subway.csv";
import Video2 from "../../Assets/project3.mp4";
import Video3 from "../../Assets/project2.mp4";
// import * as p5 from "p5";
import { P5CanvasInstance, ReactP5Wrapper } from "@p5-wrapper/react";
import {
  SpeakerRounded,
  VolumeOffRounded,
  VolumeUpRounded,
} from "@mui/icons-material";
// (window as any).p5 = p5;

// await import("p5/lib/addons/p5.sound");

const Project5 = ({ playOff }: { playOff: boolean }) => {
  const { pathname } = useLocation();
  const video2Ref = useRef<any>();
  const video3Ref = useRef<any>();
  const [muted, setMuted] = useState(false);
  const [play, setPlay] = useState<any>({
    section1: true,
    section2: false,
    section3: false,
    section4: false,
    section5: false,
    section6: false,
    section7: false,
    section8: false,
    section9: false,
  });
  useEffect(() => {
    window.scrollTo(0, 0);
    const container = window.document.getElementById("container");
    if (container) {
      container.addEventListener("scroll", getScrollPosition);
    }
    return () => {
      container?.removeEventListener("scroll", getScrollPosition);
    };
  }, [pathname]);

  useEffect(() => {
    if (playOff) {
      setPlay({
        section1: false,
        section2: false,
        section3: false,
        section4: false,
        section5: false,
        section6: false,
        section7: false,
        section8: false,
        section9: false,
      });
    }
  }, [playOff]);

  useEffect(() => {
    if (play.section2) {
      video2Ref.current.play();
    } else {
      video2Ref.current.pause();
      video2Ref.current.currentTime = 0;
      video2Ref.current.load();
    }
    if (play.section3) {
      video3Ref.current.play();
    } else {
      video3Ref.current.pause();
      video3Ref.current.currentTime = 0;
      video3Ref.current.load();
    }
  }, [play]);

  const getScrollPosition = () => {
    const sections = document.getElementsByClassName("section");
    for (let i = 0; i < sections.length; i++) {
      if (i === 0) {
        continue;
      }
      if (
        Math.floor(sections[i].getBoundingClientRect().top) >= -1 &&
        Math.floor(sections[i].getBoundingClientRect().top) <= 64
      ) {
        const tempObject: any = {};
        Object.keys(play).map((k) => {
          if (k === "section" + i) {
            tempObject[k] = true;
          } else {
            tempObject[k] = false;
          }
        });
        setPlay(tempObject);
      }
    }
  };

  const sketch1 = (p5: P5CanvasInstance) => {
    p5.setup = () => {
      if (p5.windowWidth < 699) {
        p5.createCanvas(350, 350);
      } else {
        p5.createCanvas(650, 650);
      }
      p5.background(0);
      p5.frameRate(120);
    };
    p5.draw = () => {
      p5.translate(p5.width / 2, p5.height / 2);

      let t = p5.millis() * 0.001;

      let r = p5.map(p5.sin(p5.frameCount * 0.02), -1, 1, 0, 255); //Mapping                                         frameCount values to get color values
      let g = p5.map(p5.cos(p5.frameCount * 0.02), -1, 1, 0, 255);
      let b = p5.map(p5.sin(p5.frameCount * 0.05), -1, 1, 0, 255);
      p5.stroke(r, g, b);
      // noFill()
      p5.strokeWeight(2);

      p5.beginShape(); //Creating custom shape
      for (let i = 0; i < 100; i++) {
        //Generates 100 points
        let angle = p5.map(i, 0, 100, 0, p5.TWO_PI);
        let radius = 200 * p5.cos(150 * angle + t) + 50; //t is what causes the                             animation, since it keeps on incrementing
        let x = radius * p5.cos(angle); //using the ever-changing             values of radius and angle (thanks to the for loop) to create               points
        let y = radius * p5.sin(angle);
        p5.curveVertex(x, y); //used curveVertex instead of                               vertex because vertex made it look too sharp
      }
      p5.scale(3.3); //this is one of my fav things to use in p5 now. Amazing how it can completely change the way an animation looks.
      p5.endShape(p5.CLOSE);
    };
  };
  //   const sketch2 = (p5: P5CanvasInstance) => {
  //     let song: any;
  //     let button: any;

  //     p5.setup = () => {
  //       p5.createCanvas(600, 400, p5.WEBGL);
  //       p5.background(255, 0, 0);
  //       button = p5.createButton("Toggle audio");

  //       button.mousePressed(() => {
  //         if (!song) {
  //           const songPath = Before;
  //           song = p5.loadSound(
  //             songPath,
  //             () => {
  //               song.play();
  //             },
  //             () => {
  //               console.error(
  //                 `Could not load the requested sound file ${songPath}`
  //               );
  //             }
  //           );
  //           return;
  //         }

  //         if (!song.isPlaying()) {
  //           song.play();
  //           return;
  //         }

  //         song.pause();
  //       });
  //     };

  //     p5.draw = () => {
  //       p5.background(250);
  //       p5.normalMaterial();
  //       p5.push();
  //       p5.rotateZ(p5.frameCount * 0.01);
  //       p5.rotateX(p5.frameCount * 0.01);
  //       p5.rotateY(p5.frameCount * 0.01);
  //       p5.plane(100);
  //       p5.pop();
  //     };
  //   };
  //   const sketch2 = (p5: P5CanvasInstance) => {
  //     let audio: any;
  //     let fft: any;

  //     p5.preload = () => {
  //       console.log("p5======>", p5);
  //       audio = p5.loadSound(Before);
  //     };

  //     p5.setup = () => {
  //       if(p5.windowWidth<699){
  //     p5.createCanvas(650, 650);
  // }else{
  //     p5.createCanvas(650, 650);

  // }
  //       p5.angleMode(p5.DEGREES);
  //       // @ts-ignore

  //       fft = new (window as any).p5.FFT() as any;
  //       audio.loop();
  //     };

  //     p5.draw = () => {
  //       p5.background(0);
  //       let spectrum = fft.analyze();
  //       p5.noFill();

  //       p5.push();
  //       p5.translate(p5.width / 2, p5.height / 2);

  //       for (let i = 0; i < 10; i++) {
  //         //creates 10 shapes
  //         p5.beginShape();
  //         for (let j = 0; j < spectrum.length; j++) {
  //           //loops through amplitude values
  //           let amp1 = spectrum[j]; //returns amplitude for current freq.
  //           let col = p5.map(amp1, 0, 256, 0, 255); // map that amplitude to a color
  //           p5.stroke(col, 255, 255, 80);
  //           let r = p5.map(amp1, 0, 256, 40, 200); //mapping the same amp to a different range to get our polar coordinates
  //           let x = r * p5.cos(j);
  //           let y = r * p5.sin(j);
  //           p5.curveVertex(x, y); //using polar coordinates to form a shape by connecting all vertices
  //         }
  //         p5.endShape(p5.CLOSE);
  //         p5.rotate(36); //360 deg / 10 = each new shape rotates by an angle of 36 deg
  //       }

  //       p5.pop();
  //     };
  //   };
  //   const sketch3 = (p5: P5CanvasInstance) => {
  //     let foto: any;
  //     let table: any;
  //     let subwayLines: any = [];
  //     // let audio;

  //     let mapGeoLeft = -74.0198;
  //     let mapGeoRight = -73.9066;
  //     let mapGeoTop = 40.7533;
  //     let mapGeoBottom = 40.6675;

  //     p5.preload = () => {
  //       foto = p5.loadImage(NYC);
  //       table = p5.loadTable(SubWay, "csv", "header");
  //       // audio = loadSound('puce_mary.mp3');
  //     };

  //     p5.setup = () => {
  //       // audio.loop()
  //       if(p5.windowWidth<699){
  //     p5.createCanvas(650, 650);
  // }else{
  //     p5.createCanvas(650, 650);

  // }
  //       foto.resize(p5.width, p5.height);

  //       for (let r = 0; r < table.getRowCount(); r++) {
  //         let coords = []; //coords array should reset for every row of the csv file to create different paths for different rows
  //         let row = table.getRow(r);
  //         let lineString = row.get("the_geom"); //parses through each row of the column named the_geom
  //         let pairs = lineString
  //           .replace("LINESTRING (", "")
  //           .replace(")", "")
  //           .split(", "); //removing things I don't need from the csv file, and splitting string into pairs

  //         for (let i = 0; i < pairs.length; i++) {
  //           //iterating through the 'pairs' variable to split the lon lat string into a lat lon numerical pair using .map(Number)
  //           const [lon, lat] = pairs[i].split(" ").map(Number);
  //           coords.push([lat, lon]); //pushing the extracted lat lon pair into the coords array
  //         }

  //         subwayLines.push(coords); //pushing coordinates of all lat lon pairs from a row into the subwayLines array to eventually get coordinates from all the rows at one place thanks to the for loop
  //       }
  //     };

  //     p5.draw = () => {
  //       p5.background(foto);
  //       p5.fill(0, 0, 0, 140); //added a transparent black layer over the map image for dramatic effects
  //       p5.rect(0, 0, p5.width, p5.height);
  //       p5.strokeWeight(3);

  //       for (let line of subwayLines) {
  //         p5.beginShape();
  //         for (let coord of line) {
  //           //extracting coordinates again to use in the vertex
  //           let x = p5.map(coord[1], mapGeoLeft, mapGeoRight, 0, p5.width);
  //           let y = p5.map(coord[0], mapGeoTop, mapGeoBottom, 0, p5.height);
  //           p5.vertex(x, y);
  //         }
  //         p5.stroke(p5.random(255), p5.random(255), p5.random(255)); //for the disco effect
  //         p5.noFill();
  //         p5.endShape();
  //       }
  //       // noLoop()
  //     };
  //   };
  const sketch4 = (p5: P5CanvasInstance) => {
    let foto: any;
    let flashing = false;
    let flashInterval = 4; // Number of frames between color changes
    p5.preload = () => {
      foto = p5.loadImage(Two);
    };

    p5.setup = () => {
      if (p5.windowWidth < 699) {
        p5.createCanvas(350, 350);
      } else {
        p5.createCanvas(650, 650);
      }
      p5.pixelDensity(1);
      foto.resize(p5.width, p5.height);
      p5.background(0);
    };

    p5.draw = () => {
      foto.loadPixels();
      for (let y = 0; y < p5.height; y += 5) {
        for (let x = 0; x < p5.width; x += 5) {
          let diameter = p5.map(p5.brightness(foto.get(x, y)), 0, 255, 1, 10); //brightness of the pixel determines the size of the circle
          if (p5.frameCount % flashInterval == 0) {
            flashing = !flashing; // toggle flashing on/off every flashInterval frames
          }
          if (flashing) {
            p5.fill(255, 0, 0); // Red color when flashing
          } else {
            p5.fill(0, 0, 255); // Blue color when not flashing
          }
          p5.noStroke();
          p5.circle(x, y, diameter);
        }
      }
    };
  };
  const sketch5 = (p5: P5CanvasInstance) => {
    p5.setup = () => {
      if (p5.windowWidth < 699) {
        p5.createCanvas(350, 350);
      } else {
        p5.createCanvas(650, 650);
      }
      p5.background(0);
    };

    let angleEarth = 0;
    let angleVenus = 0;

    p5.draw = () => {
      p5.translate(p5.width / 2, p5.height / 2);

      //Earth
      let x = p5.cos(angleEarth) * 149 * 2; //multiplied the coordinates with the distance of Earth from Sun i.e. 149 [million sq km] it looked too small so multiplied by 2
      let y = p5.sin(angleEarth) * 149 * 2;
      angleEarth = (2 * p5.frameCount * p5.TWO_PI) / 210; //used frameCount to increment the angleEarth value since frameCount keeps incrementing on its own. TWO_PI/210 signifies the number of frames it takes Earth to complete one TWO_PI orbit

      //Venus
      let x1 = p5.cos(angleVenus) * 108 * 2; //multiplied the coordinates with the distance of Venus from Sun i.e. 108 [million sq km] it looked too small so multiplied by 2
      let y1 = p5.sin(angleVenus) * 108 * 2;
      angleVenus = (13 / 8) * angleEarth; //since Venus orbits the Sun 13 times for every 8 Earth orbits

      p5.stroke(0, 150, 255, 80);
      p5.line(x, y, x1, y1);

      if (p5.frameCount > 850) {
        //I used print(frameCount) to determine at which frame the animation finishes generating the orbit.
        p5.noLoop();
      }
      // print(frameCount)
    };
  };

  const sketch6 = (p5: P5CanvasInstance) => {
    let t = 0;

    p5.setup = () => {
      if (p5.windowWidth < 699) {
        p5.createCanvas(350, 350);
      } else {
        p5.createCanvas(650, 650);
      }
    };

    p5.draw = () => {
      p5.background(255);

      for (let x = 0; x < 12; x++) {
        //looping each circle-like                                                 shape on the x-axis
        for (let y = 0; y < 12; y++) {
          //looping each circle-like                                                 shape on the y-axis

          p5.push();
          p5.translate(50 + x * 50, 80 + y * 50); //just like the Sol LeWitt                                             artwork solution, translating each                                         new circle-like shape based on its                                         x and y values so 2 shapes don't                                           overlap
          p5.rotate(p5.mouseX * 0.01); //just for fun

          for (let z = 0; z < 50; z++) {
            //creates 50 lines that come                                               together to look like a circle
            let x1 = p5.cos((p5.TWO_PI / 70) * z + t) * 20; //maps the start and end x                                               y co-ordinates for each line
            let y1 = p5.sin((p5.TWO_PI / 70) * z + t) * 20; //changing the '70' to any                                           other number gives interesting                                               results
            let x2 = p5.cos((p5.TWO_PI / 70) * z + t + p5.PI) * 20; //the 't' is what                                       rotates the lines, p5.since it increments
            let y2 = p5.sin((p5.TWO_PI / 70) * z + t + p5.PI) * 20;

            let gradientColor = p5.lerpColor(
              p5.color(0, 150, 255, 50),
              p5.color(255, 0, 150, 150),
              t / 40
            ); //color smoothly transitions from the                                       first to the second
            p5.stroke(gradientColor);

            p5.line(x1, y1, x2, y2);
            // ellipse(x1,y1,x2,y2)        //using ellipse instead of line                                           gives interesting results
          }

          p5.pop();
        }
      }
      t += 0.03;
    };
  };

  const sketch7 = (p5: P5CanvasInstance) => {
    let initialRadius = 10; //Initial radii of the circles

    p5.setup = () => {
      if (p5.windowWidth < 699) {
        p5.createCanvas(350, 350);
      } else {
        p5.createCanvas(650, 650);
      }
      p5.background(255);
      p5.noFill();
      p5.strokeWeight(2);
    };

    p5.draw = () => {
      p5.translate(p5.width / 2, p5.height / 2); //Bringing origin to the center

      let r = p5.map(p5.sin(p5.frameCount * 0.02), -1, 1, 0, 255); //mapping framecount                                         gives us the beautiful dynamic                                             colors here
      let g = p5.map(p5.cos(p5.frameCount * 0.02), -1, 1, 0, 255);
      let b = p5.map(p5.sin(p5.frameCount * 0.06), -1, 1, 0, 255);

      p5.stroke(r, g, b);

      for (let i = 0; i < 10; i++) {
        //Loop gives incremental i value to the animate variable which in turn affects translate and creates a dynamic animation
        let animate = 150 * p5.cos((p5.PI / 10) * i);

        p5.push();
        p5.translate(
          animate * p5.cos(p5.frameCount * 0.01),
          animate * p5.sin(p5.frameCount * 0.01)
        );
        p5.circle(0, 0, initialRadius);
        p5.pop();
      }

      initialRadius += 2; //incrementing circle size to create the final                             pattern that we see
    };
  };

  const sketch8 = (p5: P5CanvasInstance) => {
    p5.setup = () => {
      if (p5.windowWidth < 699) {
        p5.createCanvas(350, 350);
      } else {
        p5.createCanvas(650, 650);
      }
      p5.frameRate(30);
      // background(0)
      // stroke(255)
    };

    let t = 0;

    p5.draw = () => {
      p5.background(0);
      // clear()              //Refreshes the canvas because otherwise the lines                           will cover up the entire canvas and we won't be                             seeing the animation
      for (let y = 0; y < p5.height; y += 0.75) {
        //Draws lines from top to bottom

        p5.translate(p5.frameCount * 5, p5.noise(t) * 0.00001); //Continuously moves the                                                     line system as per the                                                     frame count and specified                                                   noise

        let rotation = p5.map(p5.noise(p5.frameCount * 0.0001), 0, 1, 0, p5.PI); //each line                                                 rotates by a maximum of PI                                                 radians
        p5.rotate(rotation);

        p5.line(0, y, 300 * p5.width, y); //start and end coordinates of                                             lines

        p5.stroke(0, 0, 255, 150);

        p5.strokeWeight(p5.random(0.2, 0.8));

        t += 0.01;
      }
    };
  };
  const sketch9 = (p5: P5CanvasInstance) => {
    p5.setup = () => {
      if (p5.windowWidth < 699) {
        p5.createCanvas(350, 350);
      } else {
        p5.createCanvas(650, 650);
      }
      p5.background(0);
      p5.frameRate(120);
    };

    p5.draw = () => {
      p5.translate(p5.width / 2, p5.height / 2);

      let t = p5.millis() * 0.001;

      let r = p5.map(p5.sin(p5.frameCount * 0.02), -1, 1, 0, 255);
      let g = p5.map(p5.cos(p5.frameCount * 0.02), -1, 1, 0, 255);
      let b = p5.map(p5.sin(p5.frameCount * 0.05), -1, 1, 0, 255);
      p5.stroke(r, g, b);
      // noFill()
      p5.strokeWeight(2);

      p5.beginShape();
      for (let i = 0; i < 100; i++) {
        let angle = p5.map(i, 0, 100, 0, p5.TWO_PI);
        let radius = 200 * p5.cos(12 * angle + t) + 50; //cos12
        let x = radius * p5.cos(angle);
        let y = radius * p5.sin(angle);
        p5.curveVertex(x, y);
      }
      p5.scale(3.3);
      p5.endShape(p5.CLOSE);
      // noLoop()
    };
  };

  return (
    <ProjectWrapper>
      <Box
        sx={{
          position: "fixed",
          width: "1px",
          height: "1px",
          background: (theme: Theme) => theme.palette.primary.main,
          top: 0,
          zIndex: 999999999999,
          left: 0,
          borderRadius: "100%",
          animation: "animateCircle1 0.5s",
          animationIterationCount: 1,
          "@keyframes animateCircle1": {
            from: {
              transform: "scale(4000)",
            },
            to: { transform: "scale(0)" },
          },
        }}
      ></Box>
      <Box
        id={"container"}
        sx={{
          scrollSnapType: "y mandatory",
          overflowY: "scroll",
          height: "100vh",
          "& .section": {
            px: 5,
            py: 5,
            my: 5,
            height: "95vh",
            display: "flex",
            alignItems: "flex-end",
            flexDirection: { md: "row", xs: "column" },
            scrollSnapAlign: "start",
            "&  p": {
              fontSize: { sm: "28px", xs: "18px" },
              fontWeight: 600,
              ml: 3.5,
            },
            "& button": {
              ml: 2.3,
            },
            "& .blackBox": {
              width: { sm: "650px", xs: "350px" },
              height: { sm: "650px", xs: "350px" },
              background: "#000",
            },
          },
        }}
      >
        <SectionOneWrapper
          className="section"
          sx={{
            height: "310px !important",
            py: `${0} !important`,
            px: `${0} !important`,
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Typography variant="h1">CREATIVE CODING</Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: { sm: "row", xs: "column" },
                "& p": {
                  fontWeight: 500,
                  fontSize: { md: "28px", xs: "22px" },
                },
              }}
            >
              <Typography sx={{ ml: `8px !important` }}>
                ARTWORK USING P5
              </Typography>
            </Box>
          </Box>
        </SectionOneWrapper>
        <Box className="section" id="section1">
          <Box className="blackBox">
            {play.section1 && <ReactP5Wrapper sketch={sketch1} />}
          </Box>

          <Typography>TRIPPY COS 150</Typography>
        </Box>
        <Box className="section" id="section2">
          <Box
            sx={{
              overflow: "hidden",
              "& video": {
                width: { sm: "650px", xs: "350px" },
                height: "auto",
              },
            }}
          >
            <video
              crossOrigin="anonymous"
              src={Video2}
              muted={muted}
              ref={video2Ref}
              loop
            ></video>
          </Box>
          <Box>
            <IconButton onClick={() => setMuted(!muted)}>
              {muted ? (
                <VolumeOffRounded fontSize="large" color="disabled" />
              ) : (
                <VolumeUpRounded fontSize="large" color="primary" />
              )}
            </IconButton>
            <Typography>AUDIO VISUALIZER</Typography>
          </Box>
        </Box>
        <Box className="section" id="section3">
          <Box
            sx={{
              overflow: "hidden",
              "& video": {
                width: { sm: "650px", xs: "350px" },
                height: "auto",
              },
            }}
          >
            <video
              crossOrigin="anonymous"
              src={Video3}
              muted={muted}
              ref={video3Ref}
              loop
            ></video>
          </Box>
          <Box>
            <IconButton onClick={() => setMuted(!muted)}>
              {muted ? (
                <VolumeOffRounded fontSize="large" color="disabled" />
              ) : (
                <VolumeUpRounded fontSize="large" color="primary" />
              )}
            </IconButton>
            <Typography>NYC SUBWAY</Typography>
          </Box>
        </Box>
        <Box className="section" id="section4">
          <Box className="blackBox">
            {play.section4 && <ReactP5Wrapper sketch={sketch4} />}
          </Box>

          <Typography>FLASHING SOL LEWITT NO. 358</Typography>
        </Box>
        <Box className="section" id="section5">
          <Box className="blackBox">
            {play.section5 && <ReactP5Wrapper sketch={sketch5} />}
          </Box>

          <Typography>VENUS & EARTH ORBIT</Typography>
        </Box>
        <Box className="section" id="section6">
          <Box className="blackBox">
            {play.section6 && <ReactP5Wrapper sketch={sketch6} />}
          </Box>
          <Typography>LINE CIRCLE</Typography>
        </Box>
        <Box className="section" id="section7">
          <Box className="blackBox">
            {play.section7 && <ReactP5Wrapper sketch={sketch7} />}
          </Box>
          <Typography>MANDALA</Typography>
        </Box>
        <Box className="section" id="section8">
          <Box className="blackBox">
            {play.section8 && <ReactP5Wrapper sketch={sketch8} />}
          </Box>
          <Typography>MANDALA USING LINES</Typography>
        </Box>
        <Box className="section" id="section9">
          <Box className="blackBox">
            {play.section9 && <ReactP5Wrapper sketch={sketch9} />}
          </Box>
          <Typography>TRIPPY COS 12</Typography>
        </Box>
      </Box>
    </ProjectWrapper>
  );
};

export default withAuth(Project5);
