import React, { useRef, useEffect, useState } from "react";
import {
  ProjectWrapper,
  SectionEighteenWrapper,
  SectionFiveWrapper,
  SectionFourWrapper,
  SectionOneWrapper,
  SectionSeventeenWrapper,
  SectionThreeWrapper,
  SectionTwoWrapper,
} from "./styled";

import { Box, Grid, Theme, Typography, Modal } from "@mui/material";
import AutherSlider1 from "../../Assets/images/AutherSlider1.png";
import AutherSlider2 from "../../Assets/images/AutherSlider2.png";

import Auther1 from "../../Assets/images/Auther1.png";
import Auther2 from "../../Assets/images/Auther2.png";
import Auther3 from "../../Assets/images/Auther3.png";
import Auther4 from "../../Assets/images/Auther4.png";
import Auther5 from "../../Assets/images/Auther5.png";
import Auther6 from "../../Assets/images/Auther6.png";
import Auther7 from "../../Assets/images/Auther7.png";
import Auther8 from "../../Assets/images/Auther8.png";

import AutherLast1 from "../../Assets/images/AutherLast1.png";
import AutherLast2 from "../../Assets/images/AutherLast2.png";
import AutherLast3 from "../../Assets/images/AutherLast3.png";
import AutherLast4 from "../../Assets/images/AutherLast4.png";
import AutherLast5 from "../../Assets/images/AutherLast5.png";
import AutherLast6 from "../../Assets/images/AutherLast6.png";
import AutherLast7 from "../../Assets/images/AutherLast7.png";
import AutherLast8 from "../../Assets/images/AutherLast8.png";

import AutherSticky1 from "../../Assets/images/AutherSticky1.png";
import AutherSticky2 from "../../Assets/images/AutherSticky2.png";
import AutherSticky3 from "../../Assets/images/AutherSticky3.png";
import AutherSticky4 from "../../Assets/images/AutherSticky4.png";
import AutherSticky5 from "../../Assets/images/AutherSticky5.gif";
import AutherSticky6 from "../../Assets/images/AutherSticky6.gif";
import AutherSticky7 from "../../Assets/images/AutherSticky7.png";

import User1 from "../../Assets/images/user3.png";
import User2 from "../../Assets/images/user4.png";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { East, South } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";

import { scrollTrigger, stickyTrigger } from "../../Helper/scrollAnimation";

import ToolTipIcons from "../../Assets/Icons/ToolTipIcons";
import Marquee from "react-fast-marquee";
import CircleIcon from "../../Assets/Icons/CircleIcon";
import TriangleIcon from "../../Assets/Icons/TriangleIcon";
import SqaureIcon from "../../Assets/Icons/SqaureIcon";
import AutherDraw1 from "../../Assets/Icons/AutherDraw1";
import AutherDraw2 from "../../Assets/Icons/AutherDraw2";
import AutherDraw3 from "../../Assets/Icons/AutherDraw3";
import AutherDraw4 from "../../Assets/Icons/AutherDraw4";
import RoundText2 from "../../Assets/Icons/RoundText2";
import withAuth from "../../HOC/withAuth";

const ItrationText1 = [
  {
    header: "Old design",
    image: AutherSticky1,
    content: [
      {
        bold: "Excerpts & Synthesized problems",
        text: "“The word “Assets” does not give a clear indication of the type of files that can be added.”",
      },
      {
        text: "“I wish I could see the preview of files before uploading them, to avoid wrong upload.”",
      },

      {
        text: "4 users clicked on the blue flag with the ‘+’ when asked to add images into the document, instead of selecting ‘Add Asset’",
      },
      {
        text: "“It gets tedious to find the required asset if there are a lot of assets.”",
      },
      {
        text: "“Whenever I add an image to my document, it disrupts my entire document layout.”",
      },
    ],
  },
  {
    header: "New design",
    image: AutherSticky2,
    content: [
      {
        bold: "Solutions designed",
        text: "A single “Add Asset” option changed to “Add element” dropdown",
      },
    ],
  },
  {
    header: "New design",
    image: AutherSticky3,
    content: [
      {
        text: "To make asset finding easier, Asset Library divided into ‘Current Project’ & ‘Other Projects’",
      },
      {
        text: "Aspect Ratio of images can now be changed from the 'Add Image' screen itself.",
      },
    ],
  },
  {
    header: "New design",
    image: AutherSticky4,
    content: [
      {
        text: "While adding image, Thumbnail View has been added which shows the preview there itself.",
      },
      {
        text: "'Lock' option has been added to the Format toolbar which locks image placement without affecting document layout.",
      },
    ],
  },
];
const ItrationText2 = [
  {
    header: "Old design",
    image: AutherSticky5,
    content: [
      {
        bold: "Synthesized problems",
        text: "50% of participants were not able to discover the Grey Flag which is used to insert an element.",
      },
      {
        text: "Icon always remains '+', which gets misleading once an element is inserted.",
      },

      {
        text: "Change in colour to depict change of state decreases accessibility for the colour-blind.",
      },
    ],
  },
  {
    header: "New design",
    image: AutherSticky6,
    content: [
      {
        bold: "Solutions designed",
        text: "Clearly viewable option to 'Add Element' given on top toolbar, along-with dedicated icon to add a table (Grey Flag completely removed)",
      },
    ],
  },
  {
    header: "New design",
    image: AutherSticky7,
    content: [
      {
        text: "Flag functionality retained, but the icon changed to something more relevant to its use, with no dependency on colour",
      },
      {
        text: "Preview provided right there, as opposed to no way to see preview earlier without actually applying text style to the entire document",
      },
    ],
  },
];

// const LastSliderText=[

// ]

const Project3 = () => {
  const { pathname } = useLocation();
  const IterationSection = useRef<any>([]);
  const [isPlaying, setIsPlaying] = useState(true);
  const [position, setPosition] = useState("sticky");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [image, setImage] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      scrollTrigger(".lineAnimation");
      stickyTrigger(".checkSticky");
      const section = document.getElementById("section3");
      if (section) {
        if (window.scrollY > section.offsetTop) {
          if (position === "sticky") {
            setPosition("relative");
          }
        } else {
          if (position === "relative") {
            setPosition("sticky");
          }
        }
      }
    });
  }, []);

  const handleImage = (image: any) => {
    setImage(image);
    handleOpen();
  };

  const handleScroll = () => {
    const section = document.getElementById("section3");
    const sectionPosition = section?.offsetTop ?? 0;
    window.scrollTo({ top: sectionPosition });
  };
  return (
    <ProjectWrapper>
      <Modal
        sx={{ display: { sm: "none", xs: "block" } }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            width: "80%",
            height: "70vh",
            transform: "translate(-50%, -50%)",
            background: "#fff",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            "& img": {
              position: "absolute",
              left: "-18%",
              top: "28%",
              width: "60vh",
              height: "auto",
              transform: "rotate(90deg)",
            },
          }}
        >
          {image && <img src={image} alt="no content" />}
        </Box>
      </Modal>
      <SectionOneWrapper>
        <Typography variant="h1">AUTHORCAFé</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { sm: "row", xs: "column" },
            "& p": {
              fontWeight: 500,

              fontSize: { md: "28px", xs: "22px" },
            },
          }}
        >
          <Typography>SAAS TOOL DESIGN</Typography>
          <Typography sx={{ ml: { sm: 6 } }}>2 MONTHS</Typography>
        </Box>
      </SectionOneWrapper>
      <Box sx={{ overflowX: "hidden" }}>
        <SectionTwoWrapper>
          <ParallaxProvider>
            <Parallax
              // speed={-10}
              translateX={["17", "-20"]}
              easing="easeOutQuad"
              endScroll={100}
            >
              <Box
                sx={{
                  display: "flex",
                  "& img": {
                    width: {
                      lg: "832px",
                      md: "700px",
                      sm: "600px",
                      xs: "402px",
                    },
                    height: "auto",
                    boxShadow: "4px 0px 20px 0px #66666640",
                    borderRadius: "12px",
                  },
                  "& div": {
                    mx: 1.75,
                  },
                }}
              >
                <Box>
                  <img src={AutherSlider1} alt="No Banner" />
                </Box>
                <Box>
                  <img src={AutherSlider2} alt="No Banner" />
                </Box>
              </Box>
            </Parallax>
          </ParallaxProvider>
        </SectionTwoWrapper>
      </Box>
      <SectionThreeWrapper id="section1">
        <Box>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
          >
            <Typography
              variant="h3"
              sx={{
                fontSize: { md: "28px", xs: "22px" },
                fontWeight: 600,
              }}
            >
              What is AuthorCafé?
            </Typography>
          </AnimationOnScroll>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
            delay={300}
          >
            <Typography sx={{ fontSize: { md: "22px", sm: "18px" }, mt: 2.5 }}>
              AC is a web-based word editor, inclined specifically towards
              writing of academic content. It aims to make writing
              research-oriented content feature packed as compared to
              traditional Word Editors. AC has several research paper specific
              features which make it easier and simpler to write research papers
              effortlessly.
            </Typography>
          </AnimationOnScroll>
        </Box>
        <Box sx={{ mt: 10 }}>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
          >
            <Typography
              variant="h3"
              sx={{
                fontSize: { md: "28px", xs: "22px" },
                fontWeight: 600,
              }}
            >
              Problem Statement/Description
            </Typography>
          </AnimationOnScroll>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
            delay={300}
          >
            <Typography sx={{ fontSize: { md: "22px", sm: "18px" }, mt: 2.5 }}>
              Redesign the AuthorCafé academic content-writing platform, and
              add/remove certain features related to academic writing. All while
              keeping it similar to MS Word as that's what most of their users
              used prior to this.
            </Typography>
          </AnimationOnScroll>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
            delay={300}
          >
            <Box
              sx={{
                py: 3.5,
                px: 4,
                mt: 9,
                background: (theme: Theme) => theme.palette.secondary.main,
              }}
            >
              <Typography
                sx={{
                  fontSize: { md: "28px", xs: "22px" },
                  fontWeight: 600,
                  lineHeight: "33.89px",
                }}
              >
                Since this was a completely different type of application,{" "}
                <Typography
                  component="span"
                  sx={{
                    fontSize: { md: "28px", xs: "22px" },
                    fontWeight: 600,
                    lineHeight: "33.89px",
                    textDecorationLine: "underline",
                    position: "relative",
                    "&:before": {
                      display: "none",
                      content: "'Alongside me, 1 UX Designer, & 1 PM'",
                      width: "fit-content",
                      height: "auto",
                      position: "absolute",
                      top: "100%",
                      left: "100%",
                      p: { md: 3, xs: 1.5 },
                      background: "#000000",
                      color: (theme: Theme) => theme.palette.secondary.main,
                      whiteSpace: "nowrap",
                      fontSize: { md: "22px", xs: "18px" },
                      fontWeight: 600,
                    },
                    "&:hover": {
                      cursor: "pointer",
                      "&:before": {
                        display: { md: "block", xs: "none" },
                      },
                    },
                  }}
                >
                  we
                </Typography>{" "}
                decided to start off with rounds of usability testing, in order
                to understand the application and user behavior better.
              </Typography>
            </Box>
          </AnimationOnScroll>
        </Box>
      </SectionThreeWrapper>
      <SectionThreeWrapper id="section2">
        <Box>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
          >
            <Typography
              variant="h3"
              sx={{
                fontSize: { md: "40px", sm: "32px", xs: "28px" },
                fontWeight: 700,
                fontFamily: "Mohave",
                fontStyle: "italic",
              }}
            >
              MY ROLE
            </Typography>
          </AnimationOnScroll>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
          >
            <Box sx={{ mt: 1.5, position: "relative" }}>
              <Box
                sx={{
                  position: "absolute",
                  left: "14%",
                  top: "21%",
                  zIndex: -1,
                }}
              >
                <CircleIcon />
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  left: "51%",
                  top: "-35%",
                  zIndex: -1,
                }}
              >
                <TriangleIcon />
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  right: "6%",
                  top: "40%",
                  zIndex: -1,
                }}
              >
                <SqaureIcon />
              </Box>
              <Typography
                variant="h3"
                sx={{
                  fontSize: { md: "28px", xs: "22px" },
                  fontWeight: 600,
                }}
              >
                Usability Testing -&gt; Finding user problems -&gt; Wireframing
                solutions
              </Typography>
            </Box>
          </AnimationOnScroll>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
            delay={300}
          >
            <Box
              sx={{
                py: 3.5,
                px: 4,
                pl: 7,
                mt: 9,
                background: (theme: Theme) => theme.palette.secondary.main,
                position: "relative",
                "& p": {
                  fontSize: { md: "28px", xs: "22px" },
                  fontWeight: 600,
                  lineHeight: "33.89px",
                },
              }}
            >
              <Typography>
                3 rounds of Usability Testing were conducted.
              </Typography>
              <Box
                sx={{
                  position: "relative",
                  "&:before": {
                    content: "''",
                    position: "absolute",
                    left: "-20px",
                    top: 25,
                    width: "1px",
                    height: "75%",
                    borderLeft: "3px dashed #000",
                  },
                }}
              >
                <Typography
                  sx={{
                    "&:before": {
                      content: '""',
                      position: "absolute",
                      width: "10px",
                      height: "10px",
                      background: "#000",
                      left: "-24px",
                      borderRadius: "100%",
                      top: 25,
                    },
                    mt: 5,
                  }}
                >
                  1st - To understand what works and what doesn’t in the
                  existing application, before designing anything
                </Typography>
                <Typography
                  sx={{
                    mt: 5,
                    "&:before": {
                      content: '""',
                      position: "absolute",
                      width: "10px",
                      height: "10px",
                      background: "#000",
                      left: "-24px",
                      borderRadius: "100%",
                      top: "45%",
                      transform: "translateY(-45%)",
                    },
                  }}
                >
                  2nd - To understand user sentiment towards the redesign, after
                  designing
                </Typography>
                <Typography
                  sx={{
                    mt: 5,
                    "&:before": {
                      content: '""',
                      position: "absolute",
                      width: "10px",
                      height: "10px",
                      background: "#000",
                      left: "-24px",
                      borderRadius: "100%",
                      top: "80%",
                    },
                  }}
                >
                  3rd - Testing feedback (received during 2nd test)
                  implementation after 2nd usability test, making sure everyone
                  is on the same page
                </Typography>
              </Box>
            </Box>
          </AnimationOnScroll>
        </Box>
        <Box sx={{ mt: 10 }}>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
          >
            <Typography
              variant="h3"
              sx={{
                fontSize: { md: "28px", xs: "22px" },
                fontWeight: 600,
              }}
            >
              Usability Testing #1 - Understanding target users’ pain points
            </Typography>
          </AnimationOnScroll>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
            delay={300}
          >
            <Typography sx={{ fontSize: { md: "22px", sm: "18px" }, mt: 2.5 }}>
              Participants were given <b>9 tasks -</b>
            </Typography>
          </AnimationOnScroll>
        </Box>
        <Box sx={{ mt: 10, position: "relative" }}>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
            delay={300}
          >
            <Box
              sx={{
                py: 3.5,
                px: 4,
                mt: 9,
                background: (theme: Theme) => theme.palette.secondary.main,
              }}
            >
              <Typography
                sx={{
                  fontSize: { md: "28px", xs: "22px" },
                  fontWeight: 600,
                  lineHeight: "33.89px",
                }}
              >
                The tasks were formulated based on discussions with the business
                stakeholders, considering features and usage-data. The sparingly
                used features were added in the tasks with an emphasis on “Why
                isn’t this being used?, What’s going wrong?”, etc., while the
                most-used ones were added to understand possible usability
                issues.
              </Typography>
            </Box>
          </AnimationOnScroll>
          <Box
            sx={{
              mt: 7,
              "& p": {
                fontSize: { md: "22px", sm: "18px" },
                mt: 0.75,
              },
            }}
          >
            <AnimationOnScroll
              duration={0.5}
              animateOnce={true}
              animateIn="animate__fadeInUp"
              delay={300}
            >
              <Typography>-&gt; Formatting a Paragraph</Typography>
              <Typography>-&gt; Formatting Heading</Typography>
              <Typography>
                -&gt; Adding an Image (Added in this Case Study)
              </Typography>
              <Typography>
                -&gt; Adding an Element (Table) (Added in this Case Study)
              </Typography>
              <Typography>-&gt; Rearranging Elements</Typography>
              <Typography>-&gt; Adding a Reference</Typography>
              <Typography>-&gt; Adding a Citation</Typography>
              <Typography>-&gt; Inviting a Collaborator</Typography>
              <Typography>-&gt; Writing and Sharing a Comment</Typography>
            </AnimationOnScroll>
          </Box>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
            delay={300}
          >
            <Box
              sx={{
                py: 3.5,
                px: 4,
                mt: 5.25,
                background: (theme: Theme) => theme.palette.secondary.main,
              }}
            >
              <Typography
                sx={{
                  fontSize: { md: "28px", xs: "22px" },
                  fontWeight: 600,
                  lineHeight: "33.89px",
                }}
              >
                To keep this case study concise, I've added detailed analysis
                for 2/9 of the tasks.
              </Typography>
            </Box>
          </AnimationOnScroll>
        </Box>
      </SectionThreeWrapper>
      <Box
        sx={{
          position: position,
          top: { md: "200px" },
          width: "100%",
          maxWidth: "1280px",
          marginTop: -2.5,
          display: { md: "block", xs: "none" },
          zIndex: 99,
        }}
      >
        <Box
          sx={{
            transform: "translate(-50%,-80%)",
            right: 0,
            width: "120px",
            height: "120px",
            borderRadius: "100%",
            display: { md: "flex", sm: "none" },
            alignItems: "center",
            float: "right",
            justifyContent: "center",
            cursor: "pointer",
            "&:hover": {
              "& > div:nth-of-type(1)": {
                animationPlayState: "running",
              },
            },
            "& > div:nth-of-type(1)": {
              animation: "rotation 8s infinite linear ",
              animationPlayState: "paused",
            },
          }}
          onClick={handleScroll}
        >
          <Box
            sx={{
              position: "absolute",
              "& svg": {
                width: "150px",
                height: "150px",
              },
            }}
          >
            <RoundText2 />
          </Box>
          <Box sx={{ color: "#000", zIndex: 1 }}>
            <South fontSize="large" color="inherit" />
          </Box>
        </Box>
      </Box>

      <SectionFourWrapper>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Box sx={{ mt: 1.5 }}>
            <Typography
              variant="h3"
              sx={{
                fontSize: { md: "28px", xs: "22px" },
                fontWeight: 600,
                mt: 5.2,
              }}
            >
              Task 1 - Adding an Image - Insights Captured from Usability
              Testing
            </Typography>
          </Box>
        </AnimationOnScroll>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: 600,
              mt: 5.75,
              color: "#009806 !important",
            }}
          >
            User Recommendations
          </Typography>
        </AnimationOnScroll>
        <Box>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
          >
            <Grid container rowSpacing={3} columnSpacing={10}>
              <Grid item md={6}>
                <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
                  <East color="success" />
                  <Typography
                    sx={{ ml: 1, fontSize: { md: "22px", xs: "1rem" } }}
                  >
                    The word “Add” is more helpful than “Assets” as it does not
                    give a clear indication of the type of files that can be
                    added.
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
                  <East color="success" />
                  <Typography
                    sx={{ ml: 1, fontSize: { md: "22px", xs: "1rem" } }}
                  >
                    Viewing the files in a different format (perhaps thumbnails,
                    etc.)
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
                  <East color="success" />
                  <Typography
                    sx={{ ml: 1, fontSize: { md: "22px", xs: "1rem" } }}
                  >
                    The option to see preview of the file is good, but currently
                    it seems disconnected from the list of assets.
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
                  <East color="success" />
                  <Typography
                    sx={{ ml: 1, fontSize: { md: "22px", xs: "1rem" } }}
                  >
                    Allowing a search functionality will also add value.
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
                  <East color="success" />
                  <Typography
                    sx={{ ml: 1, fontSize: { md: "22px", xs: "1rem" } }}
                  >
                    The list of assets is only visible in the “List” format.
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
                  <East color="success" />
                  <Typography
                    sx={{ ml: 1, fontSize: { md: "22px", xs: "1rem" } }}
                  >
                    Possibility to lock an image in its position, so that the
                    rest of the document does not get disrupted.x
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </AnimationOnScroll>
        </Box>
      </SectionFourWrapper>
      <SectionFourWrapper>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Box sx={{ mt: 1.5 }}>
            <Typography
              variant="h3"
              sx={{
                fontSize: { md: "28px", xs: "22px" },
                fontWeight: 600,
                mt: 5.2,
              }}
            >
              Task 2 - Adding a Table - Insights Captured from Usability Testing
            </Typography>
          </Box>
        </AnimationOnScroll>

        <Box>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
          >
            <Grid
              container
              sx={{ display: { md: "flex", xs: "none" } }}
              rowSpacing={3}
              columnSpacing={10}
            >
              <Grid item md={6}>
                <Typography
                  sx={{
                    fontSize: "24px",
                    fontWeight: 600,
                    mt: 5.75,
                    color: "#ff0000 !important",
                  }}
                >
                  Pain Points
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Typography
                  sx={{
                    fontSize: "24px",
                    fontWeight: 600,
                    mt: 5.75,
                    color: "#009806 !important",
                  }}
                >
                  Possible Improvements
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
                  <East color="error" />
                  <Typography
                    sx={{ ml: 1, fontSize: { md: "22px", xs: "1rem" } }}
                  >
                    3 participants were not able to discover the Grey Flag which
                    is used to insert an element.
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
                  <East color="success" />
                  <Typography
                    sx={{ ml: 1, fontSize: { md: "22px", xs: "1rem" } }}
                  >
                    We could demarcate the Blue and Grey flags.
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
                  <East color="error" />
                  <Typography
                    sx={{ ml: 1, fontSize: { md: "22px", xs: "1rem" } }}
                  >
                    Icon always remains '+', which gets misleading once an
                    element is inserted.
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
                  <East color="success" />
                  <Typography
                    sx={{ ml: 1, fontSize: { md: "22px", xs: "1rem" } }}
                  >
                    Keyboard shortcuts similar to{" "}
                    <Typography
                      component="span"
                      sx={{
                        fontSize: { md: "22px", xs: "18px" },
                        fontWeight: 600,
                        lineHeight: "33.89px",
                        textDecorationLine: "underline",
                        position: "relative",
                        "&:before": {
                          display: "none",
                          content: "'Popular reference-management software'",
                          width: "400px",
                          height: "auto",
                          position: "absolute",
                          top: "100%",
                          left: "0",
                          p: { md: 3, xs: 1.5 },
                          background: "#000000",
                          color: (theme: Theme) => theme.palette.secondary.main,
                          fontSize: { md: "22px", xs: "18px" },
                          fontWeight: 600,
                        },
                        "&:hover": {
                          cursor: "pointer",
                          "&:before": {
                            display: { md: "block", xs: "none" },
                          },
                        },
                      }}
                    >
                      Zotero
                    </Typography>{" "}
                    could be introduced.
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
                  <East color="error" />
                  <Typography
                    sx={{ ml: 1, fontSize: { md: "22px", xs: "1rem" } }}
                  >
                    Change in colour to depict change of state decreases
                    accessibility for the colour-blind.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              sx={{ display: { md: "none", xs: "block" } }}
              rowSpacing={3}
              columnSpacing={10}
            >
              <Grid item md={6}>
                <Typography
                  sx={{
                    fontSize: "24px",
                    fontWeight: 600,
                    mt: 5.75,
                    color: "#ff0000 !important",
                  }}
                >
                  Pain Points
                </Typography>
                <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
                  <East color="error" />
                  <Typography
                    sx={{ ml: 1, fontSize: { md: "22px", xs: "1rem" } }}
                  >
                    3 participants were not able to discover the Grey Flag which
                    is used to insert an element.
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
                  <East color="error" />
                  <Typography
                    sx={{ ml: 1, fontSize: { md: "22px", xs: "1rem" } }}
                  >
                    Icon always remains '+', which gets misleading once an
                    element is inserted.
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
                  <East color="error" />
                  <Typography
                    sx={{ ml: 1, fontSize: { md: "22px", xs: "1rem" } }}
                  >
                    Change in colour to depict change of state decreases
                    accessibility for the colour-blind.
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Typography
                  sx={{
                    fontSize: "24px",
                    fontWeight: 600,
                    mt: 5.75,
                    color: "#009806 !important",
                  }}
                >
                  Possible Improvements
                </Typography>
                <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
                  <East color="success" />
                  <Typography
                    sx={{ ml: 1, fontSize: { md: "22px", xs: "1rem" } }}
                  >
                    We could demarcate the Blue and Grey flags.
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
                  <East color="success" />
                  <Typography
                    sx={{ ml: 1, fontSize: { md: "22px", xs: "1rem" } }}
                  >
                    Keyboard shortcuts similar to Zotero could be introduced.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </AnimationOnScroll>
        </Box>
      </SectionFourWrapper>
      <SectionFiveWrapper id="section3">
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: { md: "40px", sm: "32px", xs: "28px" },
              fontWeight: 700,
              fontFamily: "Mohave",
              fontStyle: "italic",
            }}
          >
            Mid-fi Wireframing - Based on Usability Testing #1
          </Typography>
        </AnimationOnScroll>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: { md: "28px", xs: "22px" },
              fontWeight: 600,
              mt: 3,
            }}
          >
            Adding an Element (Image)
          </Typography>
        </AnimationOnScroll>
        <Box>
          {ItrationText1.map((item, i) => {
            const tempArray: any = [];
            return (
              <Box
                sx={{ position: "relative" }}
                onClick={() => handleImage(item.image)}
              >
                <Box
                  sx={{
                    mt: 5,
                    position: "sticky",
                    top: 100,
                    zIndex: -1,
                    "& img": {
                      width: "100%",
                      height: "auto",
                      border: "2px solid #ADFF00",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      background: "#ADFF00",
                      py: 1.75,
                      px: 3,
                      fontWeight: 700,
                      fontSize: { md: "22px", xs: "18px" },
                    }}
                  >
                    {item.header}
                  </Typography>
                  <img src={item.image} alt="no content" />
                </Box>

                {item.content.map((contentItem, index) => {
                  return (
                    <Box
                      ref={(c) => (tempArray[index] = c)}
                      sx={{
                        position: "",
                        top: "80%",
                        marginTop: index !== 0 ? "80vh" : "",
                        display: "flex",
                        justifyContent: index % 2 === 0 ? "left" : "right",
                        // textAlign:
                      }}
                    >
                      <Box
                        sx={{
                          px: 3,
                          py: 2,
                          maxWidth: "390px",
                          color: "#000",
                          fontSize: { md: "20px", xs: "16px" },
                          background: (theme: Theme) =>
                            theme.palette.secondary.main,
                        }}
                      >
                        {contentItem.bold && (
                          <>
                            <b>{contentItem.bold}</b> <br />
                          </>
                        )}
                        {contentItem.text}
                      </Box>
                    </Box>
                  );
                })}
                {(IterationSection.current[i] = tempArray)}
              </Box>
            );
          })}
        </Box>
      </SectionFiveWrapper>
      <SectionFourWrapper>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
          delay={300}
        >
          <Box
            sx={{
              py: 3.5,
              px: 4,
              mt: 5.25,
              background: (theme: Theme) => theme.palette.secondary.main,
            }}
          >
            <Typography
              sx={{
                fontSize: { md: "28px", xs: "22px" },
                fontWeight: 600,
                lineHeight: "33.89px",
              }}
            >
              All solutions were tested iteratively with unique participants
              from our usability testing sessions, in order to get quick
              feedback with iterative implementation.
            </Typography>
          </Box>
        </AnimationOnScroll>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: { md: "28px", xs: "22px" },
              fontWeight: 600,
              mt: 7,
            }}
          >
            Adding an Element (Table)
          </Typography>
        </AnimationOnScroll>
        <Box>
          {ItrationText2.map((item, i) => {
            const tempArray: any = [];
            return (
              <Box
                sx={{ position: "relative" }}
                onClick={() => handleImage(item.image)}
              >
                <Box
                  sx={{
                    mt: 5,
                    position: "sticky",
                    top: 100,

                    zIndex: -1,
                    "& img": {
                      width: "100%",
                      height: "auto",
                      border: "2px solid #ADFF00",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      background: "#ADFF00",
                      py: 1.75,
                      px: 3,

                      fontWeight: 700,
                      fontSize: { md: "22px", xs: "18px" },
                    }}
                  >
                    {item.header}
                  </Typography>
                  <img src={item.image} alt="no content" />
                </Box>

                {item.content.map((contentItem, index) => {
                  return (
                    <Box
                      ref={(c) => (tempArray[index] = c)}
                      sx={{
                        position: "",
                        top: "80%",
                        marginTop: index !== 0 ? "80vh" : "",
                        display: "flex",
                        justifyContent: index % 2 === 0 ? "left" : "right",
                        // textAlign:
                      }}
                    >
                      <Box
                        sx={{
                          px: 3,
                          py: 2,
                          maxWidth: "390px",
                          color: "#000",
                          fontSize: { md: "20px", xs: "16px" },
                          background: (theme: Theme) =>
                            theme.palette.secondary.main,
                        }}
                      >
                        {contentItem.bold && (
                          <>
                            <b>{contentItem.bold}</b> <br />
                          </>
                        )}
                        {contentItem.text}
                      </Box>
                    </Box>
                  );
                })}
                {(IterationSection.current[i] = tempArray)}
              </Box>
            );
          })}
        </Box>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: { md: "28px", xs: "22px" },
              fontWeight: 600,
              mt: 7,
            }}
          >
            Insights Captured from Usability Testing #2
          </Typography>
        </AnimationOnScroll>
        <Box>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
          >
            <Grid
              sx={{ display: { md: "none", xs: "block" } }}
              container
              rowSpacing={3}
              columnSpacing={10}
            >
              <Grid item md={6}>
                <Typography
                  sx={{
                    fontSize: "24px",
                    fontWeight: 600,
                    mt: 5.75,
                    color: "#ff0000 !important",
                  }}
                >
                  Pain Points
                </Typography>
                <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
                  <East color="error" />
                  <Typography
                    sx={{ ml: 1, fontSize: { md: "22px", xs: "1rem" } }}
                  >
                    2 out of 3 participants understand the Indent Icon
                    correctly, while 1 participant mistook it for an Alignment
                    icon.
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
                  <East color="error" />
                  <Typography
                    sx={{ ml: 1, fontSize: { md: "22px", xs: "1rem" } }}
                  >
                    All 3 participants could discover the drop-down, but, Sketch
                    visual cues helped 2 out of 3 participants discover it.
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
                  <East color="error" />
                  <Typography
                    sx={{ ml: 1, fontSize: { md: "22px", xs: "1rem" } }}
                  >
                    Preview in 'Section Heading' is redundant and not helping.
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
                  <East color="error" />
                  <Typography
                    sx={{ ml: 1, fontSize: { md: "22px", xs: "1rem" } }}
                  >
                    All 3 participants discover the Style Flag easily, although
                    Sketch cues helped P3 discover it.
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Typography
                  sx={{
                    fontSize: "24px",
                    fontWeight: 600,
                    mt: 5.75,
                    color: "#009806 !important",
                  }}
                >
                  Possible Improvements
                </Typography>
                <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
                  <East color="success" />
                  <Typography
                    sx={{ ml: 1, fontSize: { md: "22px", xs: "1rem" } }}
                  >
                    Indent Icon needs rework.
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
                  <East color="success" />
                  <Typography
                    sx={{ ml: 1, fontSize: { md: "22px", xs: "1rem" } }}
                  >
                    The drop-down option could be made more evident/easily
                    discoverable.
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
                  <East color="success" />
                  <Typography
                    sx={{ ml: 1, fontSize: { md: "22px", xs: "1rem" } }}
                  >
                    Preview in 'Section Heading' under the Style Flag menu can
                    be removed, as the visual representation is already shown on
                    the editor.
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
                  <East color="success" />
                  <Typography
                    sx={{ ml: 1, fontSize: { md: "22px", xs: "1rem" } }}
                  >
                    Style Flag could be made more discoverable
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid
              sx={{ display: { md: "flex", xs: "none" } }}
              container
              rowSpacing={3}
              columnSpacing={10}
            >
              <Grid item md={6}>
                <Typography
                  sx={{
                    fontSize: "24px",
                    fontWeight: 600,
                    mt: 5.75,
                    color: "#ff0000 !important",
                  }}
                >
                  Pain Points
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Typography
                  sx={{
                    fontSize: "24px",
                    fontWeight: 600,
                    mt: 5.75,
                    color: "#009806 !important",
                  }}
                >
                  Possible Improvements
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
                  <East color="error" />
                  <Typography
                    sx={{ ml: 1, fontSize: { md: "22px", xs: "1rem" } }}
                  >
                    2 out of 3 participants understand the Indent Icon
                    correctly, while 1 participant mistook it for an Alignment
                    icon.
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
                  <East color="success" />
                  <Typography
                    sx={{ ml: 1, fontSize: { md: "22px", xs: "1rem" } }}
                  >
                    Indent Icon needs rework.
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
                  <East color="error" />
                  <Typography
                    sx={{ ml: 1, fontSize: { md: "22px", xs: "1rem" } }}
                  >
                    All 3 participants could discover the drop-down, but, Sketch
                    visual cues helped 2 out of 3 participants discover it.
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
                  <East color="success" />
                  <Typography
                    sx={{ ml: 1, fontSize: { md: "22px", xs: "1rem" } }}
                  >
                    The drop-down option could be made more evident/easily
                    discoverable.
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
                  <East color="error" />
                  <Typography
                    sx={{ ml: 1, fontSize: { md: "22px", xs: "1rem" } }}
                  >
                    Preview in 'Section Heading' is redundant and not helping.
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
                  <East color="success" />
                  <Typography
                    sx={{ ml: 1, fontSize: { md: "22px", xs: "1rem" } }}
                  >
                    Preview in 'Section Heading' under the Style Flag menu can
                    be removed, as the visual representation is already shown on
                    the editor.
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
                  <East color="error" />
                  <Typography
                    sx={{ ml: 1, fontSize: { md: "22px", xs: "1rem" } }}
                  >
                    All 3 participants discover the Style Flag easily, although
                    Sketch cues helped P3 discover it.
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
                  <East color="success" />
                  <Typography
                    sx={{ ml: 1, fontSize: { md: "22px", xs: "1rem" } }}
                  >
                    Style Flag could be made more discoverable
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </AnimationOnScroll>
        </Box>
      </SectionFourWrapper>
      <Box sx={{ overflowX: "hidden" }}>
        <SectionFourWrapper>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
          >
            <Box sx={{ mt: 1.5 }}>
              <Typography
                variant="h3"
                sx={{
                  fontSize: { md: "28px", xs: "22px" },
                  fontWeight: 600,
                  mt: 5.2,
                }}
              >
                Changes made based on user feedback collected
              </Typography>
            </Box>
          </AnimationOnScroll>
          <Box
            sx={{
              fontSize: { md: "22px", sm: "18px" },
              position: "relative",
              fontFamily: "Caveat",
              color: "#000",
            }}
          >
            <Grid container sx={{ mt: 5.25 }} columnSpacing={{ lg: 7, md: 5 }}>
              <Grid
                item
                md={6}
                xs={11}
                sx={{
                  "& img": {
                    width: { lg: "479.88px", xs: "100%" },
                  },
                }}
              >
                <AnimationOnScroll
                  animateOnce={true}
                  animateIn={"animate__fadeInLeft"}
                >
                  <img src={Auther1} alt="no content" />
                </AnimationOnScroll>
              </Grid>
              <Grid item md={6} xs={11} sx={{ mt: { md: 0, xs: 5 } }}>
                <AnimationOnScroll
                  animateOnce={true}
                  delay={500}
                  animateIn="animate__flipInX"
                >
                  <ul style={{ marginTop: "0" }}>
                    <li>
                      Preview for Section Heading removed, instead, visual
                      hierarchy depicting different levels added.
                    </li>
                    <li>Indent icon modified</li>
                    <li>Dropdown made more evident</li>
                  </ul>
                </AnimationOnScroll>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: { md: "block", xs: "none" },
                position: "absolute",
                top: { lg: "50%", md: "52%" },
                left: { lg: "50%", md: "55%" },
              }}
            >
              <AutherDraw1 />
            </Box>
            <Box
              className="lineAnimation"
              sx={{
                display: { md: "block", xs: "none" },
                position: "absolute",
                top: { lg: "50%", md: "52%" },
                left: { lg: "50%", md: "55%" },
                width: "305px",
                height: "130px",
                background: "#fff",
              }}
            ></Box>
          </Box>
          <Box
            sx={{
              fontSize: { md: "22px", sm: "18px" },
              position: "relative",
              fontFamily: "Caveat",
              color: "#000",
              marginTop: 12,
              ".lineAnimation.active": {
                animation: "pencil2 1.5s forwards",
                animationDelay: "1s",
              },
            }}
          >
            <Grid
              container
              sx={{
                mt: 5.25,
                flexDirection: { md: "row", xs: "column-reverse" },
              }}
              columnSpacing={7}
            >
              <Grid item md={6} xs={11}>
                <AnimationOnScroll
                  animateOnce={true}
                  delay={500}
                  animateIn="animate__flipInX"
                >
                  <Box sx={{ mt: { md: 0, sm: 5 } }}>
                    On hovering over the written text, Style Flag icon shows up
                    on the left along with tool tip to help users understand its
                    functionality.
                  </Box>
                </AnimationOnScroll>
              </Grid>
              <Grid
                item
                md={6}
                xs={11}
                sx={{
                  "& img": {
                    width: { lg: "479.88px", xs: "100%" },
                  },
                }}
              >
                <AnimationOnScroll
                  animateOnce={true}
                  animateIn={"animate__fadeInRight"}
                >
                  <img src={Auther2} alt="no content" />
                </AnimationOnScroll>
              </Grid>
            </Grid>
            <Box
              sx={{
                position: "absolute",
                display: { md: "block", xs: "none" },
                top: { lg: "50%", md: "40%" },
                left: { lg: "27%", md: "25%" },
                transform: "rotateY(180deg)",
              }}
            >
              <AutherDraw1 />
            </Box>
            <Box
              className="lineAnimation"
              sx={{
                display: { md: "block", xs: "none" },
                position: "absolute",
                top: { lg: "50%", md: "40%" },
                left: { lg: "27%", md: "25%" },
                width: "240px",
                height: "130px",
                background: "#fff",
              }}
            ></Box>
          </Box>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
            delay={300}
          >
            <Box
              sx={{
                py: 3.5,
                px: 4,
                mt: 8,
                background: (theme: Theme) => theme.palette.secondary.main,
              }}
            >
              <Typography
                sx={{
                  fontSize: { md: "28px", xs: "22px" },
                  fontWeight: 600,
                  lineHeight: "33.89px",
                }}
              >
                This is how changes were made iteratively, with user feedback
                taken into consideration at each step. <br />
                <br />
                Similarly, iterations were made for the other features of the
                application. If you would like to see that, please reach out to
                me on LinkedIn.
              </Typography>
            </Box>
          </AnimationOnScroll>
        </SectionFourWrapper>
        <SectionFourWrapper sx={{ mt: 8 }}>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
          >
            <Box>
              <Typography
                variant="h3"
                sx={{
                  fontSize: { md: "28px", xs: "22px" },
                  fontWeight: 600,
                }}
              >
                Some other miscellaneous changes
              </Typography>
            </Box>
          </AnimationOnScroll>
          <Box
            sx={{
              fontSize: { md: "22px", sm: "18px" },
              position: "relative",
              fontFamily: "Caveat",
              color: "#000",
              display: "flex",
            }}
          >
            <Typography
              variant="h3"
              sx={{
                color: "#0021f5",
                fontSize: { md: "28px", xs: "22px" },
                fontWeight: 600,
                mt: 5,
              }}
            >
              1/3
            </Typography>
            <Grid container sx={{ mt: 5.25, ml: -5 }} columnSpacing={7}>
              <Grid
                item
                md={6}
                xs={11}
                sx={{
                  "& img": {
                    width: "100%",
                  },
                }}
              >
                <AnimationOnScroll
                  animateOnce={true}
                  animateIn={"animate__fadeInLeft"}
                >
                  <img src={Auther3} alt="no content" />
                </AnimationOnScroll>
              </Grid>
              <Grid item md={6} xs={11} sx={{ mt: { md: 0, xs: 5 } }}>
                <AnimationOnScroll
                  animateOnce={true}
                  delay={500}
                  animateIn="animate__flipInX"
                >
                  50% of the participants didn’t realize that it is not
                  necessary to select the entire paragraph (as the flag arm
                  depicts complete paragraph selection.)
                </AnimationOnScroll>
              </Grid>
            </Grid>
            <Box
              sx={{
                position: "absolute",
                display: { md: "block", xs: "none" },
                top: { lg: 160, md: 160 },
                left: "53%",
                transform: "rotateX(30deg)",
              }}
            >
              <AutherDraw2 />
            </Box>
            <Box
              className="lineAnimation"
              sx={{
                display: { md: "block", xs: "none" },
                position: "absolute",
                top: { lg: 160, md: 170 },
                left: "53%",
                width: "305px",
                height: "145px",
                background: "#fff",
              }}
            ></Box>
          </Box>
          <Box
            sx={{
              fontSize: { md: "22px", sm: "18px" },
              position: "relative",
              fontFamily: "Caveat",
              color: "#000",
              marginTop: 12,
              ".lineAnimation.active": {
                animation: "pencil2 1.5s forwards !important",
                animationDelay: "1s",
              },
            }}
          >
            <Grid
              container
              sx={{
                mt: 5.25,
                flexDirection: { md: "row", xs: "column-reverse" },
                alignItems: { md: "flex-end", xs: "center" },
              }}
              columnSpacing={7}
            >
              <Grid item md={6} xs={11} justifyContent="left">
                <AnimationOnScroll
                  animateOnce={true}
                  delay={500}
                  animateIn="animate__flipInX"
                >
                  <Box sx={{ mt: 5 }}>
                    Boundary added around the paragraph to indicate paragraph
                    selection as per the grey flag
                  </Box>
                </AnimationOnScroll>
              </Grid>
              <Grid
                item
                md={6}
                xs={11}
                sx={{
                  "& img": {
                    width: { lg: "479.88px", xs: "100%" },
                  },
                }}
              >
                <AnimationOnScroll
                  animateOnce={true}
                  animateIn={"animate__fadeInRight"}
                >
                  <img src={Auther4} alt="no content" />
                </AnimationOnScroll>
              </Grid>
            </Grid>
            <Box
              sx={{
                position: "absolute",
                display: { md: "block", xs: "none" },
                top: { lg: 80, md: 45 },
                left: { lg: "27%", md: "25%" },
                transform: "rotate(180deg)",
              }}
            >
              <AutherDraw1 />
            </Box>
            <Box
              className="lineAnimation"
              sx={{
                display: { md: "block", xs: "none" },
                position: "absolute",
                top: { lg: 80, md: 45 },
                left: { lg: "27%", md: "25%" },
                width: "240px",
                height: "130px",
                background: "#fff",
              }}
            ></Box>
          </Box>
        </SectionFourWrapper>
        <SectionFourWrapper sx={{ mt: 8 }}>
          <Box
            sx={{
              fontSize: { md: "22px", sm: "18px" },
              position: "relative",
              fontFamily: "Caveat",
              color: "#000",
              display: "flex",
            }}
          >
            <Typography
              variant="h3"
              sx={{
                color: "#0021f5",
                fontSize: { md: "28px", xs: "22px" },
                fontWeight: 600,
                mt: 5,
              }}
            >
              2/3
            </Typography>
            <Grid
              container
              sx={{
                mt: 5.25,
                ml: -5,
                alignItems: { xl: "center", md: "flex-start" },
              }}
              columnSpacing={7}
            >
              <Grid
                item
                md={6}
                xs={11}
                sx={{
                  "& img": {
                    width: { lg: "450px", xs: "100%" },
                  },
                }}
              >
                <AnimationOnScroll
                  animateOnce={true}
                  animateIn={"animate__fadeInLeft"}
                >
                  <img src={Auther5} alt="no content" />
                </AnimationOnScroll>
              </Grid>
              <Grid item md={6} xs={11}>
                <AnimationOnScroll
                  animateOnce={true}
                  delay={500}
                  animateIn="animate__flipInX"
                >
                  <Box sx={{ mt: { md: -1, xs: 5 } }}>
                    -&gt;40% participants pointed out, "What's the difference
                    between Read & Write, and Read & Chat?" <br />
                    <br />
                    -&gt; Notifications, Collaboration, and Chat sections are
                    currently at 3 different places
                  </Box>
                </AnimationOnScroll>
              </Grid>
            </Grid>
            <Box
              sx={{
                position: "absolute",
                display: { md: "block", xs: "none" },
                top: { xl: 225, md: 200 },
                left: "54%",
                transform: "rotateX(18deg)",
              }}
            >
              <AutherDraw3 />
            </Box>
            <Box
              className="lineAnimation"
              sx={{
                display: { md: "block", xs: "none" },
                position: "absolute",
                top: { xl: 225, md: 200 },
                left: "54%",
                width: "305px",
                height: "145px",
                background: "#fff",
              }}
            ></Box>
          </Box>
          <Box
            sx={{
              position: "relative",
              "& *": {
                fontSize: { md: "22px", sm: "18px" },
                fontFamily: "Caveat",
                color: "#000",
              },
              marginTop: 8,
              ".lineAnimation.active": {
                animation: "pencil2 1.5s forwards !important",
                animationDelay: "1s",
              },
            }}
          >
            <Grid
              container
              sx={{
                mt: 5.25,
                flexDirection: { md: "row", xs: "column-reverse" },
                alignItems: { md: "flex-end", xs: "center" },
              }}
              columnSpacing={7}
            >
              <Grid item md={6} xs={11}>
                <Box
                  sx={{
                    width: { lg: "417px", xs: "100%" },
                  }}
                >
                  <AnimationOnScroll
                    animateOnce={true}
                    delay={500}
                    animateIn="animate__flipInX"
                  >
                    <Typography
                      sx={{
                        mt: { lg: 5, md: 10 },
                        fontFamily: "Caveat",
                        fontSize: { md: "22px", sm: "18px" },
                      }}
                    >
                      -&gt; 'Read and Chat' and 'Read and Write' options have
                      been renamed to 'View and Comment' and 'Edit and Comment'
                      respectively.
                      <br />
                      <br /> -&gt; Notification', 'Chat', and 'Invite'
                      (Collaboration) sections have been merged into a single
                      menu, as they all go hand-in-hand and work together.
                    </Typography>
                  </AnimationOnScroll>
                </Box>
              </Grid>

              <Grid
                item
                md={6}
                sx={{
                  "& img": {
                    width: { lg: "479.88px", xs: "100%" },
                  },
                }}
              >
                <AnimationOnScroll
                  animateOnce={true}
                  animateIn={"animate__fadeInRight"}
                >
                  <img src={Auther6} alt="no content" />
                </AnimationOnScroll>
              </Grid>
            </Grid>
            <Box
              sx={{
                position: "absolute",

                display: { md: "block", xs: "none" },
                top: 25,
                left: "28%",
              }}
            >
              <AutherDraw4 />
            </Box>
            <Box
              className="lineAnimation"
              sx={{
                display: { md: "block", xs: "none" },
                position: "absolute",
                top: 25,
                left: "28%",
                width: "230px",
                height: "42px",
                background: "#fff",
              }}
            ></Box>
          </Box>
        </SectionFourWrapper>
        <SectionFourWrapper sx={{ mt: 8 }}>
          <Box
            sx={{
              fontSize: { md: "22px", sm: "18px" },
              position: "relative",
              fontFamily: "Caveat",
              color: "#000",
              display: "flex",
            }}
          >
            <Typography
              variant="h3"
              sx={{
                color: "#0021f5",
                fontSize: { md: "28px", xs: "22px" },
                fontWeight: 600,
                mt: 5,
              }}
            >
              3/3
            </Typography>
            <Grid container sx={{ mt: 5.25, ml: -5 }} columnSpacing={7}>
              <Grid
                item
                md={6}
                xs={11}
                sx={{
                  "& img": {
                    width: { xs: "100%" },
                  },
                }}
              >
                <AnimationOnScroll
                  animateOnce={true}
                  animateIn={"animate__fadeInLeft"}
                >
                  <img src={Auther7} alt="no content" />
                </AnimationOnScroll>
              </Grid>

              <Grid item md={6} xs={11}>
                <AnimationOnScroll
                  animateOnce={true}
                  delay={500}
                  animateIn="animate__flipInX"
                >
                  <Box sx={{ mt: { md: 2, sm: 5 } }}>
                    Earlier, Citation was done through ‘Manage Content’ on the
                    side menu, and wasn’t intuitive to find and use, as per
                    insights from usability testing
                  </Box>
                </AnimationOnScroll>
              </Grid>
            </Grid>
            <Box
              sx={{
                position: "absolute",

                display: { md: "block", xs: "none" },
                top: 225,
                left: "53%",
                transform: "rotateX(180deg)",
              }}
            >
              <AutherDraw1 />
            </Box>
            <Box
              className="lineAnimation"
              sx={{
                display: { md: "block", xs: "none" },
                position: "absolute",
                top: 225,
                left: "53%",
                width: "305px",
                height: "145px",
                background: "#fff",
              }}
            ></Box>
          </Box>
          <Box
            sx={{
              position: "relative",
              "& *": {
                fontSize: { md: "22px", sm: "18px" },
                fontFamily: "Caveat",
                color: "#000",
              },
              marginTop: 8,
              ".lineAnimation.active": {
                animation: "pencil2 1.5s forwards !important",
                animationDelay: "1s",
              },
            }}
          >
            <Grid
              container
              sx={{
                mt: 5.25,
                flexDirection: { md: "row", xs: "column-reverse" },
                alignItems: { md: "flex-start", sm: "center" },
              }}
              columnSpacing={7}
            >
              <Grid item md={6} xs={11}>
                <Box
                  sx={{
                    width: { md: "417px", xs: "100%" },
                  }}
                >
                  <AnimationOnScroll
                    animateOnce={true}
                    delay={500}
                    animateIn="animate__flipInX"
                  >
                    <Typography
                      sx={{
                        mt: { md: 10, xs: 5 },
                        fontFamily: "caveat",
                        fontSize: { md: "22px", xs: "1rem" },
                      }}
                    >
                      'Manage Content', 'Add Asset', 'Add Reference', and 'Add
                      Citation' are all now clubbed into 'My Library', for
                      streamlined access.
                      <br />
                      Citation can now be made using a keyboard shortcut.
                      <br />
                      Library elements can now be filtered based on projects
                      ('All Assets', 'This project only', 'Other projects').
                      <br />
                    </Typography>
                  </AnimationOnScroll>
                </Box>
              </Grid>
              <Grid
                item
                md={6}
                xs={11}
                sx={{
                  "& img": {
                    width: { lg: "479.88px", xs: "100%" },
                  },
                }}
              >
                <AnimationOnScroll
                  animateOnce={true}
                  animateIn={"animate__fadeInRight"}
                >
                  <img src={Auther8} alt="no content" />
                </AnimationOnScroll>
              </Grid>
            </Grid>
            <Box
              sx={{
                position: "absolute",

                display: { md: "block", xs: "none" },
                top: 30,
                left: "30%",
              }}
            >
              <AutherDraw4 />
            </Box>
            <Box
              className="lineAnimation"
              sx={{
                display: { md: "block", xs: "none" },
                position: "absolute",
                top: 30,
                left: "30%",
                width: "230px",
                height: "42px",
                background: "#fff",
              }}
            ></Box>
          </Box>
        </SectionFourWrapper>
      </Box>
      <SectionSeventeenWrapper id="section4">
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: { md: "40px", sm: "32px", xs: "28px" },
              fontWeight: 700,
              fontFamily: "Mohave",
              fontStyle: "italic",
              width: "fit-content",
              // borderBottom: "4px solid",
            }}
          >
            IMPACT
          </Typography>
        </AnimationOnScroll>

        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
          style={{ width: { md: "453px", xs: "100%" } }}
        >
          <Typography sx={{ fontWeight: 700, fontSize: "60px" }}>
            80%
          </Typography>
          <Typography
            sx={{
              fontSize: { md: "28px", xs: "22px" },
              fontWeight: 600,
            }}
          >
            improvement in user satisfaction [Based on usability testing]
          </Typography>
        </AnimationOnScroll>

        <Box
          sx={{
            top: "20%",
            position: { lg: "absolute", xs: "relative" },
            right: 0,
            mt: { lg: 0, xs: 5 },
          }}
        >
          <Box
            sx={{
              width: { lg: "640px", xs: "100%" },
              px: 3,
              py: 3.5,
              background: (theme: Theme) => theme.palette.secondary.main,
            }}
          >
            <Typography
              sx={{ fontSize: "24px", fontWeight: 600, color: "#000" }}
            >
              “Everything seems so harmonious and fluid. No friction between my
              work and the tool!”
            </Typography>
          </Box>
          <Box
            sx={{
              width: { lg: "640px", xs: "100%" },
              pl: 3,
              pr: 1,
              py: 1.5,
              background: "#000",
            }}
          >
            <Typography
              sx={{
                fontSize: { md: "20px", xs: "16px" },
                fontWeight: 400,
                color: (theme: Theme) => theme.palette.secondary.main,
              }}
            >
              -Name is confidential, Research Student, IIT Madras
            </Typography>
          </Box>
          <Box sx={{ mr: 6, mt: -1, textAlign: "right" }}>
            <ToolTipIcons />
          </Box>
          <Box sx={{ mt: 3, textAlign: "right" }}>
            <img src={User2} alt="no user" />
          </Box>
        </Box>
        <Box
          sx={{
            top: "54%",
            mt: { lg: 3, xs: 5 },
            position: { lg: "absolute", xs: "relative" },
          }}
        >
          <Box
            sx={{
              width: { lg: "640px", xs: "100%" },
              px: 3,
              py: 3.5,
              background: (theme: Theme) => theme.palette.secondary.main,
            }}
          >
            <Typography
              sx={{ fontSize: "24px", fontWeight: 600, color: "#000" }}
            >
              “I love how I can see my entire library with references and
              citations at the same place, really makes it easy for me to upload
              or cite”
            </Typography>
          </Box>
          <Box
            sx={{
              width: { lg: "640px", xs: "100%" },
              pl: 3,
              pr: 1,
              py: 1.5,
              background: "#000",
            }}
          >
            <Typography
              sx={{
                fontSize: { md: "20px", xs: "16px" },
                fontWeight: 400,
                color: (theme: Theme) => theme.palette.secondary.main,
              }}
            >
              -Name is confidential, Food Researcher, Nestle
            </Typography>
          </Box>
          <Box sx={{ ml: 6, mt: -1 }}>
            <ToolTipIcons />
          </Box>
          <Box sx={{ mt: 3 }}>
            <img src={User1} alt="no user" />
          </Box>
        </Box>
      </SectionSeventeenWrapper>
      <Box sx={{ overflowX: "hidden" }}>
        <SectionEighteenWrapper
          sx={{
            "& div": {
              overflowX: "visible !important",
              zIndex: 0,
            },
            "&>div:hover": {
              zIndex: 1,
            },
          }}
        >
          <Box
            onMouseEnter={() => setIsPlaying(false)}
            onMouseLeave={() => setIsPlaying(true)}
            sx={{ my: 3 }}
          >
            <Marquee play={isPlaying} gradient={false} speed={50}>
              <img src={AutherLast1} alt="No Info" />

              <img src={AutherLast2} alt="No Info" />

              <img src={AutherLast3} alt="No Info" />

              <img src={AutherLast4} alt="No Info" />
            </Marquee>
          </Box>
          <Box
            onMouseEnter={() => setIsPlaying(false)}
            onMouseLeave={() => setIsPlaying(true)}
            sx={{ my: 3 }}
          >
            <Marquee
              play={isPlaying}
              gradient={false}
              speed={50}
              direction="right"
            >
              <img src={AutherLast5} alt="No Info" />

              <img src={AutherLast6} alt="No Info" />

              <img src={AutherLast7} alt="No Info" />

              <img src={AutherLast8} alt="No Info" />
            </Marquee>
          </Box>
        </SectionEighteenWrapper>
      </Box>
      <Box
        sx={{
          position: "fixed",
          width: "1px",
          height: "1px",
          background: (theme: Theme) => theme.palette.primary.main,
          top: 0,
          zIndex: 999999999999,
          left: 0,
          borderRadius: "100%",
          animation: "animateCircle1 0.5s",
          animationIterationCount: 1,
          "@keyframes animateCircle1": {
            from: {
              transform: "scale(4000)",
            },
            to: { transform: "scale(0)" },
          },
        }}
      ></Box>
    </ProjectWrapper>
  );
};

export default withAuth(Project3);
