import React from "react";

const Lines = () => {
  return (
    <svg
      width="65"
      height="18"
      viewBox="0 0 65 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 8.81055L64 8.6084"
        stroke="#ADFF00"
        stroke-width="2"
        stroke-linecap="round"
        stroke-dasharray="6 6"
      />
    </svg>
  );
};

export default Lines;
