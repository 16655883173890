import React from "react";

const Bulb2Icon = () => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.00736 18.5996C5.44485 18.5996 5.79983 18.9546 5.79983 19.3995C5.79983 19.8413 5.43913 20.1995 5.00736 20.1995H1.79248C1.35499 20.1995 1 19.8445 1 19.3995C1 18.9578 1.3607 18.5996 1.79248 18.5996H5.00736ZM36.2063 18.5996C36.6438 18.5996 36.9987 18.9546 36.9987 19.3995C36.9987 19.8413 36.6381 20.1995 36.2063 20.1995H32.9914C32.5539 20.1995 32.1989 19.8445 32.1989 19.3995C32.1989 18.9578 32.5596 18.5996 32.9914 18.5996H36.2063ZM20.1995 5.00736C20.1995 5.44485 19.8445 5.79983 19.3995 5.79983C18.9578 5.79983 18.5996 5.43913 18.5996 5.00736V1.79248C18.5996 1.35499 18.9546 1 19.3995 1C19.8413 1 20.1995 1.3607 20.1995 1.79248V5.00736ZM9.12607 8.79401C9.4357 9.10365 9.4357 9.60577 9.12107 9.92037C8.80858 10.2325 8.30003 10.2307 7.99471 9.92537L5.72197 7.65227C5.41234 7.34299 5.41234 6.84087 5.72697 6.52627C6.03946 6.21378 6.54766 6.21593 6.85296 6.52127L9.12607 8.79401ZM30.8049 9.9254C30.4952 10.235 29.9931 10.235 29.6785 9.9204C29.3664 9.60791 29.3682 9.09936 29.6735 8.79404L31.9466 6.5213C32.2559 6.21167 32.758 6.21167 33.0726 6.5263C33.3851 6.83879 33.383 7.34699 33.0776 7.65229L30.8049 9.9254Z"
        fill="black"
        stroke="black"
      />
      <path
        d="M21.7996 26.9211C25.062 25.768 27.3994 22.6566 27.3994 18.9993C27.3994 14.3602 23.6388 10.5996 18.9997 10.5996C14.3605 10.5996 10.5999 14.3602 10.5999 18.9993C10.5999 22.6567 12.9373 25.768 16.1997 26.9211V35.3987H21.7996V26.9211ZM9 19.3993C9 13.4766 13.477 8.99963 19.3996 8.99963C24.5223 8.99963 28.9993 13.4766 28.9993 19.3993C28.9993 22.9106 26.7326 26.4012 23.3995 28.199V35.3987C23.3995 36.2737 22.6281 36.9987 21.8206 36.9987H16.1997C15.4723 36.9987 14.5998 36.2248 14.5998 35.3987V28.199C11.2667 26.4012 9 22.9106 9 19.3993ZM16.1997 28.9989H21.7996V30.5989H16.1997V28.9989ZM16.1997 32.1988H21.7996V33.7988H16.1997V32.1988Z"
        fill="black"
        stroke="black"
      />
    </svg>
  );
};

export default Bulb2Icon;
