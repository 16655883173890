import { createTheme } from "@mui/material";
const theme = createTheme({
  palette: {
    common: {
      black: "#000",
      white: "#fff",
    },
    primary: {
      main: "#0021F5",
      dark: "#000000",
      light: "#494949",
      contrastText: "#fff",
    },
    secondary: {
      main: "#ADFF00",
      dark: "#bd8800",
      light: "#ffe95a",
      contrastText: "#0021F5",
    },
    info: {
      main: "#2959b3",
    },
    text: {
      primary: "#0021F5",
      secondary: "#f4b721",
    },
  },
  typography: {
    allVariants: {
      fontFamily: "Inter",
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          background: "transparent",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});

export default theme;
