import React, { useRef, useEffect, useState } from "react";
import {
  ProjectWrapper,
  SectionEighteenWrapper,
  SectionEightWrapper,
  SectionElevanWrapper,
  SectionFifteenWrapper,
  SectionFiveWrapper,
  SectionFourteenWrapper,
  SectionFourWrapper,
  SectionNineWrapper,
  SectionOneWrapper,
  SectionSeventeenWrapper,
  SectionSevenWrapper,
  SectionSixteenWrapper,
  SectionSixWrapper,
  SectionTenWrapper,
  SectionThirteenWrapper,
  SectionThreeWrapper,
  SectionTwelveWrapper,
  SectionTwoWrapper,
  SliderTracker,
} from "./styled";

import { Box, Grid, Theme, Typography } from "@mui/material";
import Pharma1 from "../../Assets/images/pharma1.png";
import Pharma2 from "../../Assets/images/pharma2.png";
import Pharma3 from "../../Assets/images/pharma3.png";

import PharmaInfo from "../../Assets/images/PharmaInfo.png";
import PharmaIdea from "../../Assets/images/pharmaIdea.png";
import Sketch1 from "../../Assets/images/sketch1.png";
import Sketch2 from "../../Assets/images/sketch2.png";
import Slider1 from "../../Assets/images/Slider1.png";
import Slider2 from "../../Assets/images/Slider2.png";
import Slider3 from "../../Assets/images/Slider3.png";
import Slider4 from "../../Assets/images/Slider4.png";
import LastSlider1 from "../../Assets/images/LastSlider1.png";
import LastSlider2 from "../../Assets/images/LastSlider2.png";
import LastSlider3 from "../../Assets/images/LastSlider3.png";
import LastSlider4 from "../../Assets/images/LastSlider4.png";
import User1 from "../../Assets/images/user1.png";
import User2 from "../../Assets/images/user2.png";
import Image1 from "../../Assets/images/Project1last/Image1.png";
import Image2 from "../../Assets/images/Project1last/Image2.png";
import Image3 from "../../Assets/images/Project1last/Image3.png";
import Image4 from "../../Assets/images/Project1last/Image4.png";
import Image5 from "../../Assets/images/Project1last/Image5.png";
import Image6 from "../../Assets/images/Project1last/Image6.png";
import Image7 from "../../Assets/images/Project1last/Image7.png";
import Image8 from "../../Assets/images/Project1last/Image8.png";
import Image9 from "../../Assets/images/Project1last/Image9.png";
import Image10 from "../../Assets/images/Project1last/Image10.png";
import Image11 from "../../Assets/images/Project1last/Image11.png";
import Image12 from "../../Assets/images/Project1last/Image12.png";
import Image13 from "../../Assets/images/Project1last/Image13.png";
import Old1 from "../../Assets/images/old1.png";
import Old2 from "../../Assets/images/old2.png";
import Old3 from "../../Assets/images/old3.png";
import Old4 from "../../Assets/images/old4.png";
import Old5 from "../../Assets/images/old5.png";

import Figma from "../../Assets/images/Figma.png";
import Pharma1GIF from "../../Assets/images/Pharma1GIF.gif";
import Pharma2GIF from "../../Assets/images/Pharma2GIF.gif";
import Pharma3GIF from "../../Assets/images/Pharma3GIF.gif";

import { AnimationOnScroll } from "react-animation-on-scroll";
import BulbIcon from "../../Assets/Icons/BulbIcon";
import HandDownIcon from "../../Assets/Icons/HandDownIcon";
import { ChevronLeft, ChevronRight, East } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import Slider from "react-slick";
import SmileIcon from "../../Assets/Icons/SmileIcon";
import LBulbIcon from "../../Assets/Icons/LBulbIcon";
import RoundText from "../../Assets/Icons/RoundText";
import DrawLine from "../../Assets/Icons/DrawLine";
import debounce from "../../Helper/debounce";
import Line1 from "../../Assets/Icons/Line1";
import Line2 from "../../Assets/Icons/Line2";
import { scrollTrigger } from "../../Helper/scrollAnimation";
import LongLine from "../../Assets/Icons/LongLine";
import ToolTipIcons from "../../Assets/Icons/ToolTipIcons";
import Marquee from "react-fast-marquee";
import CircleIcon from "../../Assets/Icons/CircleIcon";
import TriangleIcon from "../../Assets/Icons/TriangleIcon";
import SqaureIcon from "../../Assets/Icons/SqaureIcon";
import SadIcon from "../../Assets/Icons/SadIcon";
import Prisma from "../../Assets/Icons/Prisma";
import withAuth from "../../HOC/withAuth";

const ItrationText1 = [
  "“I love that the KPIs are on the homescreen”",
  "“’Last modified by’ should’ve been on the dashboard”",
  "“How do I know that data has been added after I submit?”",
  "“I’m not sure which period am I entering this data for”",
  "“Data completeness for my role should be upfront”",
];
const ItrationText2 = [
  "After having various discussions with the stakeholders, we realised that this version of the dashboard was flawed.",
  "It did not take into account the way data is entered for various KPIs (this one just has one KPI per region, while there could be multiple KPIs for the same region).",
  "We also realised, Comments are not something that the users want to see/would benefit from seeing all the time. Hence, giving that much real estate to it on the Dashboard wasn’t viable.",
];
const ItrationText3 = [
  "“Only 2-3 people have the authority to use Freeze” (which meant that prominence could be reduced on the Dashboard, and something more important can take its place)",
  "“We do not ever reply to comments”",
];
const ItrationText4 = [
  "“The table looks a bit cluttered” (Opportunity for us to figure out if the columns can be modified)",
  "Observation - Most users could not figure out that the ‘Completed’/’Pending’/’High’/’Medium’ etc. part is clickable, and acts as a filter",
];

const Project = () => {
  const { pathname } = useLocation();
  const IterationSection = useRef<any>();
  const [isPlaying, setIsPlaying] = useState(true);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentSlideFromLastSlider, setCurrentSlideFromLastSlider] = useState({
    currentIndex: 0,
    prevIndex: 0,
  });
  const maxLength = useRef<number>(0);
  const maxLengthLastSlider = useRef<number>(0);
  const debounceTest = useRef<any>();
  const debounceTestLastSlider = useRef<any>();
  const SliderRef = useRef<any>();
  const SliderParentRef = useRef<any>();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    SliderParentRef.current.addEventListener("wheel", (e: any) => {
      const delta = e.wheelDelta / 60;
      if (window.innerWidth > 1024) {
        if (delta < 0) {
          if (maxLength.current !== 3) {
            e.preventDefault();
            window.scrollTo(0, SliderParentRef.current.offsetTop - 50);
            debounceTest.current(delta);
          }
        } else if (delta > 0) {
          if (maxLength.current !== 0) {
            e.preventDefault();
            window.scrollTo(0, SliderParentRef.current.offsetTop - 50);
            debounceTest.current(delta);
          }
        }
      }
    });

    IterationSection.current.addEventListener("wheel", (e: any) => {
      const delta = e.wheelDelta / 60;
      if (window.innerWidth > 1024) {
        if (delta < 0) {
          if (maxLengthLastSlider.current !== 4) {
            e.preventDefault();
            window.scrollTo(0, IterationSection.current.offsetTop - 175);
            debounceTestLastSlider.current(delta);
          }
        } else if (delta > 0) {
          if (maxLengthLastSlider.current !== 0) {
            e.preventDefault();
            window.scrollTo(0, IterationSection.current.offsetTop - 175);
            debounceTestLastSlider.current(delta);
          }
        }
      }
    });

    window.addEventListener("scroll", (e) => {
      scrollTrigger(".lineAnimation");
    });
  }, []);

  const changeCurrentSlide = (deltaY: number) => {
    if (deltaY < 0) {
      if (currentSlide < 2) {
        setCurrentSlide(currentSlide + 1);
        if (SliderRef.current) {
          SliderRef.current.slickNext();
          maxLength.current = currentSlide + 1;
        }
      } else {
        setCurrentSlide(3);
        SliderRef.current.slickNext();
        maxLength.current = 3;
      }
    } else if (deltaY > 0) {
      if (currentSlide > 1) {
        setCurrentSlide(currentSlide - 1);
        if (SliderRef.current) {
          SliderRef.current.slickPrev();
          maxLength.current = currentSlide - 1;
        }
      } else {
        setCurrentSlide(0);
        SliderRef.current.slickPrev();
        maxLength.current = 0;
      }
    }
  };

  const changeLastSlider = (deltaY: number) => {
    if (deltaY < 0) {
      if (currentSlideFromLastSlider.currentIndex < 3) {
        setCurrentSlideFromLastSlider({
          prevIndex: currentSlideFromLastSlider.currentIndex,
          currentIndex: currentSlideFromLastSlider.currentIndex + 1,
        });
        maxLengthLastSlider.current =
          currentSlideFromLastSlider.currentIndex + 1;
      } else {
        setCurrentSlideFromLastSlider({
          prevIndex: currentSlideFromLastSlider.currentIndex,
          currentIndex: currentSlideFromLastSlider.currentIndex + 1,
        });
        maxLengthLastSlider.current = 4;
      }
    } else if (deltaY > 0) {
      if (currentSlideFromLastSlider.currentIndex > 1) {
        setCurrentSlideFromLastSlider({
          prevIndex: currentSlideFromLastSlider.currentIndex,
          currentIndex: currentSlideFromLastSlider.currentIndex - 1,
        });
        maxLengthLastSlider.current =
          currentSlideFromLastSlider.currentIndex - 1;
      } else {
        setCurrentSlideFromLastSlider({
          prevIndex: currentSlideFromLastSlider.currentIndex,
          currentIndex: currentSlideFromLastSlider.currentIndex - 1,
        });
        maxLengthLastSlider.current = 0;
      }
    }
  };

  debounceTestLastSlider.current = debounce(changeLastSlider, 250);
  debounceTest.current = debounce(changeCurrentSlide, 250);

  return (
    <ProjectWrapper>
      <Box
        sx={{
          position: "fixed",
          width: "1px",
          height: "1px",
          background: (theme: Theme) => theme.palette.primary.main,
          top: 0,
          zIndex: 999999999999,
          left: 0,
          borderRadius: "100%",
          animation: "animateCircle1 0.5s",
          animationIterationCount: 1,
          "@keyframes animateCircle1": {
            from: {
              transform: "scale(4000)",
            },
            to: { transform: "scale(0)" },
          },
        }}
      ></Box>
      <SectionOneWrapper>
        <Typography variant="h1">AMERICAN PHARMA GIANT</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { sm: "row", xs: "column" },
            "& p": {
              fontWeight: 500,
              fontSize: { md: "28px", xs: "22px" },
            },
          }}
        >
          <Typography>ENTERPRISE SUPPLY CHAIN</Typography>
          <Typography sx={{ ml: { sm: 6 } }}>3 MONTHS</Typography>
        </Box>
      </SectionOneWrapper>
      <SectionTwoWrapper>
        <ParallaxProvider>
          <Parallax
            // speed={-10}
            translateX={["17", "-20"]}
            easing="easeOutQuad"
            endScroll={100}
          >
            <Box
              sx={{
                display: "flex",
                "& img": {
                  width: { lg: "832px", md: "700px", sm: "600px", xs: "402px" },
                  height: "auto",
                  boxShadow: "4px 0px 20px 0px #66666640",
                  borderRadius: "12px",
                },
                "& div": {
                  mx: 1.75,
                },
              }}
            >
              <Box>
                <img src={Pharma1} alt="No Banner" />
              </Box>
              <Box>
                <img src={Pharma2} alt="No Banner" />
              </Box>
            </Box>
          </Parallax>
        </ParallaxProvider>
      </SectionTwoWrapper>
      <SectionThreeWrapper id="section1">
        <Box>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
          >
            <Typography
              variant="h3"
              sx={{ fontSize: { md: "28px", xs: "22px" }, fontWeight: 600 }}
            >
              What is this about?
            </Typography>
          </AnimationOnScroll>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
            delay={300}
          >
            <Typography sx={{ fontSize: { md: "22px", sm: "18px" }, mt: 2.5 }}>
              This is the redesign of an application named Prism. In a typical
              supply chain architecture, there are various steps that need to be
              executed in order to process all aspects of the supply chain. In
              that entire process, Prism is a metrics application with data
              entry, data management, and reporting capabilities (Basically,
              takes care of Data Entry and Performance Measurement of KPIs).
            </Typography>
          </AnimationOnScroll>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
            delay={300}
          >
            <Box
              sx={{
                mt: 4,
                "& svg": {
                  width: "100%",
                },
              }}
            >
              <Prisma />
            </Box>
          </AnimationOnScroll>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
            delay={300}
          >
            <Box
              sx={{
                py: 3.5,
                px: 4,
                mt: 8,
                background: (theme: Theme) => theme.palette.secondary.main,
              }}
            >
              <Typography
                sx={{
                  fontSize: { md: "28px", xs: "22px" },
                  fontWeight: 600,
                  lineHeight: "33.89px",
                }}
              >
                There are 2 personas using Prism
                <br />
                <br /> Data Owners - Responsible for entering data <br />
                Data Leads - Mostly responsible for ensuring timely data entry,
                but also occassionaly enter data
              </Typography>
            </Box>
          </AnimationOnScroll>
        </Box>
        <Box sx={{ mt: 10 }}>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
          >
            <Typography
              variant="h3"
              sx={{ fontSize: { md: "28px", xs: "22px" }, fontWeight: 600 }}
            >
              Problem Statement/Description
            </Typography>
          </AnimationOnScroll>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
            delay={300}
          >
            <Typography sx={{ fontSize: { md: "22px", sm: "18px" }, mt: 2.5 }}>
              The only description provided was{" "}
              <b>“Improve the user experience of Prism”</b>. Since it was a
              broad statement to think about, we buckled up, and began looking
              out for the information we needed in order to begin thinking about
              improving Prism’s user experience.
            </Typography>
          </AnimationOnScroll>
        </Box>
        <Box sx={{ mt: 10 }} id="section2">
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
          >
            <Typography
              variant="h3"
              sx={{
                fontSize: { md: "40px", sm: "32px", xs: "28px" },
                fontWeight: 700,
                fontFamily: "Mohave",
                fontStyle: "italic",
              }}
            >
              MY ROLE
            </Typography>
          </AnimationOnScroll>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
          >
            <Box sx={{ mt: 1.5, position: "relative" }}>
              <Box
                sx={{
                  position: "absolute",
                  left: "14%",
                  top: "21%",
                  zIndex: -1,
                }}
              >
                <CircleIcon />
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  left: "51%",
                  top: "-20%",
                  zIndex: -1,
                }}
              >
                <TriangleIcon />
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  right: "6%",
                  top: "21%",
                  zIndex: -1,
                }}
              >
                <SqaureIcon />
              </Box>
              <Typography
                variant="h3"
                sx={{ fontSize: { md: "28px", xs: "22px" }, fontWeight: 600 }}
              >
                User Interviews -&gt; Identifying problems -&gt; Designing
                solutions (with iterative user feedback)
              </Typography>
            </Box>
          </AnimationOnScroll>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
            delay={300}
          >
            <Box
              sx={{
                py: 3.5,
                px: 4,
                mt: 9,
                background: (theme: Theme) => theme.palette.secondary.main,
              }}
            >
              <Typography
                sx={{
                  fontSize: { md: "28px", xs: "22px" },
                  fontWeight: 600,
                  lineHeight: "33.89px",
                }}
              >
                To gather the information we needed about Prism, before we could
                proceed,{" "}
                <Typography
                  component="span"
                  sx={{
                    fontSize: { md: "28px", xs: "22px" },
                    fontWeight: 600,
                    lineHeight: "33.89px",
                    textDecorationLine: "underline",
                    position: "relative",
                    "&:before": {
                      display: "none",
                      content:
                        "'Alongside me, 1 UX Designer, 1 PM, and 2 Visual Designers'",
                      height: "auto",
                      position: "absolute",
                      top: "100%",
                      left: { sm: 0, xs: 0 },
                      p: { md: 3, xs: 1.5 },
                      background: "#000000",
                      color: (theme: Theme) => theme.palette.secondary.main,
                      maxWidth: "450px",
                      minWidth: "450px",
                      fontSize: { md: "22px", xs: "18px" },
                      fontWeight: 600,
                      zIndex: 1,
                    },
                    "&:hover": {
                      cursor: "pointer",
                      "&:before": {
                        display: { md: "block", xs: "none" },
                      },
                    },
                  }}
                >
                  we
                </Typography>{" "}
                conducted a total of 4 unique user interviews. Out of those, 2
                were with{" "}
                <Typography
                  component="span"
                  sx={{
                    fontSize: { md: "28px", xs: "22px" },
                    fontWeight: 600,
                    lineHeight: "33.89px",
                    textDecorationLine: "underline",
                    position: "relative",
                    "&:before": {
                      display: "none",
                      content: "'Responsible for entering data'",
                      height: "auto",
                      position: "absolute",
                      top: "100%",
                      left: 0,
                      whiteSpace: "nowrap",
                      p: { md: 3, xs: 1.5 },
                      background: "#000000",
                      color: (theme: Theme) => theme.palette.secondary.main,
                      maxWidth: "450px",
                      fontSize: { md: "22px", xs: "18px" },
                      fontWeight: 600,
                    },
                    "&:hover": {
                      cursor: "pointer",
                      "&:before": {
                        display: { md: "block", xs: "none" },
                      },
                    },
                  }}
                >
                  Data Owners
                </Typography>{" "}
                , while the other 2 were with{" "}
                <Typography
                  component="span"
                  sx={{
                    fontSize: { md: "28px", xs: "22px" },
                    fontWeight: 600,
                    lineHeight: "33.89px",
                    textDecorationLine: "underline",
                    position: "relative",
                    "&:before": {
                      display: "none",
                      content:
                        "'Responsible for ensuring timely data entry by Data Owners'",
                      height: "auto",
                      position: "absolute",
                      top: "100%",
                      left: 0,
                      p: { md: 3, xs: 1.5 },
                      background: "#000000",
                      color: (theme: Theme) => theme.palette.secondary.main,
                      maxWidth: "450px",
                      minWidth: "450px",
                      fontSize: { md: "22px", xs: "18px" },
                      fontWeight: 600,
                    },
                    "&:hover": {
                      cursor: "pointer",
                      "&:before": {
                        display: { md: "block", xs: "none" },
                      },
                    },
                  }}
                >
                  Data Leads.
                </Typography>{" "}
                [To understand their way of working, goals, pain points, and
                much more!]
              </Typography>
            </Box>
          </AnimationOnScroll>
        </Box>
      </SectionThreeWrapper>
      <SectionFourWrapper>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Box
            sx={{
              background: (theme: Theme) => theme.palette.primary.main,
              p: 1.5,
              width: "fit-content",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <BulbIcon />
              <Typography
                sx={{
                  color: "#fff !important",
                  fontWeight: 700,
                  fontSize: "18px",
                  ml: 1,
                }}
              >
                HOW WE STRUCTURED THE INTERVIEWS
              </Typography>
            </Box>
          </Box>
        </AnimationOnScroll>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Box sx={{ mt: 1.5 }}>
            <Typography
              variant="h3"
              sx={{
                fontSize: { md: "28px", xs: "22px" },
                fontWeight: 600,
                mt: 5.2,
              }}
            >
              Formulating script -&gt; Conducting interviews -&gt; Synthesizing
              information
            </Typography>
          </Box>
        </AnimationOnScroll>
        <Box sx={{ mt: 10 }}>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
          >
            <Typography
              variant="h3"
              sx={{
                fontSize: { md: "40px", sm: "32px", xs: "28px" },
                fontWeight: 700,
                fontFamily: "Mohave",
                fontStyle: "italic",
              }}
            >
              SCRIPT
            </Typography>
          </AnimationOnScroll>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
          >
            <Box sx={{ mt: 2 }}>
              <Typography
                variant="h3"
                sx={{ fontSize: { md: "28px", xs: "22px" }, fontWeight: 600 }}
              >
                Wanted to get an understanding of their day-to-day activities,
                and their experience with Prism
              </Typography>
            </Box>
          </AnimationOnScroll>
        </Box>
        <Box
          sx={{
            mt: 4,
            "& p": {
              fontSize: { md: "22px", sm: "18px" },
            },
          }}
        >
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
          >
            <Typography>
              <b>Ice-breakers - Introduction</b>
              <br />
              Tell us about you, your role
              <br />
              What does a typical day look like for you?
              <br />
              What do you use Prism for? (Your goal)
              <br /> How often do you use it?
              <br /> What are the key KPIs you work on?
              <br /> Could you tell us more about your experience working on
              Prism?
            </Typography>
          </AnimationOnScroll>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
          >
            <Typography sx={{ mt: 8 }}>
              <b>Tasks</b> <br />
              Could you show us how you usually add data?{" "}
              <b> [Main use-case of Prism] </b>
              <br />
              While they're performing the task, we could ask the number of KPIs
              they enter data for Depending on what they choose - Manual or Bulk
              Upload, ask further questions on what made them choose that
            </Typography>
          </AnimationOnScroll>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
          >
            <Typography sx={{ mt: 8 }}>
              <b>Post-task</b> <br /> Once data entry has been made - Ask how
              would they modify the entered and submitted values
              <br /> What could have been easier about this task?
            </Typography>
          </AnimationOnScroll>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
          >
            <Typography sx={{ mt: 8 }}>
              <b>Outro</b>
              <br /> Key insights
              <br /> Anything else you wish to add or let us know about your
              overall experience with Prism?
            </Typography>
          </AnimationOnScroll>
        </Box>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Box sx={{ mt: 4.5 }}>
            <Typography
              variant="h3"
              sx={{ fontSize: { md: "28px", xs: "22px" }, fontWeight: 600 }}
            >
              *Structure changed as & when needed during the interview
            </Typography>
          </Box>
        </AnimationOnScroll>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
          delay={300}
        >
          <Box
            sx={{
              py: 3.5,
              px: 4,
              mt: 6,
              background: (theme: Theme) => theme.palette.secondary.main,
            }}
          >
            <Typography
              sx={{
                fontSize: { md: "28px", xs: "22px" },
                fontWeight: 600,
                lineHeight: "33.89px",
              }}
            >
              The fun part - I created this script with the idea that we’ll be
              interviewing the users 1-1. However, due to time and availability
              constraints, we got to interview a group of users (Data Owners &
              Data Leads) instead. It turned out to be a boon for us, since we
              got a myriad of insights from their discussions among each other.
            </Typography>
          </Box>
        </AnimationOnScroll>
      </SectionFourWrapper>
      <SectionFiveWrapper>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: { md: "40px", sm: "32px", xs: "28px" },
              fontWeight: 700,
              fontFamily: "Mohave",
              fontStyle: "italic",
            }}
          >
            SYNTHESIZING INFORMATION
          </Typography>
        </AnimationOnScroll>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Box
            sx={{
              py: 1.5,
              width: "fit-content",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <HandDownIcon />
              <Typography
                sx={{
                  fontWeight: 600,
                  color: "#0021F5 !important",
                  fontSize: "18px",
                  ml: 1,
                }}
              >
                This was synthesized from the interview transcripts
              </Typography>
            </Box>
          </Box>
        </AnimationOnScroll>
        <Box
          sx={{
            mt: 4.5,
            "& img": {
              width: "100%",
            },
          }}
        >
          <img src={PharmaInfo} alt="No Info" />
        </Box>
      </SectionFiveWrapper>
      <SectionSixWrapper>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: { md: "40px", sm: "32px", xs: "28px" },
              fontWeight: 700,
              ml: { md: 7.5, sm: 5, xs: 2 },
              fontFamily: "Mohave",
              fontStyle: "italic",
            }}
          >
            OLD APPLICATION SCREENS
          </Typography>
        </AnimationOnScroll>

        <Box
          sx={{
            ml: 3,
            mt: 3,
            "& p": {
              px: { md: 3, xs: 1.5 },
              py: { md: 2, xs: 1 },
              position: "absolute",
              bottom: 0,
              left: "0",
              fontSize: { md: "20px", xs: "14px" },
              zIndex: 1,
              background: (theme: Theme) => theme.palette.secondary.main,
              fontWeight: 600,
            },
            "& img": {
              boxShadow: "4px 0px 20px 0px #66666640",
              borderRadius: "12px",
              width: "auto",
              maxHeight: { lg: "470px", md: "300px", sm: "250px", xs: "150px" },
            },
          }}
        >
          <Slider
            {...{
              infinite: true,
              nextArrow: <ChevronRight color="primary" />,
              prevArrow: <ChevronLeft color="primary" />,
              arrows: true,
              adaptiveHeight: true,
              variableWidth: true,
              draggable: true,
              speed: 500,
              autoplay: true,
              slidesToShow: 1,
              slidesToScroll: 1,
            }}
          >
            <Box sx={{ position: "relative", mx: 1.5 }}>
              <img src={Old1} alt="No Info" />
              <Typography sx={{ borderBottomLeftRadius: "12px" }}>
                Data Entry (tiny pencil icon)
              </Typography>
            </Box>

            <Box sx={{ position: "relative", mx: 1.5 }}>
              <img src={Old2} alt="No Info" />
              <Typography sx={{ borderBottomLeftRadius: "12px" }}>
                Navigation
              </Typography>
            </Box>

            <Box sx={{ position: "relative", mx: 1.5 }}>
              <img src={Old3} alt="No Info" />
              <Typography sx={{ borderBottomLeftRadius: "12px" }}>
                Bulk Upload
              </Typography>
            </Box>

            <Box sx={{ position: "relative", mx: 1.5 }}>
              <img src={Old4} alt="No Info" />
              <Typography sx={{ borderBottomLeftRadius: "12px" }}>
                Scorecard
              </Typography>
            </Box>

            <Box sx={{ position: "relative", mx: 1.5 }}>
              <img src={Old5} alt="No Info" />
              <Typography sx={{ borderBottomLeftRadius: "12px" }}>
                KPI Overview
              </Typography>
            </Box>
          </Slider>
        </Box>
      </SectionSixWrapper>
      <SectionSevenWrapper>
        <Typography
          variant="h2"
          sx={{
            fontSize: { md: "28px", xs: "22px" },
            fontWeight: 600,
            lineHeight: "33.89px",
          }}
        >
          Uncovering Problems - Insights Captured from the interviews
        </Typography>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Box
            sx={{
              background: (theme: Theme) => theme.palette.secondary.main,
              px: 1.5,
              py: 1,
              mt: 4,
              width: "fit-content",
            }}
          >
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "19.36px !important",
              }}
            >
              DATA OWNERS
            </Typography>
          </Box>
        </AnimationOnScroll>
        <Box>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
            delay={300}
          >
            <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
              <East color="error" />
              <Typography
                sx={{ ml: 1, fontSize: { md: "22px", sm: "18px", xs: "16px" } }}
              >
                “Takes me <b>too much time to upload data for multiple KPIs”</b>{" "}
                [Main application use-case]
              </Typography>
            </Box>
          </AnimationOnScroll>

          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
            delay={300}
          >
            <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
              <East color="error" />
              <Typography
                sx={{ ml: 1, fontSize: { md: "22px", sm: "18px", xs: "16px" } }}
              >
                “Since there’s <b>no restriction,</b> sometimes my{" "}
                <b>peers overwrite my uploaded data</b> by mistake, then I have
                to go back and upload my data again”
              </Typography>
            </Box>
          </AnimationOnScroll>

          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
            delay={300}
          >
            <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
              <East color="error" />
              <Typography
                sx={{ ml: 1, fontSize: { md: "22px", sm: "18px", xs: "16px" } }}
              >
                “I need to go to KPI Overview to confirm if data has been
                updated since there’s{" "}
                <b>no confirmation after successful data updation”</b>
              </Typography>
            </Box>
          </AnimationOnScroll>

          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
            delay={300}
          >
            <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
              <East color="error" />
              <Typography
                sx={{ ml: 1, fontSize: { md: "22px", sm: "18px", xs: "16px" } }}
              >
                “Icons throught the application have poor accessibility”
              </Typography>
            </Box>
          </AnimationOnScroll>

          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
            delay={300}
          >
            <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
              <East color="error" />
              <Typography
                sx={{ ml: 1, fontSize: { md: "22px", sm: "18px", xs: "16px" } }}
              >
                “I <b>cannot make out</b> which of my{" "}
                <b>Favourites belong to which region</b> since there is no
                nomenclature”
              </Typography>
            </Box>
          </AnimationOnScroll>

          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
            delay={300}
          >
            <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
              <East color="error" />
              <Typography
                sx={{ ml: 1, fontSize: { md: "22px", sm: "18px", xs: "16px" } }}
              >
                <b>“Bulk Upload is cumbersome if mulitple levels of data</b>{" "}
                needs to be entered. Manual Entry works better in such cases”
                [Main application use-case]
              </Typography>
            </Box>
          </AnimationOnScroll>

          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
            delay={300}
          >
            <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
              <East color="error" />
              <Typography
                sx={{ ml: 1, fontSize: { md: "22px", sm: "18px", xs: "16px" } }}
              >
                <b>“Navigation</b> is difficult to understand,{" "}
                <b>complicated view”</b>
              </Typography>
            </Box>
          </AnimationOnScroll>
        </Box>

        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Box
            sx={{
              background: (theme: Theme) => theme.palette.secondary.main,
              px: 1.5,
              py: 1,
              mt: 7,
              width: "fit-content",
            }}
          >
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "19.36px !important",
              }}
            >
              DATA LEADS
            </Typography>
          </Box>
        </AnimationOnScroll>
        <Box>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
            delay={300}
          >
            <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
              <East color="error" />
              <Typography
                sx={{ ml: 1, fontSize: { md: "22px", sm: "18px", xs: "16px" } }}
              >
                <b>“Calendar</b> is kind of <b>hidden”</b>
              </Typography>
            </Box>
          </AnimationOnScroll>

          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
            delay={300}
          >
            <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
              <East color="error" />
              <Typography
                sx={{ ml: 1, fontSize: { md: "22px", sm: "18px", xs: "16px" } }}
              >
                “The <b>KPI Overview screen is difficult to reach”</b>
              </Typography>
            </Box>
          </AnimationOnScroll>

          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
            delay={300}
          >
            <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
              <East color="error" />
              <Typography
                sx={{ ml: 1, fontSize: { md: "22px", sm: "18px", xs: "16px" } }}
              >
                “In KPI Overview, all the <b>information is scattered</b> and I
                really have to put in a lot of effort to find relevant
                information”
              </Typography>
            </Box>
          </AnimationOnScroll>
        </Box>
      </SectionSevenWrapper>
      <SectionEightWrapper>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: { md: "40px", sm: "32px", xs: "28px" },
              fontWeight: 700,
              fontFamily: "Mohave",
              fontStyle: "italic",
            }}
          >
            IDEATION
          </Typography>
        </AnimationOnScroll>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Typography
            sx={{
              fontSize: { md: "22px", sm: "18px" },
              mt: 3,
            }}
          >
            After gathering insights about users, and their experiences with
            Prism, a collaborative ideation session was set up, wherein the
            entire application was divided into 6 major parts (as per the
            use-cases), and ideas collated for each one of them.
          </Typography>
        </AnimationOnScroll>
        <Box
          sx={{
            mt: 8,
            "& img": {
              boxShadow: "4px 0px 20px 0px #66666640",
              borderRadius: "12px",
              width: "100%",
            },
          }}
        >
          <img src={PharmaIdea} alt="No idea" />
        </Box>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
          delay={300}
        >
          <Box
            sx={{
              py: 3.5,
              px: 4,
              mt: 10,
              background: (theme: Theme) => theme.palette.secondary.main,
            }}
          >
            <Typography
              sx={{
                fontSize: { md: "28px", xs: "22px" },
                fontWeight: 600,
                lineHeight: "33.89px",
              }}
            >
              Result - Collaboration done with minimal context about the
              application turned out to be a great way to ideate, since there
              are no restrictions in mind, and ideas turn out be more unique.
              <br />
              <br />
              <br />
              We did end up implementing some of these ideas, while others paved
              way for something even better.
            </Typography>
          </Box>
        </AnimationOnScroll>
      </SectionEightWrapper>
      <SectionNineWrapper>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: { md: "40px", sm: "32px", xs: "28px" },
              fontWeight: 700,
              fontFamily: "Mohave",
              fontStyle: "italic",
            }}
          >
            INITIAL SKETCHES.... EXPLORATORY IDEAS
          </Typography>
        </AnimationOnScroll>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              mt: 5,
              "& img": {
                width: { lg: "572px", xs: "70%" },
              },
            }}
          >
            <img src={Sketch1} alt="no sketch" />
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: { lg: "280px", sm: "25%", xs: "20%" },
              left: { lg: "500px", sm: "50%", xs: "40%" },
              width: "100%",
              "& img": {
                width: { lg: "572px", xs: "50%" },
              },
            }}
          >
            <img src={Sketch2} alt="no sketch" />
          </Box>
        </Box>

        <Box
          sx={{
            position: "absolute",
            left: { lg: "95%", md: "98%", xs: "85%" },
            top: { lg: "80%", md: "60%", sm: "55%", xs: "40%" },
            transform: "translate(-50%,-80%)",
            width: "120px",
            height: "120px",
            borderRadius: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: (theme: Theme) => theme.palette.secondary.main,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              cursor: "pointer",
              animation: "rotation 8s infinite linear ",
              animationPlayState: "paused",
              "&:hover": {
                animationPlayState: "running",
              },
              "& svg": {
                width: "110px",
                height: "110px",
              },
            }}
          >
            <RoundText />
          </Box>
          <LBulbIcon />
        </Box>
        <Box
          sx={{
            py: 3.5,
            px: 3,
            mt: { lg: 7, xs: 16 },
            width: { lg: "461px", xs: "100%" },
            background: (theme: Theme) => theme.palette.secondary.main,
          }}
        >
          <Typography
            sx={{
              fontSize: { md: "28px", xs: "22px" },
              fontWeight: 600,
              lineHeight: "33.89px",
            }}
          >
            This is the point where I knew no boundaries & constraints. I wanted
            to sketch possible solutions out before getting bogged down into
            constraints.
          </Typography>
        </Box>
        <Box
          sx={{
            py: 3.5,
            px: 3.5,
            mt: 6.2,
            background: (theme: Theme) => theme.palette.secondary.main,
            display: "flex",
            alignItems: "flex-start",
            "& svg": {
              width: { md: "50px", xs: "22px" },
            },
          }}
        >
          <Box sx={{ display: { md: "block", xs: "none" } }}>
            <SmileIcon />
          </Box>
          <Typography
            sx={{
              fontSize: { md: "28px", xs: "22px" },
              ml: 1,
              fontWeight: 600,
              lineHeight: "33.89px",
            }}
          >
            <Typography
              component="span"
              sx={{ display: { md: "none" }, mt: 3, mr: 1 }}
            >
              <SmileIcon />
            </Typography>
            The sketches turned out to be fruitful, since they led to the
            concept that we ended up implementing! (Role-based data upload)
          </Typography>
        </Box>
      </SectionNineWrapper>
      <SectionTenWrapper>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: { md: "40px", sm: "32px", xs: "28px" },
              fontWeight: 700,
              fontFamily: "Mohave",
              fontStyle: "italic",
              width: "fit-content",
              // borderBottom: "4px solid",
            }}
          >
            BEHIND THE SCENES
          </Typography>
        </AnimationOnScroll>
        <Box
          sx={{
            mt: 6,
            "& img": {
              width: { md: "800px", xs: "100%" },
            },
          }}
        >
          <img src={Figma} alt="no figma" />
        </Box>
        <Box
          sx={{
            py: 3.5,
            px: 4,
            mt: 10,
            background: (theme: Theme) => theme.palette.secondary.main,
          }}
        >
          <Typography
            sx={{
              fontSize: { md: "28px", xs: "22px" },
              fontWeight: 600,
              lineHeight: "33.89px",
              color: "#000",
            }}
          >
            All of this, a few discussions, and some food is what led to the
            almost final screens!
          </Typography>
        </Box>
      </SectionTenWrapper>
      <SectionElevanWrapper>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: { md: "40px", sm: "32px", xs: "28px" },
              fontWeight: 700,
              ml: { lg: 7.5, sm: 5, xs: 2 },
              fontFamily: "Mohave",
              fontStyle: "italic",
              width: "fit-content",
              // borderBottom: "4px solid",
            }}
          >
            ITERATIONS THAT LED TO THE FINAL DESIGN
          </Typography>
        </AnimationOnScroll>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Typography
            sx={{
              fontSize: { md: "22px", sm: "18px" },
              mt: 3,
              width: "70%",

              ml: { lg: 7.5, sm: 5, xs: 2 },
            }}
          >
            Before coming to the final solution(s), these are some of the
            versions we had made, which eventually led to the final design with
            further iterations.
          </Typography>
        </AnimationOnScroll>
        <Box ref={SliderParentRef}>
          <Slider
            ref={(c) => (SliderRef.current = c)}
            {...{
              dots: true,
              infinite: true,
              // nextArrow: <ChevronRight color="primary" />,
              // prevArrow: <ChevronLeft color="primary" />,
              // arrows: true,
              speed: 250,
              slidesToShow: 1,
              slidesToScroll: 1,
            }}
          >
            <Box>
              <Grid
                container
                sx={{ mt: 4 }}
                alignItems="flex-start"
                position="relative"
              >
                <Grid
                  item
                  lg={8.5}
                  md={8}
                  sx={{
                    p: 3,
                    "& img": {
                      width: "100%",
                      height: "auto",
                      borderRadius: "12px",
                      boxShadow: "4px 0px 20px 0px #66666640",
                    },
                  }}
                >
                  <img src={Slider1} alt="No content" />
                </Grid>
                <Grid
                  item
                  lg={3.5}
                  md={4}
                  xs={12}
                  sx={{
                    textAlign: { md: "left", xs: "left" },
                    mx: { md: 0, xs: 5 },
                  }}
                >
                  <Box
                    sx={{
                      display: { md: "block", xs: "none" },
                      position: "absolute",
                      transform: "translate(-20px,50px)",
                    }}
                  >
                    <DrawLine />
                  </Box>
                  <Box
                    sx={{
                      py: 2.25,
                      px: 3,
                      mt: { md: 10 },
                      width: { md: "100%", xs: "80%" },
                      // mx: { md: 0, xs: "auto" },
                      background: (theme: Theme) =>
                        theme.palette.secondary.main,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { lg: "20px", xs: "16px" },
                        fontWeight: 600,
                        color: "#000",
                        textAlign: "center",
                      }}
                    >
                      Excerpts from the user feedback
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      "& p": {
                        fontFamily: "Caveat",
                        fontSize: { lg: "22px", sm: "18px", xs: "15px" },
                      },
                    }}
                  >
                    {ItrationText1.map((item, index) => {
                      return (
                        <Typography sx={{ mt: { lg: 3.5, xs: 1 } }}>
                          {item}
                        </Typography>
                      );
                    })}
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid
                container
                sx={{ mt: 4 }}
                alignItems="flex-start"
                position="relative"
              >
                <Grid
                  item
                  lg={8.5}
                  md={8}
                  sx={{
                    p: 3,
                    "& img": {
                      width: "100%",
                      height: "auto",
                      borderRadius: "12px",
                      boxShadow: "4px 0px 20px 0px #66666640",
                    },
                  }}
                >
                  <img src={Slider2} alt="No content" />
                </Grid>
                <Grid
                  item
                  lg={3.5}
                  md={4}
                  xs={12}
                  sx={{
                    textAlign: { md: "left", xs: "left" },
                    mx: { md: 0, xs: 5 },
                  }}
                >
                  <Box
                    sx={{
                      display: { md: "block", xs: "none" },
                      position: "absolute",
                      transform: "translate(-20px,50px)",
                    }}
                  >
                    <DrawLine />
                  </Box>
                  <Box
                    sx={{
                      py: 2.25,
                      px: 3,
                      mt: { md: 10 },
                      width: { md: "100%", xs: "80%" },
                      // mx: { md: 0, xs: "auto" },
                      background: (theme: Theme) =>
                        theme.palette.secondary.main,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { lg: "20px", xs: "16px" },
                        fontWeight: 600,
                        color: "#000",
                        textAlign: "center",
                      }}
                    >
                      This one didn't pass our own heuristic analysis
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      "& p": {
                        fontFamily: "Caveat",
                        fontSize: { lg: "22px", sm: "18px", xs: "15px" },
                      },
                    }}
                  >
                    {ItrationText2.map((item, index) => {
                      return (
                        <Typography sx={{ mt: { lg: 3.5, xs: 1 } }}>
                          {item}
                        </Typography>
                      );
                    })}
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid
                container
                sx={{ mt: 4 }}
                alignItems="flex-start"
                position="relative"
              >
                <Grid
                  item
                  lg={8.5}
                  md={8}
                  sx={{
                    p: 3,
                    "& img": {
                      width: "100%",
                      height: "auto",
                      borderRadius: "12px",
                      boxShadow: "4px 0px 20px 0px #66666640",
                    },
                  }}
                >
                  <img src={Slider3} alt="No content" />
                </Grid>
                <Grid
                  item
                  lg={3.5}
                  md={4}
                  xs={12}
                  sx={{
                    textAlign: { md: "left", xs: "left" },
                    mx: { md: 0, xs: 5 },
                  }}
                >
                  <Box
                    sx={{
                      display: { md: "block", xs: "none" },
                      position: "absolute",
                      transform: "translate(-20px,50px)",
                    }}
                  >
                    <DrawLine />
                  </Box>
                  <Box
                    sx={{
                      py: 2.25,
                      px: 3,
                      mt: { md: 10 },
                      width: { md: "100%", xs: "80%" },
                      // mx: { md: 0, xs: "auto" },
                      background: (theme: Theme) =>
                        theme.palette.secondary.main,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { lg: "20px", xs: "16px" },
                        fontWeight: 600,
                        color: "#000",
                        textAlign: "center",
                      }}
                    >
                      Excerpts from the user feedback
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      "& p": {
                        fontFamily: "Caveat",
                        fontSize: { lg: "22px", sm: "18px", xs: "15px" },
                      },
                    }}
                  >
                    {ItrationText3.map((item, index) => {
                      return (
                        <Typography
                          sx={{ mt: 3.5 }}
                          // className={index === currentSlide ? "active" : ""}
                        >
                          {item}
                        </Typography>
                      );
                    })}
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid
                container
                sx={{ mt: 4 }}
                alignItems="flex-start"
                position="relative"
              >
                <Grid
                  item
                  lg={8.5}
                  md={8}
                  sx={{
                    p: 3,
                    "& img": {
                      width: "100%",
                      height: "auto",
                      borderRadius: "12px",
                      boxShadow: "4px 0px 20px 0px #66666640",
                    },
                  }}
                >
                  <img src={Slider4} alt="No content" />
                </Grid>
                <Grid
                  item
                  lg={3.5}
                  md={4}
                  xs={12}
                  sx={{
                    textAlign: { md: "left", xs: "left" },
                    mx: { md: 0, xs: 5 },
                  }}
                >
                  <Box
                    sx={{
                      display: { md: "block", xs: "none" },
                      position: "absolute",
                      transform: "translate(-20px,50px)",
                    }}
                  >
                    <DrawLine />
                  </Box>
                  <Box
                    sx={{
                      py: 2.25,
                      px: 3,
                      mt: { md: 10 },
                      width: { md: "100%", xs: "80%" },
                      // mx: { md: 0, xs: "auto" },
                      background: (theme: Theme) =>
                        theme.palette.secondary.main,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { lg: "20px", xs: "16px" },
                        fontWeight: 600,
                        color: "#000",
                        textAlign: "center",
                      }}
                    >
                      Excerpts from the user feedback
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      "& p": {
                        fontFamily: "Caveat",
                        fontSize: { lg: "22px", sm: "18px", xs: "15px" },
                      },
                    }}
                  >
                    {ItrationText4.map((item, index) => {
                      return <Typography sx={{ mt: 3.5 }}>{item}</Typography>;
                    })}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Slider>
        </Box>
      </SectionElevanWrapper>
      <SectionTwelveWrapper id="section3">
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: { md: "40px", sm: "32px", xs: "28px" },
              fontWeight: 700,
              fontFamily: "Mohave",
              fontStyle: "italic",
              width: "fit-content",
              // borderBottom: "4px solid",
            }}
          >
            DESIGNS THAT ALL STAKEHOLDERS WERE HAPPY WITH
          </Typography>
        </AnimationOnScroll>
        <Box sx={{ mt: 4 }}>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
          >
            <Typography
              variant="h2"
              sx={{
                fontSize: { md: "28px", xs: "22px" },
                fontWeight: 600,
                lineHeight: "33.89px",
              }}
            >
              Introducing....
            </Typography>
            <Typography
              variant="h3"
              sx={{
                mt: 2,
                fontSize: { md: "40px", sm: "32px", xs: "28px" },
                fontWeight: 700,
                fontFamily: "Mohave",
                fontStyle: "italic",
                width: "fit-content",
              }}
            >
              ROLE-BASED DATA UPLOAD
            </Typography>
          </AnimationOnScroll>
        </Box>
        <Box
          sx={{
            mt: 7.5,
            "& img": {
              width: "100%",
              height: "auto",
              borderRadius: "12px",
              boxShadow: "4px 0px 20px 0px #66666640",
            },
          }}
        >
          <img src={Pharma1} alt="No content" />
        </Box>
        <Box
          sx={{
            mt: 7.5,
            ml: { md: 3, xs: 1 },
            width: "100%",
            maxWidth: "1000px",
            "& p": {
              fontSize: {
                md: "22px !important",
                sm: "18px !important",
                xs: "15px !important",
              },
              color: "#000",
              mt: 5,
            },
          }}
        >
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
          >
            <Typography>
              <b>Role-based personalised access</b> is what we conceptualised
              and designed, to solve several of our users’ problems. It shows
              each user a personalised set of KPIs that they are responsible
              for, instead of showing all KPIs to each user.
            </Typography>
          </AnimationOnScroll>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
          >
            <Typography>
              As Data Entry/Viewing is the main task of the people who use
              Prism, we made the KPI data tables (which allow data
              entry/viewing) as the first screen of the application after a user
              logs in. This, combined with Role-based access, makes Data
              Entry/Viewing super quick and contextual.
            </Typography>
          </AnimationOnScroll>{" "}
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
          >
            <Typography>
              This potentially solves these pain points (tested with the users
              before shipping) -
            </Typography>
          </AnimationOnScroll>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
          >
            <Box sx={{ display: "flex", alignItems: "flex-start", mt: 5 }}>
              <East
                sx={{
                  "& *": {
                    fill: "#03a300",
                  },
                }}
              />
              <Typography
                sx={{
                  ml: 1,
                  fontSize: { md: "22px", sm: "18px" },
                  color: "#03a300 !important",
                  fontStyle: "italic",
                  mt: "0 !important",
                }}
              >
                “Takes me <b>too much time to upload data for multiple KPIs”</b>
              </Typography>
            </Box>
          </AnimationOnScroll>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
          >
            <Box sx={{ display: "flex", alignItems: "flex-start", mt: 5 }}>
              <East
                sx={{
                  "& *": {
                    fill: "#03a300",
                  },
                }}
              />
              <Typography
                sx={{
                  ml: 1,
                  fontSize: { md: "22px", sm: "18px" },
                  color: "#03a300 !important",
                  fontStyle: "italic",
                  mt: "0 !important",
                }}
              >
                “Since there’s <b>no restriction</b>, sometimes my{" "}
                <b>peers overwrite my uploaded data</b> by mistake, then I have
                to go back and upload my data again”
              </Typography>
            </Box>
          </AnimationOnScroll>
        </Box>
      </SectionTwelveWrapper>
      <SectionThirteenWrapper>
        <Box
          sx={{
            position: "absolute",
            top: { lg: "22%", sm: "10%" },
            left: {
              lg: "calc(50% - 300px)",
              md: "calc(50% - 250px)",
              sm: "calc(50% - 300px)",
            },
            width: { lg: "450px", sm: "350px" },
            transform: { md: "translateX(-50%)" },
            display: { sm: "block", xs: "none" },
          }}
        >
          <AnimationOnScroll
            animateOnce={true}
            delay={500}
            animateIn="animate__flipInX"
          >
            The tables shown are user-specific, meaning that no data owner would
            now be able to over-write somebody else’s data
          </AnimationOnScroll>
        </Box>

        <Box
          className="lineAnimation"
          sx={{
            position: "absolute",
            display: { sm: "block", xs: "none" },
            top: "33%",
            left: { xl: "33.7%", md: "28.7%", sm: "45%" },
            width: "305px",
            height: "160px",
            background: "#fff",
          }}
        ></Box>
        <Box
          sx={{
            position: "absolute",
            display: { sm: "block", xs: "none" },
            top: "33%",
            left: { xl: "33.7%", md: "28.7%", sm: "45%" },

            // transform: { md: "translateX(-50%)" },
            zIndex: -1,
          }}
        >
          <Line1 />
        </Box>

        <Box
          sx={{
            position: "absolute",
            display: { sm: "block", xs: "none" },
            top: { md: "68%", sm: "56%" },
            left: {
              lg: "calc(50% - 300px)",
              md: "calc(50% - 250px)",
              sm: "calc(50% - 300px)",
            },
            width: { lg: "450px", sm: "350px" },
            transform: { md: "translateX(-50%)" },
          }}
        >
          <AnimationOnScroll
            animateOnce={true}
            delay={500}
            animateIn="animate__flipInX"
          >
            All assigned KPIs are now shown on a single screen, to enable easy
            data entry for multiple KPIs
          </AnimationOnScroll>
        </Box>
        <Box
          className="lineAnimation"
          sx={{
            position: "absolute",
            display: { sm: "block", xs: "none" },
            top: "80%",
            left: { xl: "33.7%", md: "28.7%", sm: "45%" },
            width: "305px",
            height: "160px",
            background: "#fff",
          }}
        ></Box>
        <Box
          sx={{
            position: "absolute",
            display: { sm: "block", xs: "none" },
            top: "80%",
            left: { xl: "33.7%", md: "28.7%", sm: "45%" },
            zIndex: -1,
          }}
        >
          <Line2 />
        </Box>
        <AnimationOnScroll
          animateOnce={true}
          animateIn={"animate__fadeInRight"}
        >
          <Box
            sx={{
              textAlign: { sm: "right", xs: "center" },
              transform: {
                lg: "translateX(290px)",
                md: "translateX(350px)",
                sm: "translateX(400px)",
              },
              "& img": {
                width: { lg: "950px", md: "850px", sm: "650px", xs: "90%" },
                height: "auto",
                borderRadius: "12px",
                boxShadow: "-4px 4px 20px 0px #66666640",
              },
            }}
          >
            <img src={Pharma1} alt="No Content" />
            <Box
              sx={{ display: { sm: "none", xs: "block" }, width: "90%", ml: 1 }}
            >
              <AnimationOnScroll
                animateOnce={true}
                delay={500}
                animateIn="animate__flipInX"
              >
                The tables shown are user-specific, meaning that no data owner
                would now be able to over-write somebody else’s data
              </AnimationOnScroll>
              <br />
              <br />
              <AnimationOnScroll
                animateOnce={true}
                delay={500}
                animateIn="animate__flipInX"
              >
                All assigned KPIs are now shown on a single screen, to enable
                easy data entry for multiple KPIs
              </AnimationOnScroll>
            </Box>
          </Box>
        </AnimationOnScroll>
      </SectionThirteenWrapper>
      <SectionFourteenWrapper>
        <Box
          sx={{
            position: "absolute",
            display: { sm: "block", xs: "none" },
            top: { lg: "7%", md: 0 },
            left: {
              lg: "calc(50% + 325px)",
              md: "calc(50% + 250px)",
              sm: "calc(50% - 80px)",
            },
            transform: { md: "translateX(-50%)" },
            width: { lg: "450px", md: "350px", sm: "420px" },
            // display: { sm: "block", xs: "none" },
          }}
        >
          <AnimationOnScroll
            animateOnce={true}
            delay={500}
            animateIn="animate__flipInX"
          >
            The editable columns (Num/Den/Actual) show up only when a user
            wishes to enter data (video shown below). This is to avoid
            unnecessary information on the homescreen
          </AnimationOnScroll>
        </Box>
        <Box
          className="lineAnimation"
          sx={{
            position: "absolute",
            display: { sm: "block", xs: "none" },
            top: "28%",
            right: { xl: "32%", md: "27%", sm: "50%" },
            width: "305px",
            height: "160px",
            background: "#fff",
          }}
        ></Box>
        <Box
          sx={{
            position: "absolute",
            display: { sm: "block", xs: "none" },
            top: "28%",
            right: { xl: "32%", md: "27%", sm: "50%" },
            transform: "rotateY(180deg)",
            zIndex: -1,
          }}
        >
          <Line1 />
        </Box>
        <Box
          sx={{
            position: "absolute",
            display: { sm: "block", xs: "none" },
            top: "51%",
            left: {
              lg: "calc(50% + 325px)",
              md: "calc(50% + 250px)",

              sm: "calc(50% - 80px)",
            },
            transform: { md: "translateX(-50%)" },
            width: { lg: "470px", md: "350px", sm: "420px" },
          }}
        >
          <AnimationOnScroll
            animateOnce={true}
            delay={500}
            animateIn="animate__flipInX"
          >
            Various states of Num/Den/Actual cells (x, white cell, coloured
            cell, no cell). These states have been introduced to denote
            different types of actions that can be taken on various KPIs
          </AnimationOnScroll>
        </Box>
        <Box
          className="lineAnimation"
          sx={{
            position: "absolute",
            display: { sm: "block", xs: "none" },
            top: { lg: "67%", xs: "80%" },
            right: { xl: "32%", md: "27%" },
            width: "310px",
            height: "160px",
            background: "#fff",
          }}
        ></Box>
        <Box
          sx={{
            position: "absolute",
            display: { sm: "block", xs: "none" },
            top: { lg: "67%", xs: "80%" },
            right: { xl: "32%", md: "27%" },
            transform: "rotateY(180deg)",
            zIndex: -1,
          }}
        >
          <Line2 />
        </Box>
        <AnimationOnScroll animateOnce={true} animateIn={"animate__fadeInLeft"}>
          <Box
            sx={{
              textAlign: { sm: "left", xs: "center" },
              transform: {
                lg: "translateX(-272px)",
                md: "translateX(-370px)",
                sm: "translateX(-400px)",
              },
              "& img": {
                width: { lg: "950px", md: "850px", sm: "650px", xs: "90%" },
                height: "auto",
                borderRadius: "12px",
                boxShadow: "-4px 4px 20px 0px #66666640",
              },
            }}
          >
            <img src={Pharma3} alt="No Content" />
            <Box
              sx={{ display: { sm: "none", xs: "block" }, width: "90%", ml: 1 }}
            >
              <AnimationOnScroll
                animateOnce={true}
                delay={500}
                animateIn="animate__flipInX"
              >
                The editable columns (Num/Den/Actual) show up only when a user
                wishes to enter data (video shown below). This is to avoid
                unnecessary information on the homescreen
              </AnimationOnScroll>
              <br />
              <br />
              <AnimationOnScroll
                animateOnce={true}
                delay={500}
                animateIn="animate__flipInX"
              >
                Various states of Num/Den/Actual cells (x, white cell, coloured
                cell, no cell). These states have been introduced to denote
                different types of actions that can be taken on various KPIs
              </AnimationOnScroll>
            </Box>
          </Box>
        </AnimationOnScroll>
      </SectionFourteenWrapper>
      <Box
        sx={{
          mt: 12.5,
          width: "95%",
          mx: "auto",
          "& img": {
            maxWidth: "1536px",
            width: "100%",
            borderRadius: "12px",
            boxShadow: "4px 0px 20px 0px #66666640",
          },
        }}
      >
        <img src={Pharma2GIF} alt="no gif" />
      </Box>

      <SectionFifteenWrapper>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: { md: "40px", sm: "32px", xs: "28px" },
              fontWeight: 700,
              fontFamily: "Mohave",
              fontStyle: "italic",
              width: "fit-content",
              // borderBottom: "4px solid",
            }}
          >
            BULK UPLOAD
          </Typography>
        </AnimationOnScroll>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Typography sx={{ fontSize: { md: "22px", sm: "18px" }, mt: 7 }}>
            Bulk Upload is used to upload bulk of data in one go, without having
            to enter it one-by-one. Users have a single or a few source file(s),
            from where they copy data into an offline Excel template.
          </Typography>
        </AnimationOnScroll>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Typography sx={{ fontSize: { md: "22px", sm: "18px" }, mt: 5 }}>
            In the old application, it was a tedious and cumbersome process. It
            had several challenges -
          </Typography>
        </AnimationOnScroll>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Box sx={{ display: "flex", alignItems: "flex-start", mt: 5 }}>
            <East
              sx={{
                "& *": {
                  fill: "#ff0000",
                },
              }}
            />
            <Typography
              sx={{
                ml: 1,
                fontSize: { md: "22px", sm: "18px" },
                color: "#ff0000 !important",
                mt: "0 !important",
              }}
            >
              No ability to use Bulk Upload for multiple hierarchical levels at
              once
            </Typography>
          </Box>
        </AnimationOnScroll>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Box sx={{ display: "flex", alignItems: "flex-start", mt: 5 }}>
            <East
              sx={{
                "& *": {
                  fill: "#ff0000",
                },
              }}
            />
            <Typography
              sx={{
                ml: 1,
                fontSize: { md: "22px", sm: "18px" },
                color: "#ff0000 !important",
                mt: "0 !important",
              }}
            >
              Tedious to go to each level to be able to use Bulk Upload
            </Typography>
          </Box>
        </AnimationOnScroll>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Box sx={{ display: "flex", alignItems: "flex-start", mt: 5 }}>
            <East
              sx={{
                "& *": {
                  fill: "#ff0000",
                },
              }}
            />
            <Typography
              sx={{
                ml: 1,
                fontSize: { md: "22px", sm: "18px" },
                color: "#ff0000 !important",
                mt: "0 !important",
              }}
            >
              Not very convenient to maintain multiple Excel files for different
              data entry levels
            </Typography>
          </Box>
        </AnimationOnScroll>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Box
            sx={{
              py: 3.5,
              px: 4,
              mt: 10,
              width: { md: "93%", xs: "100%" },
              background: (theme: Theme) => theme.palette.secondary.main,
            }}
          >
            <Typography
              sx={{
                fontSize: { md: "28px", xs: "22px" },
                fontWeight: 600,
                lineHeight: "33.89px",
                color: "#000",
              }}
            >
              After ideating on it for a while, we started to question the need
              of even having this feature. Why can’t the data just automatically
              sync from the source, directly into Prism? Researching on it a bit
              informed us that it is indeed technically possible.
              <br />
              <br />
              Suggested this to the stakeholders, but unfortunately, it wasn’t a
              financially sound choice for them to spend resources building
              <Typography
                component="span"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: { md: "28px", xs: "22px" },
                  fontWeight: 600,
                  lineHeight: "33.89px",
                  color: "#000",
                }}
              >
                this functionality.
                <Typography component="span" sx={{ mt: 1 }}>
                  <SadIcon />
                </Typography>
              </Typography>
            </Typography>
          </Box>
        </AnimationOnScroll>
        <Box sx={{ mt: 10, width: "94%" }}>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
          >
            <Typography
              variant="h2"
              sx={{
                fontSize: { md: "28px", xs: "22px" },
                fontWeight: 600,
                lineHeight: "33.89px",
              }}
            >
              The next best solution....
            </Typography>
          </AnimationOnScroll>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
          >
            <Typography sx={{ fontSize: { md: "22px", sm: "18px" }, mt: 7 }}>
              Introducing, Online Bulk Upload (the name could’ve been better
              :P). Online Bulk Upload is a common-for-all-KPIs template for
              uploading data in bulk, on cloud.
            </Typography>
          </AnimationOnScroll>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
          >
            <Box sx={{ display: "flex", alignItems: "flex-start", mt: 5 }}>
              <East
                sx={{
                  "& *": {
                    fill: "#03a300",
                  },
                }}
              />
              <Typography
                sx={{
                  ml: 1,
                  fontSize: { md: "22px", sm: "18px" },
                  color: "#03a300 !important",
                  mt: "0 !important",
                }}
              >
                Universal access to Bulk Upload, KPI-independent
              </Typography>
            </Box>
          </AnimationOnScroll>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
          >
            <Box sx={{ display: "flex", alignItems: "flex-start", mt: 5 }}>
              <East
                sx={{
                  "& *": {
                    fill: "#03a300",
                  },
                }}
              />
              <Typography
                sx={{
                  ml: 1,
                  fontSize: { md: "22px", sm: "18px" },
                  color: "#03a300 !important",
                  mt: "0 !important",
                }}
              >
                Adds the ability to bulk upload data for multiple KPIs at once
              </Typography>
            </Box>
          </AnimationOnScroll>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
          >
            <Box sx={{ display: "flex", alignItems: "flex-start", mt: 5 }}>
              <East
                sx={{
                  "& *": {
                    fill: "#03a300",
                  },
                }}
              />
              <Typography
                sx={{
                  ml: 1,
                  fontSize: { md: "22px", sm: "18px" },
                  color: "#03a300 !important",
                  mt: "0 !important",
                }}
              >
                In order to maintain familiarity, Online Bulk Upload still has
                basic Excel features like multi-cell selection, copy/paste,
                column-level filter
              </Typography>
            </Box>
          </AnimationOnScroll>
          <AnimationOnScroll
            duration={0.5}
            animateOnce={true}
            animateIn="animate__fadeInUp"
          >
            <Box sx={{ display: "flex", alignItems: "flex-start", mt: 5 }}>
              <East
                sx={{
                  "& *": {
                    fill: "#03a300",
                  },
                }}
              />
              <Typography
                sx={{
                  ml: 1,
                  fontSize: { md: "22px", sm: "18px" },
                  color: "#03a300 !important",
                  mt: "0 !important",
                }}
              >
                No need to maintain any Excel template. Whenever you wish to use
                it, just do it on cloud, quickly
              </Typography>
            </Box>
          </AnimationOnScroll>
        </Box>
      </SectionFifteenWrapper>
      <Box
        sx={{
          mt: 10,
          width: "95%",
          mx: "auto",
          "& img": {
            maxWidth: "1536px",
            width: "100%",
            borderRadius: "12px",
            boxShadow: "4px 0px 20px 0px #66666640",
          },
        }}
      >
        <img src={Pharma1GIF} alt="No content" />
      </Box>
      <SectionSixteenWrapper>
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: { md: "40px", sm: "32px", xs: "28px" },
              fontWeight: 700,
              ml: { md: 8, sm: 5, xs: 1 },
              fontFamily: "Mohave",
              fontStyle: "italic",
              width: "fit-content",
              // borderBottom: "4px solid",
            }}
          >
            OTHER CHANGES MADE THROUGHOUT THE APPLICATION
          </Typography>
        </AnimationOnScroll>
        <Grid
          container
          sx={{ mt: { lg: 5 }, display: { lg: "flex", xs: "none" } }}
          alignItems="flex-start"
          ref={IterationSection}
          position="relative"
        >
          <Grid
            item
            lg={8.5}
            md={8}
            sx={{
              height: "70vh",
              position: "relative",
              "& img": {
                position: "absolute",
                width: "80%",
                height: "auto",
                right: "15%",
              },
            }}
          >
            <img
              src={LastSlider1}
              style={{ zIndex: 15, bottom: 0 }}
              alt="No content"
              className={
                currentSlideFromLastSlider.currentIndex === 0
                  ? "active"
                  : currentSlideFromLastSlider.currentIndex >
                    currentSlideFromLastSlider.prevIndex
                  ? "deactive"
                  : ""
              }
            />
            <Box
              sx={{
                "& img": {
                  ...(currentSlideFromLastSlider.currentIndex >
                    currentSlideFromLastSlider.prevIndex &&
                  currentSlideFromLastSlider.currentIndex > 1
                    ? {
                        transition: "0.5s",
                        transform: "skewX(0deg) !important",
                        width: "80% !important",
                        bottom: 0,
                        right: "15% !important",
                        zIndex: 10,
                      }
                    : {
                        transform: "skewX(-5deg)",
                        width: "70%",
                        bottom: "20px",
                        right: { xl: "140px", lg: "120px" },
                        zIndex: 10,
                      }),
                },
              }}
            >
              <img
                src={LastSlider2}
                alt="No content"
                className={
                  currentSlideFromLastSlider.currentIndex === 1
                    ? "active"
                    : (currentSlideFromLastSlider.currentIndex >
                        currentSlideFromLastSlider.prevIndex ||
                        currentSlideFromLastSlider.prevIndex >
                          currentSlideFromLastSlider.currentIndex) &&
                      currentSlideFromLastSlider.currentIndex > 1
                    ? "deactive"
                    : currentSlideFromLastSlider.prevIndex >
                      currentSlideFromLastSlider.currentIndex
                    ? "reactive"
                    : ""
                }
              />
            </Box>
            <Box
              sx={{
                "& .reactive": {
                  transform: "skewX(-8deg) !important",
                  width: "68% !important",
                  bottom: "20px !important",
                  right: "13% !important",
                  zIndex: "5 !important",
                },
                "& img": {
                  ...(currentSlideFromLastSlider.currentIndex >
                    currentSlideFromLastSlider.prevIndex &&
                  currentSlideFromLastSlider.currentIndex > 2
                    ? {
                        transition: "0.5s",
                        transform: "skewX(0deg) !important",
                        width: "80% !important",
                        bottom: 0,
                        right: "15% !important",
                        zIndex: 5,
                      }
                    : {
                        transform: "skewX(-8deg)",
                        width: "67%",
                        bottom: "20px",
                        right: "13% !important",
                        zIndex: 5,
                      }),
                },
              }}
            >
              <img
                src={LastSlider3}
                alt="No content"
                className={
                  currentSlideFromLastSlider.currentIndex === 2
                    ? "active"
                    : (currentSlideFromLastSlider.currentIndex >
                        currentSlideFromLastSlider.prevIndex ||
                        currentSlideFromLastSlider.prevIndex >
                          currentSlideFromLastSlider.currentIndex) &&
                      currentSlideFromLastSlider.currentIndex > 2
                    ? "deactive"
                    : currentSlideFromLastSlider.prevIndex >
                      currentSlideFromLastSlider.currentIndex
                    ? "reactive"
                    : ""
                }
              />
            </Box>

            <Box
              sx={{
                "& .reactive": {
                  transform: "skewX(-12deg) !important",
                  width: "65% !important",
                  bottom: "20px !important",

                  right: "11% !important",
                  zIndex: "3 !important",
                },
                "& img": {
                  ...(currentSlideFromLastSlider.currentIndex >
                    currentSlideFromLastSlider.prevIndex &&
                  currentSlideFromLastSlider.currentIndex > 3
                    ? {
                        transition: "0.5s",
                        transform: "skewX(0deg) !important",
                        width: "80% !important",
                        bottom: 0,
                        right: "15% !important",
                        zIndex: 3,
                      }
                    : {
                        transform: "skewX(-12deg)",
                        width: "65%",
                        bottom: "10px",
                        right: "12% !important",
                        zIndex: 3,
                      }),
                },
              }}
            >
              <img
                src={LastSlider4}
                alt="No content"
                className={
                  currentSlideFromLastSlider.currentIndex === 3
                    ? "active"
                    : (currentSlideFromLastSlider.currentIndex >
                        currentSlideFromLastSlider.prevIndex ||
                        currentSlideFromLastSlider.prevIndex >
                          currentSlideFromLastSlider.currentIndex) &&
                      currentSlideFromLastSlider.currentIndex > 3
                    ? "deactive"
                    : currentSlideFromLastSlider.prevIndex >
                      currentSlideFromLastSlider.currentIndex
                    ? "reactive"
                    : ""
                }
              />
            </Box>
            <Box
              sx={{
                "& img": {
                  right: "95px !important",
                  zIndex: "0 !important",
                },
              }}
            >
              <img
                src={Pharma3GIF}
                alt="No content"
                style={{
                  transform: "skewX(-16deg)",
                  width: "63%",
                  bottom: "0",
                }}
                className={
                  currentSlideFromLastSlider.currentIndex === 4
                    ? "active"
                    : // : currentSlideFromLastSlider.currentIndex >
                    //   currentSlideFromLastSlider.prevIndex
                    // ? "deactive"
                    currentSlideFromLastSlider.prevIndex >
                      currentSlideFromLastSlider.currentIndex
                    ? "reactive"
                    : ""
                }
              />
            </Box>
          </Grid>
          <Grid
            item
            lg={3.5}
            md={4}
            xs={12}
            textAlign="center"
            position="relative"
            height="70vh"
          >
            {currentSlideFromLastSlider.currentIndex === 0 && (
              <>
                <SliderTracker>
                  <Typography>
                    {currentSlideFromLastSlider.currentIndex + 1}/5
                  </Typography>
                </SliderTracker>
                <Box
                  sx={{
                    position: "absolute",
                    top: "28%",
                    left: { xl: "-35%", lg: "-28%" },
                    zIndex: 20,
                  }}
                >
                  <LongLine />
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    top: "37%",
                    left: "-15%",
                    width: "290px",
                    "& p": {
                      fontFamily: "Caveat",
                      fontSize: { md: "22px", sm: "18px" },
                    },
                  }}
                >
                  <Typography>
                    All data entry statuses now shown on top for quick view
                    (Role-based data)
                  </Typography>
                </Box>
                <Box
                  sx={{
                    py: 2.25,
                    px: 3,
                    position: "absolute",
                    top: "65%",
                    left: "-20%",
                    background: (theme: Theme) => theme.palette.secondary.main,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { lg: "20px", xs: "16px" },
                      fontWeight: 600,
                      width: "380px",
                      color: "#000",
                      textAlign: "center",
                    }}
                  >
                    Quick glance of KPI entry status
                  </Typography>
                </Box>
              </>
            )}
            {currentSlideFromLastSlider.currentIndex === 1 && (
              <>
                <SliderTracker>
                  <Typography>
                    {currentSlideFromLastSlider.currentIndex + 1}/5
                  </Typography>
                </SliderTracker>
                <Box
                  sx={{
                    position: "absolute",
                    top: "45%",
                    left: { xl: "-35%", lg: "-28%" },
                    zIndex: 20,
                  }}
                >
                  <LongLine />
                </Box>
                <Box
                  sx={{
                    py: 2.25,
                    px: 3,
                    position: "absolute",
                    top: "17%",
                    left: "-12%",
                    background: (theme: Theme) => theme.palette.secondary.main,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { lg: "20px", xs: "16px" },
                      fontWeight: 600,
                      width: "380px",
                      color: "#000",
                    }}
                  >
                    Freeze moved from being a card on top, to just a button
                    (considering usage frequency)
                  </Typography>
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    top: "55%",
                    left: "-12%",
                    width: "340px",
                    "& p": {
                      fontFamily: "Caveat",
                      fontSize: { md: "22px", sm: "18px" },
                    },
                  }}
                >
                  <Typography>
                    Freeze added next to the table and not as a card on top
                    (since only 2-3 people use it, so having it as a card on top
                    isn’t good use of space)
                  </Typography>
                </Box>
              </>
            )}
            {currentSlideFromLastSlider.currentIndex === 2 && (
              <>
                <SliderTracker>
                  <Typography>
                    {currentSlideFromLastSlider.currentIndex + 1}/5
                  </Typography>
                </SliderTracker>
                <Box
                  sx={{
                    position: "absolute",
                    top: "42%",
                    left: { xl: "-35%", lg: "-28%" },
                    zIndex: 20,
                    transform: "rotateX(180deg)",
                  }}
                >
                  <LongLine />
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    top: "25%",
                    left: "-15%",
                    width: "340px",
                    "& p": {
                      fontFamily: "Caveat",
                      fontSize: { md: "22px", sm: "18px" },
                    },
                  }}
                >
                  <Typography>
                    Table cleaned up by eliminating unnecessary columns (better
                    focus on what matters most at this point for the user)
                  </Typography>
                </Box>
                <Box
                  sx={{
                    py: 2.25,
                    px: 3,
                    position: "absolute",
                    top: "58%",
                    left: "-20%",
                    background: (theme: Theme) => theme.palette.secondary.main,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { lg: "20px", xs: "16px" },
                      fontWeight: 600,
                      width: "380px",
                      color: "#000",
                    }}
                  >
                    Data Owner & Last Modified By added at a place where it’s
                    the least distracting, with all imp. columns upfront
                  </Typography>
                </Box>
              </>
            )}
            {currentSlideFromLastSlider.currentIndex === 3 && (
              <>
                <SliderTracker>
                  <Typography>
                    {currentSlideFromLastSlider.currentIndex + 1}/5
                  </Typography>
                </SliderTracker>
                <Box
                  sx={{
                    position: "absolute",
                    top: "25%",
                    left: { xl: "-35%", lg: "-28%" },
                    zIndex: 20,
                  }}
                >
                  <LongLine />
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    top: "35%",
                    left: "-15%",
                    width: "290px",
                    "& p": {
                      fontFamily: "Caveat",
                      fontSize: { md: "22px", sm: "18px" },
                    },
                  }}
                >
                  <Typography>
                    Global Search introduced with section-wise segregation
                  </Typography>
                </Box>
                <Box
                  sx={{
                    py: 2.25,
                    px: 3,
                    position: "absolute",
                    top: "60%",
                    left: "-20%",
                    background: (theme: Theme) => theme.palette.secondary.main,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { lg: "20px", xs: "16px" },
                      fontWeight: 600,
                      width: "380px",
                      color: "#000",
                    }}
                  >
                    Search with application-wide results segregation, to aid
                    easy finding of the desired keyword
                  </Typography>
                </Box>
              </>
            )}
            {currentSlideFromLastSlider.currentIndex === 4 && (
              <>
                <SliderTracker>
                  <Typography>
                    {currentSlideFromLastSlider.currentIndex + 1}/5
                  </Typography>
                </SliderTracker>
                <Box
                  sx={{
                    position: "absolute",
                    top: "25%",
                    left: { xl: "-35%", lg: "-28%" },
                    zIndex: 20,
                  }}
                >
                  <LongLine />
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    top: "35%",
                    left: "-15%",
                    width: "290px",
                    "& p": {
                      fontFamily: "Caveat",
                      fontSize: { md: "22px", sm: "18px" },
                    },
                  }}
                >
                  <Typography>
                    Contextual information division in KPI Overview with
                    interactive chart and data drill-down
                  </Typography>
                </Box>
                <Box
                  sx={{
                    py: 2.25,
                    px: 3,
                    position: "absolute",
                    top: "60%",
                    left: "-20%",
                    background: (theme: Theme) => theme.palette.secondary.main,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { lg: "20px", xs: "16px" },
                      fontWeight: 600,
                      width: "380px",
                      color: "#000",
                    }}
                  >
                    KPI Overview as a flyout with grouped information and
                    data-intensive chart
                  </Typography>
                </Box>
              </>
            )}
          </Grid>
        </Grid>
        <Box
          sx={{
            display: { lg: "none", xs: "block" },
            width: "90%",
            mx: "auto",
            "& img": {
              width: "90%",
              mx: "auto",
            },
          }}
        >
          <Slider
            {...{
              dots: true,
              infinite: true,
              nextArrow: <ChevronRight color="primary" />,
              prevArrow: <ChevronLeft color="primary" />,
              arrows: true,
              speed: 250,
              slidesToShow: 1,
              slidesToScroll: 1,
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <img src={LastSlider1} alt="No content" />
              </Grid>
              <Grid item xs={12} textAlign="center">
                <>
                  <Box
                    sx={{
                      mt: 5,
                      width: "100%",
                      "& p": {
                        fontFamily: "Caveat",
                        fontSize: { md: "22px", sm: "18px" },
                      },
                    }}
                  >
                    <Typography>
                      All data entry statuses now shown on top for quick view
                      (Role-based data)
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      py: 2.25,
                      px: 3,
                      mt: 5,
                      mx: "auto",
                      width: "fit-content",
                      background: (theme: Theme) =>
                        theme.palette.secondary.main,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { lg: "20px", xs: "16px" },
                        fontWeight: 600,
                        width: "fit-content",
                        color: "#000",
                        textAlign: "center",
                      }}
                    >
                      Quick glance of KPI entry status
                    </Typography>
                  </Box>
                </>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <img src={LastSlider2} alt="No content" />
              </Grid>
              <Grid item xs={12} textAlign="center">
                <>
                  <Box
                    sx={{
                      mt: 5,
                      width: "100%",
                      "& p": {
                        fontFamily: "Caveat",
                        fontSize: { md: "22px", sm: "18px" },
                      },
                    }}
                  >
                    <Typography>
                      Freeze moved from being a card on top, to just a button
                      (considering usage frequency)
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      py: 2.25,
                      px: 3,
                      mt: 5,
                      mx: "auto",
                      width: "fit-content",
                      background: (theme: Theme) =>
                        theme.palette.secondary.main,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { lg: "20px", xs: "16px" },
                        fontWeight: 600,
                        width: "fit-content",
                        color: "#000",
                        textAlign: "center",
                      }}
                    >
                      Freeze added next to the table and not as a card on top
                      (since only 2-3 people use it, so having it as a card on
                      top isn’t good use of space)
                    </Typography>
                  </Box>
                </>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <img src={LastSlider3} alt="No content" />
              </Grid>
              <Grid item xs={12} textAlign="center">
                <>
                  <Box
                    sx={{
                      mt: 5,
                      width: "100%",
                      "& p": {
                        fontFamily: "Caveat",
                        fontSize: { md: "22px", sm: "18px" },
                      },
                    }}
                  >
                    <Typography>
                      Table cleaned up by eliminating unnecessary columns
                      (better focus on what matters most at this point for the
                      user)
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      py: 2.25,
                      px: 3,
                      mt: 5,
                      mx: "auto",
                      width: "fit-content",
                      background: (theme: Theme) =>
                        theme.palette.secondary.main,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { lg: "20px", xs: "16px" },
                        fontWeight: 600,
                        width: "fit-content",
                        color: "#000",
                        textAlign: "center",
                      }}
                    >
                      Data Owner & Last Modified By added at a place where it’s
                      the least distracting, with all imp. columns upfront
                    </Typography>
                  </Box>
                </>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <img src={LastSlider4} alt="No content" />
              </Grid>
              <Grid item xs={12} textAlign="center">
                <>
                  <Box
                    sx={{
                      mt: 5,
                      width: "100%",
                      "& p": {
                        fontFamily: "Caveat",
                        fontSize: { md: "22px", sm: "18px" },
                      },
                    }}
                  >
                    <Typography>
                      Global Search introduced with section-wise segregation
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      py: 2.25,
                      px: 3,
                      mt: 5,
                      mx: "auto",
                      width: "fit-content",
                      background: (theme: Theme) =>
                        theme.palette.secondary.main,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { lg: "20px", xs: "16px" },
                        fontWeight: 600,
                        width: "fit-content",
                        color: "#000",
                        textAlign: "center",
                      }}
                    >
                      Search with application-wide results segregation, to aid
                      easy finding of the desired keyword
                    </Typography>
                  </Box>
                </>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <img src={Pharma3GIF} alt="No content" />
              </Grid>
              <Grid item xs={12} textAlign="center">
                <>
                  <Box
                    sx={{
                      mt: 5,
                      width: "100%",
                      "& p": {
                        fontFamily: "Caveat",
                        fontSize: { md: "22px", sm: "18px" },
                      },
                    }}
                  >
                    <Typography>
                      KPI Overview as a flyout with grouped information and
                      data-intensive chart
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      py: 2.25,
                      px: 3,
                      mt: 5,
                      mx: "auto",
                      width: "fit-content",
                      background: (theme: Theme) =>
                        theme.palette.secondary.main,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { lg: "20px", xs: "16px" },
                        fontWeight: 600,
                        width: "fit-content",
                        color: "#000",
                        textAlign: "center",
                      }}
                    >
                      Contextual information division in KPI Overview with
                      interactive chart and data drill-down
                    </Typography>
                  </Box>
                </>
              </Grid>
            </Grid>
          </Slider>
        </Box>
      </SectionSixteenWrapper>
      <SectionSeventeenWrapper id="section4">
        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: { md: "40px", sm: "32px", xs: "28px" },
              fontWeight: 700,
              fontFamily: "Mohave",
              fontStyle: "italic",
              width: "fit-content",
              // borderBottom: "4px solid",
            }}
          >
            IMPACT
          </Typography>
        </AnimationOnScroll>

        <AnimationOnScroll
          duration={0.5}
          animateOnce={true}
          animateIn="animate__fadeInUp"
        >
          <Typography
            sx={{ fontSize: { md: "28px", xs: "22px" }, fontWeight: 600 }}
          >
            Lots of happy faces...
          </Typography>
        </AnimationOnScroll>
        <Box sx={{ mt: 3 }}>
          <Box
            sx={{
              width: { lg: "640px", xs: "100%" },
              px: 3,
              py: 3.5,
              background: (theme: Theme) => theme.palette.secondary.main,
            }}
          >
            <Typography
              sx={{ fontSize: "24px", fontWeight: 600, color: "#000" }}
            >
              “The new UI really makes our jobs much easier. You guys did an
              amazing job, thank you!
            </Typography>
          </Box>
          <Box
            sx={{
              width: { lg: "640px", xs: "100%" },
              pl: 3,
              pr: 1,
              py: 1.5,
              background: "#000",
            }}
          >
            <Typography
              sx={{
                fontSize: { lg: "20px", xs: "16px" },
                fontWeight: 400,
                color: (theme: Theme) => theme.palette.secondary.main,
              }}
            >
              -Name is confidential, Director, Global Strategy & Deployment
            </Typography>
          </Box>
          <Box sx={{ ml: 6, mt: -1 }}>
            <ToolTipIcons />
          </Box>
          <Box sx={{ mt: 3 }}>
            <img src={User1} alt="no user" />
          </Box>
        </Box>
        <Box
          sx={{
            top: { lg: "60%", xs: "80%" },
            position: { lg: "absolute" },
            right: 0,
          }}
        >
          <Box
            sx={{
              width: { lg: "640px", xs: "100%" },
              px: 3,
              py: 3.5,
              mt: { lg: 0, xs: 5 },
              background: (theme: Theme) => theme.palette.secondary.main,
            }}
          >
            <Typography
              sx={{ fontSize: "24px", fontWeight: 600, color: "#000" }}
            >
              “Tasks that earlier took me about 30 minutes to complete, now will
              probably take only 5 minutes!”
            </Typography>
          </Box>
          <Box
            sx={{
              width: { lg: "640px", xs: "100%" },
              pl: 3,
              pr: 1,
              py: 1.5,
              background: "#000",
            }}
          >
            <Typography
              sx={{
                fontSize: { lg: "20px", xs: "16px" },
                fontWeight: 400,
                color: (theme: Theme) => theme.palette.secondary.main,
              }}
            >
              -Name is confidential, Manager, Data Excellence
            </Typography>
          </Box>
          <Box sx={{ mr: 6, mt: -1, textAlign: "right" }}>
            <ToolTipIcons />
          </Box>
          <Box sx={{ mt: 3, textAlign: "right" }}>
            <img src={User2} alt="no user" />
          </Box>
        </Box>
      </SectionSeventeenWrapper>

      <SectionEighteenWrapper
        sx={{
          "& div": {
            overflowX: "visible !important",
            zIndex: 0,
          },
          "&>div:hover": {
            zIndex: 1,
          },
        }}
      >
        <Box
          onMouseEnter={() => setIsPlaying(false)}
          onMouseLeave={() => setIsPlaying(true)}
          sx={{ my: 3 }}
        >
          <Marquee play={isPlaying} gradient={false} speed={50}>
            <img src={Image1} alt="No Info" />

            <img src={Image2} alt="No Info" />

            <img src={Image3} alt="No Info" />

            <img src={Image4} alt="No Info" />
          </Marquee>
        </Box>
        <Box
          onMouseEnter={() => setIsPlaying(false)}
          onMouseLeave={() => setIsPlaying(true)}
          sx={{ my: 3 }}
        >
          <Marquee
            play={isPlaying}
            gradient={false}
            speed={50}
            direction="right"
          >
            <img src={Image5} alt="No Info" />

            <img src={Image6} alt="No Info" />

            <img src={Image7} alt="No Info" />

            <img src={Image8} alt="No Info" />
          </Marquee>
        </Box>
        <Box
          onMouseEnter={() => setIsPlaying(false)}
          onMouseLeave={() => setIsPlaying(true)}
          sx={{ my: 3 }}
        >
          <Marquee play={isPlaying} gradient={false} speed={50}>
            <img src={Image9} alt="No Info" />

            <img src={Image10} alt="No Info" />

            <img src={Image11} alt="No Info" />

            <img src={Image12} alt="No Info" />

            <img src={Image13} alt="No Info" />
          </Marquee>
        </Box>
      </SectionEighteenWrapper>
    </ProjectWrapper>
  );
};

export default withAuth(Project);
