import React from "react";

const RoundText2 = () => {
  return (
    <svg
      width="206"
      height="206"
      viewBox="0 0 206 206"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_0_950)">
        <circle cx="103" cy="99" r="88" fill="#ADFF00" />
      </g>
      <path
        d="M26.0461 113.104L25.7656 111.599L32.8621 110.276C33.504 110.157 34.0767 110.181 34.5802 110.351C35.0832 110.516 35.4977 110.805 35.8238 111.216C36.1467 111.628 36.3652 112.14 36.4794 112.753C36.5844 113.316 36.5762 113.842 36.4548 114.33C36.3327 114.815 36.1005 115.224 35.758 115.557C35.4118 115.888 34.9568 116.106 34.3931 116.211L34.1135 114.711C34.3904 114.659 34.618 114.551 34.7964 114.386C34.9741 114.218 35.099 114.011 35.1712 113.765C35.2394 113.517 35.2462 113.246 35.1916 112.952C35.1321 112.633 35.0149 112.375 34.84 112.178C34.6612 111.979 34.429 111.845 34.1432 111.777C33.8574 111.709 33.5239 111.711 33.1427 111.782L26.0461 113.104Z"
        fill="black"
      />
      <path
        d="M24.9971 95.8987L25.0333 94.3579L31.7281 94.5151C32.4405 94.5318 33.0678 94.714 33.61 95.0616C34.1489 95.4091 34.5686 95.8897 34.8691 96.5035C35.1664 97.1171 35.3054 97.8315 35.2863 98.6467C35.2672 99.4585 35.0948 100.164 34.7691 100.763C34.4401 101.362 33.9983 101.822 33.4437 102.144C32.8858 102.466 32.2507 102.618 31.5383 102.602L24.8434 102.444L24.8794 100.909L31.4501 101.063C31.9107 101.074 32.3222 100.982 32.6848 100.789C33.0475 100.592 33.336 100.308 33.5502 99.9386C33.7611 99.569 33.8726 99.1273 33.8847 98.6137C33.8968 98.0968 33.8062 97.6488 33.6129 97.2696C33.4163 96.8871 33.1414 96.5922 32.7882 96.385C32.4351 96.1745 32.0283 96.0638 31.5677 96.053L24.9971 95.8987Z"
        fill="black"
      />
      <path
        d="M26.2651 84.5326L26.7571 82.7343L35.2467 81.6962L35.2782 81.5811L28.4992 76.366L28.9912 74.5677L38.8121 77.2545L38.4265 78.6643L31.3197 76.7201L31.2948 76.8112L37.5948 81.6479L37.2747 82.818L29.3851 83.7728L29.3602 83.8639L36.4718 85.8094L36.0861 87.2193L26.2651 84.5326Z"
        fill="black"
      />
      <path
        d="M41.6641 70.6316L32.7105 65.7836L34.4386 62.5921C34.8157 61.8955 35.255 61.3871 35.7563 61.0668C36.2577 60.7465 36.7899 60.5919 37.3531 60.6028C37.9134 60.6122 38.4675 60.7653 39.0155 61.0619C39.5663 61.3602 39.9995 61.7436 40.3149 62.2122C40.629 62.6762 40.7896 63.2079 40.7967 63.8073C40.8025 64.4022 40.6176 65.0465 40.242 65.7401L39.0537 67.9348L37.9083 67.3146L39.0303 65.2423C39.2686 64.8023 39.3862 64.4042 39.3829 64.0482C39.3768 63.6905 39.2759 63.3758 39.0801 63.104C38.8843 62.8321 38.6173 62.6047 38.2793 62.4216C37.9412 62.2385 37.6062 62.1401 37.2745 62.1263C36.9428 62.1125 36.6276 62.2038 36.329 62.4002C36.032 62.5937 35.762 62.9149 35.519 63.3637L34.6005 65.06L42.3955 69.2807L41.6641 70.6316Z"
        fill="black"
      />
      <path
        d="M48.0607 46.1946L47.1711 45.2161L53.0017 39.9153L53.8913 40.8938L51.5406 43.0309L57.5003 49.5861L56.3673 50.6162L50.4076 44.0609L48.0607 46.1946Z"
        fill="black"
      />
      <path
        d="M70.7876 34.3709C71.3238 35.3166 71.6117 36.2278 71.6515 37.1044C71.6897 37.9781 71.5113 38.7689 71.1163 39.4767C70.7226 40.18 70.1437 40.7482 69.3796 41.1814C68.6127 41.6163 67.8249 41.8228 67.0164 41.8012C66.209 41.7751 65.4395 41.5198 64.7077 41.0356C63.9759 40.5513 63.3428 39.8377 62.8082 38.8949C62.2721 37.9492 61.9849 37.0395 61.9467 36.1658C61.9069 35.2892 62.0839 34.4992 62.4777 33.7959C62.8726 33.0881 63.4536 32.5168 64.2205 32.082C64.9846 31.6488 65.7703 31.4452 66.5776 31.4714C67.3862 31.493 68.1563 31.746 68.8881 32.2303C69.6182 32.7117 70.2514 33.4252 70.7876 34.3709ZM69.4642 35.1212C69.0555 34.4004 68.5946 33.8597 68.0816 33.4991C67.5697 33.134 67.0401 32.939 66.4926 32.9141C65.9463 32.8847 65.4195 33.0139 64.912 33.3016C64.4017 33.5909 64.0189 33.9775 63.7636 34.4613C63.5067 34.9423 63.402 35.4969 63.4497 36.1252C63.4985 36.749 63.7273 37.4213 64.136 38.1421C64.5446 38.863 65.0049 39.4059 65.5167 39.7711C66.0298 40.1317 66.5595 40.3267 67.1057 40.3561C67.6503 40.3826 68.1778 40.2512 68.6881 39.9618C69.1956 39.6741 69.5778 39.2898 69.8347 38.8088C70.0929 38.3234 70.1975 37.7687 70.1486 37.1449C70.101 36.5166 69.8729 35.8421 69.4642 35.1212Z"
        fill="black"
      />
      <path
        d="M89.7663 33.3477L85.587 23.6432L87.1878 23.4239L90.223 30.9668L90.3166 30.954L91.2908 22.862L92.8769 22.6448L95.9916 30.1818L96.0851 30.169L96.975 22.0835L98.5807 21.8636L97.1596 32.3351L95.6277 32.5449L92.47 25.2798L92.3912 25.2905L91.303 33.1372L89.7663 33.3477Z"
        fill="black"
      />
      <path
        d="M108.476 22.2162L107.82 32.3768L106.287 32.2778L106.943 22.1172L108.476 22.2162Z"
        fill="black"
      />
      <path
        d="M114.887 33.168L117.271 23.2694L120.8 24.1194C121.567 24.3041 122.171 24.5895 122.613 24.9756C123.059 25.3625 123.351 25.8198 123.49 26.3476C123.629 26.8721 123.626 27.4389 123.479 28.0479C123.333 28.6536 123.077 29.1563 122.712 29.5557C122.35 29.9527 121.884 30.2172 121.314 30.3491C120.747 30.4818 120.08 30.4558 119.313 30.271L116.64 29.6271L116.95 28.3415L119.487 28.9527C119.97 29.0692 120.38 29.0946 120.716 29.029C121.056 28.9642 121.328 28.8168 121.534 28.5867C121.739 28.3566 121.887 28.0563 121.976 27.6858C122.066 27.312 122.071 26.9707 121.992 26.6619C121.917 26.3538 121.743 26.0904 121.472 25.8717C121.205 25.6505 120.825 25.4805 120.332 25.3618L118.456 24.91L116.38 33.5278L114.887 33.168ZM120.849 29.8792L122.219 34.9343L120.518 34.5245L119.196 29.481L120.849 29.8792Z"
        fill="black"
      />
      <path
        d="M128.84 37.3094L133.215 28.1154L138.979 30.8583L138.411 32.0524L134.034 29.9696L132.701 32.7709L136.777 34.7106L136.211 35.9002L132.135 33.9606L130.796 36.7753L135.227 38.8838L134.658 40.0779L128.84 37.3094Z"
        fill="black"
      />
      <path
        d="M140.787 43.7512L146.906 35.6132L151.953 39.4077L151.158 40.4647L147.339 37.5934L145.475 40.0729L148.932 42.6723L148.14 43.7253L144.683 41.126L142.015 44.6744L140.787 43.7512Z"
        fill="black"
      />
      <path
        d="M151.221 52.4154L158.887 45.7145L161.275 48.4469C161.795 49.0408 162.123 49.6231 162.261 50.1938C162.401 50.7669 162.375 51.3089 162.183 51.8199C161.994 52.3286 161.664 52.7891 161.192 53.2014C160.723 53.6115 160.224 53.8739 159.695 53.9887C159.17 54.1038 158.637 54.0504 158.095 53.8284C157.556 53.609 157.026 53.2023 156.507 52.6084L154.698 50.5385L155.693 49.6681L157.411 51.6333C157.738 52.0076 158.058 52.2651 158.371 52.4059C158.685 52.5491 158.993 52.5862 159.293 52.5172C159.594 52.4481 159.888 52.2881 160.175 52.0373C160.464 51.7843 160.666 51.5089 160.78 51.2112C160.896 50.916 160.906 50.6008 160.812 50.2655C160.721 49.9306 160.509 49.5723 160.176 49.1905L158.906 47.7381L152.232 53.5721L151.221 52.4154ZM157.988 53.1764L156.185 58.0938L155.033 56.7762L156.869 51.8963L157.988 53.1764Z"
        fill="black"
      />
      <path
        d="M160.647 64.5859L159.86 63.1575L170.546 61.455L171.402 63.0096L164.252 71.1311L163.465 69.7028L169.375 63.1615L169.336 63.0919L160.647 64.5859ZM164.271 62.9019L166.823 67.5353L165.691 68.1589L163.139 63.5255L164.271 62.9019Z"
        fill="black"
      />
      <path
        d="M176.505 74.1514L176.997 75.9497L170.218 81.1648L170.249 81.2799L178.739 82.318L179.231 84.1162L169.41 86.8029L169.024 85.3931L176.131 83.4489L176.106 83.3578L168.221 82.4016L167.901 81.2316L174.206 76.3936L174.181 76.3025L167.07 78.248L166.684 76.8381L176.505 74.1514Z"
        fill="black"
      />
      <path
        d="M170.326 93.9545L180.5 93.5697L180.742 99.9487L179.42 99.9987L179.237 95.1548L176.137 95.2721L176.307 99.7831L174.991 99.8329L174.82 95.3219L171.705 95.4397L171.891 100.343L170.569 100.393L170.326 93.9545Z"
        fill="black"
      />
      <path
        d="M176.644 114.259C177.117 114.286 177.513 114.132 177.833 113.797C178.156 113.462 178.367 113.008 178.466 112.433C178.536 112.021 178.532 111.654 178.453 111.331C178.377 111.009 178.241 110.745 178.044 110.539C177.85 110.338 177.611 110.213 177.327 110.164C177.088 110.123 176.873 110.143 176.681 110.225C176.488 110.309 176.317 110.433 176.168 110.596C176.022 110.762 175.891 110.945 175.776 111.144C175.665 111.343 175.568 111.537 175.485 111.725L175.062 112.661C174.929 112.967 174.766 113.278 174.575 113.591C174.383 113.905 174.153 114.186 173.885 114.436C173.617 114.686 173.306 114.871 172.952 114.992C172.598 115.117 172.195 115.14 171.744 115.063C171.176 114.965 170.697 114.731 170.306 114.362C169.915 113.995 169.64 113.513 169.48 112.914C169.32 112.318 169.307 111.628 169.442 110.844C169.571 110.093 169.802 109.463 170.134 108.955C170.467 108.447 170.879 108.076 171.37 107.84C171.864 107.606 172.416 107.524 173.026 107.595L172.765 109.114C172.398 109.081 172.075 109.147 171.798 109.311C171.524 109.479 171.3 109.715 171.127 110.018C170.957 110.325 170.838 110.673 170.771 111.062C170.698 111.49 170.7 111.882 170.776 112.238C170.856 112.598 171 112.895 171.209 113.129C171.42 113.364 171.69 113.51 172.016 113.566C172.314 113.617 172.572 113.574 172.79 113.436C173.008 113.302 173.204 113.102 173.378 112.836C173.55 112.573 173.715 112.27 173.871 111.927L174.398 110.797C174.755 110.031 175.186 109.449 175.692 109.051C176.196 108.657 176.798 108.52 177.497 108.64C178.076 108.739 178.553 108.982 178.931 109.37C179.308 109.758 179.57 110.24 179.717 110.816C179.866 111.394 179.884 112.019 179.768 112.692C179.652 113.371 179.429 113.95 179.102 114.428C178.774 114.91 178.375 115.265 177.905 115.494C177.439 115.724 176.935 115.799 176.394 115.719L176.644 114.259Z"
        fill="black"
      />
      <defs>
        <filter
          id="filter0_d_0_950"
          x="0"
          y="0"
          width="206"
          height="206"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="7.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.525 0 0 0 0 0.525 0 0 0 0 0.525 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_950"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_0_950"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default RoundText2;
