import {
  AppBar,
  Box,
  Button,
  CssBaseline,
  Drawer,
  IconButton,
  Toolbar,
  Theme,
  Modal,
  Typography,
  Popper,
  Fade,
  Paper,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import React, { useCallback, useEffect, useState } from "react";
// import SidePanelComponent from "../SidePanelComponent";
// import { useNavigate } from "react-router-dom";
// import Logo1 from "../../Assets/images/Logo1.png";
// import Logo2 from "../../Assets/images/Logo2.png";
// import Logo3 from "../../Assets/images/Logo3.png";
// import Logo4 from "../../Assets/images/Logo4.png";
// import Logo5 from "../../Assets/images/Logo5.png";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import SideComponent from "../SideComponent";
import { Close, East } from "@mui/icons-material";
import TriangleIcon from "../../Assets/Icons/TriangleIcon";
import CircleIcon from "../../Assets/Icons/CircleIcon";
import SqaureIcon from "../../Assets/Icons/SqaureIcon";
import Wave from "../../Assets/images/wave.png";
import Me from "../../Assets/images/me.jpg";
import AboutLine from "../../Assets/Icons/AboutLine";
import Logo from "../../Assets/Icons/Logo";
// the width of drawer

export const drawerWidth = 64;

const menuItems = [
  {
    name: "Resume",
    link: "https://drive.google.com/file/d/1uey6eim913OzVDYrU2rozeVqnVs5FOhd/view?usp=drivesdk",
  },
  { name: "Linkedin", link: "https://www.linkedin.com/in/meet-sai" },
];

const Header = ({
  open,
  setOpen,
  setPlayOff,
}: {
  open: boolean;
  setOpen: Function;
  setPlayOff: Function;
}) => {
  /*-------------Initailizations-------------*/

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [mobileRightOpen, setMobileRightOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { pathname } = useLocation();

  // const [Logos, setLogos] = useState(Logo1);

  const navigate = useNavigate();

  /*-------------Initailizations-------------*/

  /*----------------Handlers------------------*/

  // handles drawer toogle in mobile devices
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleRightDrawerToggle = () => {
    setMobileRightOpen(!mobileRightOpen);
  };

  // useEffect(() => {
  //   if (pathname === "/") {
  //     setLogos(Logo1);
  //     window.removeEventListener("scroll", setScrollLogos);
  //   } else {
  //     setLogos(Logo2);
  //     window.addEventListener("scroll", setScrollLogos);
  //   }
  // }, [pathname]);

  // const setScrollLogos = useCallback((e: any) => {
  //   if (pathname !== "/") {
  //     const TF = (document.body.scrollHeight * 25) / 100;
  //     const SZ = (document.body.scrollHeight * 70) / 100;
  //     const HD = document.body.scrollHeight - window.innerHeight - 100;
  //     if (window.scrollY < TF) {
  //       if (Logos !== Logo2) setLogos(Logo2);
  //     }
  //     if (window.scrollY > TF) {
  //       if (Logos !== Logo3) setLogos(Logo3);
  //     }
  //     if (window.scrollY > SZ) {
  //       if (Logos !== Logo4) setLogos(Logo4);
  //     }
  //     if (window.scrollY > HD) {
  //       if (Logos !== Logo5) setLogos(Logo5);
  //     }
  //   }
  // }, []);

  const handleClick = (e: any) => {
    setAnchorEl(e.target);
    setOpen(true);
  };

  /*----------------Handlers------------------*/

  return (
    <Box sx={{ display: "flex" }}>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement={"bottom-end"}
        transition
        sx={{ zIndex: 999999 }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper
              sx={{
                background: (theme: Theme) => theme.palette.primary.main,
                mt: 1.2,
                mr: -3,
                width: "450px",
                color: "#fff",
                borderRadius: 0,
              }}
            >
              <Box
                sx={{
                  py: 5,
                  px: 3.5,
                  borderBottom: "1px solid #fff",
                }}
              >
                <Typography
                  sx={{
                    fontSize: { md: "40px", sm: "32px", xs: "28px" },
                    fontWeight: 700,
                  }}
                >
                  This is me
                </Typography>
                <Box
                  sx={{
                    mt: 3,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "110px",
                      height: "110px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      "& img": {
                        width: "100%",
                        height: "auto",
                        borderRadius: "100%",
                        border: "5px solid #ADFF00",
                        cursor: "pointer",
                        animation: "rotation 8s infinite linear ",
                        animationPlayState: "paused",
                        "&:hover": {
                          animationPlayState: "running",
                        },
                        "@keyframes rotation": {
                          from: {
                            transform: "rotate(0deg)",
                          },
                          to: {
                            transform: "rotate(360deg)",
                          },
                        },
                      },
                    }}
                  >
                    <img src={Me} alt="no content" />
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontFamily: "Mohave",
                        color: "#ADFF00",
                        fontWeight: 700,
                        fontStyle: "italic",
                        mt: 1,
                      }}
                    >
                      ADI
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontFamily: "Mohave",
                        color: "#ADFF00",
                        fontWeight: 700,
                        fontStyle: "italic",
                      }}
                    >
                      1998 - PRESENT
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      position: "relative",
                      pl: 0.5,
                      height: "110px",
                      width: "250px",
                    }}
                  >
                    <Box sx={{ position: "absolute", top: 0 }}>
                      <AboutLine />
                    </Box>
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        background: (theme: Theme) =>
                          theme.palette.primary.main,
                        width: "150px",
                        height: "70px",
                        animation: "aboutLine 1.5s forwards",
                        "@keyframes aboutLine": {
                          from: {
                            transform: "translate(0,0)",
                          },
                          to: {
                            transform: "translate(150px,0)",
                          },
                        },
                      }}
                    ></Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { md: "22px", xs: "16px" },
                          fontWeight: 700,
                        }}
                      >
                        Hey there!
                      </Typography>
                      <img src={Wave} alt="no wave" style={{ marginTop: -1 }} />
                    </Box>
                  </Box>
                </Box>
                <Typography
                  sx={{
                    fontSize: { md: "40px", sm: "32px", xs: "28px" },
                    fontWeight: 700,
                    mt: 10,
                  }}
                >
                  A bit more...
                </Typography>
                <Typography
                  sx={{
                    mt: 1.5,
                    maxWidth: 350,
                    fontSize: { md: "24px", xs: "16px" },
                    fontWeight: 600,
                  }}
                >
                  explorer, designer (obv.), intj, learner, & concerningly calm
                </Typography>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    fontSize: { lg: "20px", xs: "16px" },
                    p: 3.5,
                    fontWeight: 700,
                  }}
                >
                  My MBTI Personality Type
                </Typography>
                <Box
                  sx={{
                    mt: { md: 0, xs: 5 },
                    ml: { sm: 3.5, xs: 1 },
                    display: "flex",
                    "& button": {
                      px: 1.5,
                      py: 1.25,
                      borderRadius: 0,
                      transition: "0.2s",
                      zIndex: 0,
                      background: "#ADFF00",
                      fontWeight: 700,
                      fontSize: "16px",
                      color: "#000",
                      "& *": {
                        textDecoration: "none",
                        color: "#000",
                      },
                      "&:hover": {
                        background: "#ADFF00",
                      },
                      "&:before": {
                        content: "''",
                        width: "100%",
                        height: "100%",
                        background: "transparent",
                        border: "2px solid #ADFF00",
                        top: 4,
                        left: 4,
                        zIndex: -1,
                        transition: "0.2s",
                        position: "absolute",
                      },
                      "&:active": {
                        transition: "0.2s",
                        "&:before": {
                          transform: "translate(-4px,-4px)",
                          zIndex: -1,
                        },
                        transform: "translate(4px,4px)",
                        zIndex: 0,
                      },
                    },
                  }}
                >
                  <Button variant="contained">
                    <a
                      href="https://www.16personalities.com/intj-personality"
                      target="_blank"
                      rel="noreferrer"
                    >
                      INTJ
                    </a>
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Fade>
        )}
      </Popper>
      <CssBaseline />
      <AppBar
        position="fixed"
        // color="transparent"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer - 1,
          boxShadow: "none",
          borderBottom: (theme) => `1.5px solid ${theme.palette.primary.main}`,
          background: "#fff",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            pl: "8px !important",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              position: "relative",
              "& img": {
                mt: 1.5,
                display:
                  pathname !== "/" ? { sm: "none", xs: "block" } : "block",
                // animation: "1s imageAnimation ease-in-out",
              },
              "@keyframes imageAnimation": {
                "0%": {
                  transform: "translateY(70px)",
                },
                "100%": {
                  transform: "translateY(0px)",
                },
              },
            }}
            onClick={() => {
              if (pathname === "/project5") {
                setPlayOff(true);
              } else {
                setPlayOff(false);
              }
              navigate("/");
            }}
          >
            {/* <Box
              sx={{
                ml: -1,
                lineHeight: 0,
                mt: 1.1,
                cursor: "pointer",
                width: "60px",
                height: "55px",
                "& svg": {
                  display:
                    pathname === "/" || pathname === "/project5"
                      ? "block"
                      : "none",
                  width: "60px",
                  height: "55px",
                },
              }}
            >
              <Logo />
            </Box> */}
            <Box
              sx={{
                alignItems: "center",
                display: pathname !== "/" ? "flex" : "none",
                ml: { sm: 8, xs: 0.5 },
                mt: 1.5,
                color: "red",
                "& div": {
                  mx: 1,
                  transition: "0.2s",
                },
              }}
            >
              <Box>
                <TriangleIcon />
              </Box>
              <Box>
                <CircleIcon />
              </Box>
              <Box>
                <SqaureIcon />
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: { sm: "block", xs: "none" },
              "& button": {
                fontSize: "22px",
                fontWeight: "500",
              },
            }}
          >
            {menuItems.map((item, index) => {
              return (
                <Button
                  key={index}
                  sx={{
                    mx: 1.5,
                    "&:hover": {
                      background: "transparent",
                    },
                    "& a": {
                      color: "#0021f5",
                      textDecoration: "none",
                    },
                  }}
                >
                  <a href={item.link} target="_blank" rel="noreferrer">
                    {item.name}
                  </a>
                </Button>
              );
            })}
            <Button
              onClick={handleClick}
              sx={{
                mx: 1.5,
                "&:hover": {
                  background: "transparent",
                },
                "& a": {
                  color: "#0021f5",
                  textDecoration: "none",
                },
              }}
            >
              About Me
            </Button>
          </Box>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleRightDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" }, color: "#0021f5" }}
          >
            <MenuIcon color="inherit" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          "& ::-webkit-scrollbar": {
            width: 0,
            height: "2px",
          },
          flexShrink: { sm: 0 },
        }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          <SideComponent
            setPlayOff={(value: any) => {
              setPlayOff(value);
            }}
          />
        </Drawer>
        <Drawer
          anchor="right"
          variant="temporary"
          open={mobileRightOpen}
          onClose={handleRightDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: "100%",
              height: "100vh",
            },
          }}
        >
          <Box sx={{ position: "absolute", right: 0 }}>
            <IconButton
              onClick={handleRightDrawerToggle}
              sx={{ color: "#0021f5" }}
            >
              <Close fontSize="large" color="inherit" />
            </IconButton>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              "& button": {
                fontSize: "22px",
                fontWeight: "500",
              },
            }}
          >
            {menuItems.map((item, index) => {
              return (
                <Button
                  key={index}
                  sx={{
                    my: 1.5,
                    "&:hover": {
                      background: "transparent",
                    },
                    "& a": {
                      color: "#0021f5",
                      textDecoration: "none",
                    },
                  }}
                >
                  <a href={item.link} target="_blank" rel="noreferrer">
                    {item.name}
                  </a>
                </Button>
              );
            })}
            <Button
              sx={{
                my: 1.5,
                "&:hover": {
                  background: "transparent",
                },
                "& a": {
                  color: "#0021f5",
                  textDecoration: "none",
                },
              }}
              onClick={handleRightDrawerToggle}
            >
              <NavLink to="/about" rel="noreferrer">
                About Me
              </NavLink>
            </Button>
          </Box>
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              background: "transparent",
              boxSizing: "border-box",
              width: drawerWidth,
              borderRight: (theme) =>
                `1.5px solid ${theme.palette.primary.main}`,
            },
          }}
          open
        >
          <SideComponent
            setPlayOff={(value: any) => {
              setPlayOff(value);
            }}
          />
        </Drawer>
      </Box>
      {pathname !== "/" && (
        <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: mobileOpen ? 67 : 2,
            width: "40px",
            height: "40px",
            borderRadius: "100%",
            background: "#000",
            display: { sm: "none", xs: "flex" },
            justifyContent: "center",
            transition: "0.2s",
            alignItems: "center",
            opacity: mobileOpen ? 1 : 0.2,
            zIndex: 9999,
            transform: mobileOpen ? "rotate(180deg)" : "rotate(0deg)",
            color: (theme: Theme) => theme.palette.secondary.main,
          }}
          onClick={handleDrawerToggle}
        >
          <East />
        </Box>
      )}
    </Box>
  );
};
export default Header;
