import { Box, Toolbar } from "@mui/material";
import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Header, { drawerWidth } from "../Components/Header";

interface ILayoutProps {
  setPlayOff: Function;
}
export const fullWidth = `100vw`;
export const fullHeight = `calc(100vh - ${drawerWidth}px)`;

const LayoutContainer = (props: ILayoutProps) => {
  const [open, setOpen] = React.useState(false);
  return (
    <Box onClick={() => open && setOpen(false)}>
      <Header
        open={open}
        setOpen={setOpen}
        setPlayOff={(value: any) => props.setPlayOff(value)}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,

          // width: {
          //   sm: fullWidth,
          // },
          height: "auto",
          // marginLeft: { sm: `${drawerWidth - 5}px` },
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
};

export default LayoutContainer;
