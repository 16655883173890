import { Box, styled } from "@mui/material";
import { fullHeight, fullWidth } from "../../LayoutContainer";

export const HomeWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  width: fullWidth,
  height: fullHeight,
  zIndex: 99,
  canvas: {
    position: "absolute",
    right: 0,
    bottom: "0",
    width: fullWidth,
    height: fullHeight,
    // zIndex: 0,
  },
}));

export const TitleWapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "95%",
  transform: "translateY(-95%)",
  display: "flex",
  paddingLeft: theme.spacing(15),
  paddingRight: theme.spacing(10),
  justifyContent: "space-between",
  alignItems: "flex-end",
  width: fullWidth,
  "& .bannerText": {
    fontSize: "70px !important",
    fontFamily: "Inter",
    fontWeight: 900,
  },

  [theme.breakpoints.down("sm")]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    flexDirection: "column",
    alignItems: "flex-start",
  },

  "@keyframes animate": {
    "0%": {
      left: 0,
    },
    "100%": {
      left: 8,
    },
  },
}));

export const ProjectSection = styled(Box)(({ theme }) => ({
  position: "relative",
  width: fullWidth,
  height: "fit-content",
}));

export const ProjectWrapperParent = styled(Box)(({ theme }) => ({
  zIndex: 999999,
  width: "100%",
  height: "100%",
  position: "absolute",
  left: 0,
  top: 0,
  background: "transparent",
}));

export const ProjectWrapper = styled(Box)(({ theme }) => ({
  borderTop: "1.5px solid " + theme.palette.primary.main,
  width: "100%",
  paddingLeft: theme.spacing(15),
  display: "flex",
  position: "relative",
  gap: theme.spacing(4),
  cursor: "pointer",
  alignItems: "center",
  "& h1": {
    fontFamily: "Mohave",
    fontStyle: "italic",
    paddingTop: `${theme.spacing(2.5)} `,
    fontWeight: 700,
    fontSize: "90px",
    transition: "0.5s",
    textTransform: "uppercase",
  },

  "&:hover .Icon": {
    visibility: "visible",
    opacity: 1,
    zIndex: 102,
  },
  "&:hover h1": {
    // zIndex: -1,
    marginLeft: theme.spacing(5),
    transition: "0.5s",
    zIndex: 102,
  },

  [theme.breakpoints.down("lg")]: {
    paddingLeft: theme.spacing(12),
    "& h1": {
      fontSize: "70px",
    },
  },
  [theme.breakpoints.down("md")]: {
    paddingLeft: theme.spacing(12),
    "& h1": {
      fontSize: "50px",
    },
  },
  [theme.breakpoints.down("sm")]: {
    paddingLeft: theme.spacing(1.5),
    "& h1": {
      fontSize: "25px",
      paddingTop: `${theme.spacing(1.25)} `,
      paddingBottom: `${theme.spacing(1.25)} `,
    },
  },
}));

export const ImageContent = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: "var(--imageY)",
  left: "var(--imageX)",
  width: "350px",
  zIndex: 100,
  // transform: "scale(1) translate3d(var(--x),var(--y),0) rotate(var(--rotate))",
  display: "block",
  transform:
    "scale(var(--scale)) translate3d(var(--x),var(--y),0) rotate(var(--rotate))",
  transition: "var(--transition)",
  "& img": {
    width: "350px",
    height: "226px",
  },
  "& > div": {
    background: "#ADFF00",
    padding: `${theme.spacing(1.25)} ${theme.spacing(1.5)}`,
    borderBottomLeftRadius: "12px",
    borderBottomRightRadius: "12px",

    marginTop: -8,
    "& p": {
      fontSize: "22px",
      color: "#000",
      fontWeight: 600,
    },
  },
}));
