import React from "react";

const CircleIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.8" cx="14" cy="14" r="14" fill="#F845E6" />
    </svg>
  );
};

export default CircleIcon;
