import { styled, Box } from "@mui/material";

export const ProjectWrapper = styled(Box)(({ theme }) => ({
  width: "calc( 100vw - 64px)",
  marginLeft: "64px",
  height: "100%",
  overflowX: "hidden",
  [theme.breakpoints.down("sm")]: {
    width: "100vw",
    marginLeft: 0,
  },
}));

export const SectionOneWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  height: "auto",
  marginTop: theme.spacing(20),
  marginLeft: theme.spacing(10),
  textTransform: "uppercase",
  "& h1": {
    fontFamily: "Mohave",
    fontStyle: "italic",
    fontWeight: 700,
    fontSize: "90px",
    transition: "0.5s",
  },
  [theme.breakpoints.down("md")]: {
    marginLeft: theme.spacing(5),
    "& h1": {
      fontSize: "60px",
    },
    "& p": {
      fontSize: "22px",
    },
  },
  [theme.breakpoints.down("sm")]: {
    marginLeft: theme.spacing(2.5),
    "& h1": {
      fontSize: "40px",
    },
    "& p": {
      fontSize: "15px",
    },
  },
}));

export const SectionTwoWrapper = styled(Box)(({ theme }) => ({
  // position: "relative",
  height: "auto",
  marginTop: theme.spacing(7),
  marginLeft: theme.spacing(5),
  // overflowX: "hidden",
  display: "flex",
}));

export const SectionThreeWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(15),
  width: "100%",
  maxWidth: "1000px",
  marginLeft: theme.spacing(7.5),
  "& p": {
    lineHeight: "30px",
    color: "#000",
  },
  [theme.breakpoints.down("lg")]: {
    width: "90%",
    marginLeft: theme.spacing(5),
  },
  [theme.breakpoints.down("md")]: {
    width: "90%",
    marginLeft: theme.spacing(5),
  },
  [theme.breakpoints.down("sm")]: {
    width: "95%",
    marginLeft: theme.spacing(2),
    paddingRight: theme.spacing(1.5),
  },
}));
export const SectionFourWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(15),
  width: "100%",
  maxWidth: "1000px",
  marginLeft: theme.spacing(7.5),
  "& p": {
    lineHeight: "30px",
    color: "#000",
  },
  [theme.breakpoints.down("lg")]: {
    width: "90%",
    marginLeft: theme.spacing(5),
  },
  [theme.breakpoints.down("md")]: {
    width: "90%",
    marginLeft: theme.spacing(5),
  },
  [theme.breakpoints.down("sm")]: {
    width: "95%",
    marginLeft: theme.spacing(2),

    paddingRight: theme.spacing(1.5),
  },
}));

export const SectionFiveWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(15),
  width: "100%",
  maxWidth: "1000px",
  height: "auto",
  marginLeft: theme.spacing(7.5),
  "& p": {
    lineHeight: "30px",
    color: "#000",
  },
  [theme.breakpoints.down("lg")]: {
    width: "90%",
    marginLeft: theme.spacing(5),
  },
  [theme.breakpoints.down("md")]: {
    width: "90%",
    marginLeft: theme.spacing(5),
  },
  [theme.breakpoints.down("sm")]: {
    width: "95%",
    marginLeft: theme.spacing(2),

    paddingRight: theme.spacing(1.5),
  },
}));

export const SectionSixWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(15),
  width: "100%",
  height: "auto",
  paddingRight: theme.spacing(7.5),
  "& p": {
    lineHeight: "30px",
    color: "#000",
  },
  [theme.breakpoints.down("lg")]: {
    height: "100%",
    marginLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  [theme.breakpoints.down("md")]: {
    width: "90%",
    marginLeft: theme.spacing(5),
  },
  [theme.breakpoints.down("sm")]: {
    marginLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export const CarouselItem = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
}));

export const SectionSevenWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(15),
  width: "100%",
  maxWidth: "1000px",
  height: "auto",
  marginLeft: theme.spacing(7.5),
  paddingRight: theme.spacing(7.5),
  "& p": {
    lineHeight: "30px",
    color: "#000",
  },
  [theme.breakpoints.down("lg")]: {
    width: "90%",
    marginLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  [theme.breakpoints.down("md")]: {
    width: "90%",
    marginLeft: theme.spacing(5),
  },
  [theme.breakpoints.down("sm")]: {
    width: "95%",
    marginLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export const SectionEightWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(15),
  width: "100%",
  maxWidth: "1000px",
  height: "auto",
  marginLeft: theme.spacing(7.5),
  paddingRight: theme.spacing(7.5),
  "& p": {
    lineHeight: "30px",
    color: "#000",
  },
  [theme.breakpoints.down("lg")]: {
    width: "90%",
    marginLeft: theme.spacing(5),
  },
  [theme.breakpoints.down("md")]: {
    width: "90%",
    marginLeft: theme.spacing(5),
  },
  [theme.breakpoints.down("sm")]: {
    width: "95%",
    marginLeft: theme.spacing(2),

    paddingRight: theme.spacing(1.5),
  },
}));

export const SectionNineWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(15),
  position: "relative",
  width: "100%",
  maxWidth: "1110px",
  height: "auto",
  marginLeft: theme.spacing(7.5),
  paddingRight: theme.spacing(7.5),
  "& p": {
    lineHeight: "30px",
    color: "#000",
  },
  "@keyframes rotation": {
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: "rotate(360deg)",
    },
  },
  [theme.breakpoints.down("lg")]: {
    width: "90%",
    marginLeft: theme.spacing(5),
  },
  [theme.breakpoints.down("md")]: {
    width: "90%",
    marginLeft: theme.spacing(5),
  },
  [theme.breakpoints.down("sm")]: {
    width: "95%",
    marginLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export const SectionTenWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  marginTop: theme.spacing(15),
  width: "100%",
  maxWidth: "1000px",
  height: "auto",
  marginLeft: theme.spacing(7.5),
  [theme.breakpoints.down("lg")]: {
    width: "90%",
    marginLeft: theme.spacing(5),
  },
  [theme.breakpoints.down("md")]: {
    width: "90%",
    marginLeft: theme.spacing(5),
  },
  [theme.breakpoints.down("sm")]: {
    width: "95%",
    marginLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export const SectionElevanWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(15),
  width: "100%",
  maxWidth: "1472px",
  height: "auto",
  // marginLeft: theme.spacing(7.5),
  paddingRight: theme.spacing(7.5),
  "& p": {
    lineHeight: "30px",
    color: "#000",
  },
  "& .active": {
    transition: "0.5s",
    background: theme.palette.secondary.main,
    fontWeight: 700,
    padding: `${theme.spacing(3)} ${theme.spacing(2.25)}`,
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    paddingRight: theme.spacing(2),
  },
}));

export const SectionTwelveWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(15),
  width: "calc(100% - 64px)",
  maxWidth: "1440px",
  height: "auto",
  marginLeft: theme.spacing(7.5),
  paddingRight: theme.spacing(7.5),
  [theme.breakpoints.down("lg")]: {
    width: "90%",
    marginLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  [theme.breakpoints.down("md")]: {
    width: "90%",
    marginLeft: theme.spacing(5),
  },
  [theme.breakpoints.down("sm")]: {
    width: "95%",
    marginLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export const SectionThirteenWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  marginTop: theme.spacing(10),
  width: "100%",
  maxWidth: "1536px",
  ".lineAnimation.active": {
    animation: "pencil 1.5s forwards",
    animationDelay: "1s",
  },
  "@keyframes pencil": {
    "0%": {
      transform: "translateX(0)",
    },
    "100%": {
      transform: "translateX(-320px)",
    },
  },
  "& *": {
    fontFamily: "Caveat",
    color: "#000",
    fontSize: "22px",
  },
}));

export const SectionFourteenWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
  maxWidth: "1536px",
  marginTop: theme.spacing(10),
  ".lineAnimation.active": {
    animation: "pencil2 1.5s forwards",
    animationDelay: "1s",
  },
  "@keyframes pencil2": {
    "0%": {
      transform: "translateX(0)",
    },
    "100%": {
      transform: "translateX(320px)",
    },
  },
  "& *": {
    fontFamily: "Caveat",
    color: "#000",
    fontSize: "22px",
  },
}));

export const SectionFifteenWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(15),
  width: "100%",
  maxWidth: "1000px",
  height: "auto",
  marginLeft: theme.spacing(7.5),
  "& p": {
    lineHeight: "30px",
    color: "#000",
  },
  [theme.breakpoints.down("lg")]: {
    width: "90%",
    marginLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  [theme.breakpoints.down("md")]: {
    width: "90%",
    marginLeft: theme.spacing(5),
  },
  [theme.breakpoints.down("sm")]: {
    width: "95%",
    marginLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export const SectionSixteenWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(15),
  width: "100%",
  maxWidth: "1472px",
  height: "auto",
  // marginLeft: theme.spacing(7.5),
  paddingRight: theme.spacing(7.5),
  scrollSnapType: "y mandatory",
  "& p": {
    lineHeight: "30px",
    color: "#000",
  },
  "& img": {
    boxShadow: "4px 0px 20px 0px #66666640",
    borderRadius: "12px",
  },
  "& .active": {
    transition: "0.5s",
    transform: "skewX(0deg) !important",
    width: "80% !important",
    bottom: 0,
    right: "15% !important",
    zIndex: "20 !important",
  },
  "& .deactive": {
    opacity: 0,
    transition: "0.5s",
  },
  "& .reactive:nth-of-type(1)": {
    transform: "skewX(-5deg)",
    width: "70%",
    bottom: "20px",
    right: "140px",
    zIndex: 10,
    transition: "0.5s",
  },

  "& .reactive:nth-of-type(2)": {
    transform: "skewX(-12deg)",
    width: "65%",
    bottom: "20px",
    right: "130px",
    transition: "0.5s",
  },
  [theme.breakpoints.down("xl")]: {
    paddingRight: theme.spacing(5),
    "& .active": {
      transition: "0.5s",
      transform: "skewX(0deg) !important",
      width: "80% !important",
      bottom: 0,
      right: "15% !important",
      zIndex: "20 !important",
    },
    "& .reactive:nth-of-type(1)": {
      transform: "skewX(-5deg)",
      width: "70%",
      bottom: "20px",
      right: "120px",
      zIndex: 10,
      transition: "0.5s",
    },

    "& .reactive:nth-of-type(2)": {
      transform: "skewX(-12deg)",
      width: "65%",
      bottom: "20px",
      right: "130px",
      transition: "0.5s",
    },
  },
  [theme.breakpoints.down("lg")]: {
    // marginLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    "& img": {
      marginTop: theme.spacing(5),
      boxShadow: "none",
      borderRadius: "12px",
    },
  },
  [theme.breakpoints.down("sm")]: {
    width: "95%",
    marginLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

export const SliderTracker = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: -50,
  right: "60px",
  width: "80px",
  height: "80px",
  borderRadius: "100%",
  background: "#F845E6",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: "5px",
  "& p": {
    fontSize: "40px",
    fontWeight: 700,
    fontFamily: "Mohave",
    fontStyle: "italic",
    lineHeight: "55.72px",
    color: "#fff !important",
    marginLeft: "-5px",
  },
}));

export const SectionSeventeenWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(15),
  width: "100%",
  maxWidth: "1000px",
  position: "relative",
  height: "160vh",
  marginLeft: theme.spacing(7.5),
  [theme.breakpoints.down("lg")]: {
    width: "90%",
    height: "100%",
    marginLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  [theme.breakpoints.down("md")]: {
    width: "90%",
    marginLeft: theme.spacing(5),
  },
  [theme.breakpoints.down("sm")]: {
    width: "95%",
    marginLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export const SectionEighteenWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(15),
  width: "calc(100vw - 64px)",
  position: "relative",
  height: "auto",
  marginLeft: theme.spacing(7.5),
  paddingRight: theme.spacing(7.5),
  "& *": {
    zIndex: 0,
  },
  "& img": {
    transform: "scale(1)",
    transition: "0.3s",
    width: "436px",
    height: "auto",
    boxShadow: "4px 0px 20px 0px #66666640",
    margin: "30px",
    borderRadius: "12px",
    "&:hover": { transform: "scale(1.5)", zIndex: 99 },
  },
  [theme.breakpoints.down("md")]: {
    "& img": {
      transform: "scale(1)",
      transition: "0.3s",
      width: "250px",
      height: "auto",
      boxShadow: "4px 0px 20px 0px #66666640",
      margin: "12px",
      borderRadius: "12px",
      "&:hover": { transform: "scale(1.5)", zIndex: 99 },
    },
  },
}));
