import { Box } from "@mui/material";
import React, { useEffect, useRef } from "react";

interface MouseBoxProps {
  handleClick?: Function;
  MouseMove?: Function;
  boxRef?: any;
}

const MouseBox = ({ handleClick, MouseMove, boxRef }: MouseBoxProps) => {
  const box = useRef<any>();

  useEffect(() => {
    window.addEventListener("mousemove", (e: any) => {
      if (MouseMove) {
        MouseMove(e);
      } else {
        box.current.style.visibility = "visible";
        box.current.style.transform =
          "translate(" +
          (e.clientX - box.current.offsetWidth / 2) +
          "px," +
          (e.clientY - box.current.offsetHeight / 2) +
          "px)";
      }
    });
  }, []);

  return (
    <Box
      id="box"
      ref={boxRef ?? box}
      onClick={() => handleClick && handleClick()}
      sx={{
        width: "15px",
        height: "15px",
        background: "#ADFF00",
        position: "fixed",
        fontFamily: "Mohave",
        backgroundRepeat: "no-repeat",
        fontSize: "0px",
        color: "#000",
        fontWeight: 700,
        // cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        top: 0,
        left: 0,
        zIndex: 0,
        // transition: "0.1s",
      }}
    ></Box>
  );
};

export default MouseBox;
