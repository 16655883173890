import { styled, Box } from "@mui/material";

export const ProjectWrapper = styled(Box)(({ theme }) => ({
  width: "calc( 100vw - 64px)",
  marginLeft: "64px",
  height: "100%",
  overflowX: "hidden",
  [theme.breakpoints.down("sm")]: {
    width: "100vw",
    marginLeft: 0,
  },
}));

export const SectionOneWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  height: "auto",
  marginTop: theme.spacing(20),
  marginLeft: theme.spacing(10),
  textTransform: "uppercase",
  "& h1": {
    fontFamily: "Mohave",
    fontStyle: "italic",
    fontWeight: 700,
    fontSize: "90px",
    transition: "0.5s",
  },
  [theme.breakpoints.down("md")]: {
    marginLeft: theme.spacing(5),
    "& h1": {
      fontSize: "60px",
    },
    "& p": {
      fontSize: "22px",
    },
  },
  [theme.breakpoints.down("sm")]: {
    marginLeft: theme.spacing(2.5),
    "& h1": {
      fontSize: "40px",
    },
    "& p": {
      fontSize: "15px",
    },
  },
}));

export const SectionTwoWrapper = styled(Box)(({ theme }) => ({
  // position: "relative",
  height: "auto",
  marginTop: theme.spacing(7),
  marginLeft: theme.spacing(5),
  // overflowX: "hidden",
  display: "flex",
}));

export const SectionThreeWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(15),
  width: "65%",
  marginLeft: theme.spacing(7.5),
  "& p": {
    lineHeight: "30px",
    color: "#000",
  },
  [theme.breakpoints.down("lg")]: {
    width: "90%",
    marginLeft: theme.spacing(5),
  },
  [theme.breakpoints.down("md")]: {
    width: "90%",
    marginLeft: theme.spacing(5),
  },
  [theme.breakpoints.down("sm")]: {
    width: "95%",
    marginLeft: theme.spacing(2),
  },
}));

export const SectionEightWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(15),
  width: "calc(100% - 64px)",
  height: "auto",
  marginLeft: theme.spacing(7.5),
  paddingRight: theme.spacing(7.5),
  "& p": {
    lineHeight: "30px",
    color: "#000",
  },
  [theme.breakpoints.down("lg")]: {
    width: "90%",
    marginLeft: theme.spacing(5),
  },
  [theme.breakpoints.down("md")]: {
    width: "90%",
    marginLeft: theme.spacing(5),
  },
  [theme.breakpoints.down("sm")]: {
    width: "95%",
    marginLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export const SectionTwelveWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(15),
  width: "calc(100% - 64px)",
  height: "auto",
  marginLeft: theme.spacing(7.5),
  paddingRight: theme.spacing(7.5),
  [theme.breakpoints.down("lg")]: {
    width: "90%",
    marginLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  [theme.breakpoints.down("md")]: {
    width: "90%",
    marginLeft: theme.spacing(5),
  },
  [theme.breakpoints.down("sm")]: {
    width: "95%",
    marginLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export const SectionThirteenWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  marginTop: theme.spacing(10),
  // display: "flex",
  // justifyContent: "space-between",
  marginLeft: theme.spacing(7.5),
  width: "1000px",
  "& img": {
    borderRadius: "12px",
    boxShadow: "4px 0px 20px 0px #66666640",
    marginLeft: 10,
  },
  ".lineAnimation.active": {
    animation: "firstPencil 2s forwards",
    animationDelay: "2s",
  },
  "@keyframes firstPencil": {
    "0%": {
      transform: "translateX(0)",
    },
    "100%": {
      transform: "translateX(380px)",
    },
  },
  "& *": {
    fontFamily: "Caveat",
    color: "#000",
    fontSize: "22px !important",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    marginLeft: theme.spacing(5),
  },
  [theme.breakpoints.down("md")]: {
    width: "90%",
    marginLeft: theme.spacing(5),
    "& *": {
      fontFamily: "Caveat",
      color: "#000",
      fontSize: "18px !important",
    },
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    flexWrap: "wrap",
    gap: "30px",
    marginLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export const SectionFourteenWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  marginTop: theme.spacing(10),
  // display: "flex",

  // justifyContent: "space-between",
  marginLeft: theme.spacing(7.5),
  width: "1000px",
  "& img": {
    borderRadius: "12px",
    boxShadow: "4px 0px 20px 0px #66666640",
    marginLeft: 10,
  },
  ".lineAnimation.active": {
    animation: "pencil 2s forwards",
    animationDelay: "2s",
  },

  "@keyframes pencil": {
    "0%": {
      transform: "translateX(0)",
    },
    "100%": {
      transform: "translateX(220px)",
    },
  },
  "& *": {
    fontFamily: "Caveat",
    color: "#000",
    fontSize: "22px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    marginLeft: theme.spacing(5),
  },
  [theme.breakpoints.down("md")]: {
    width: "90%",
    marginLeft: theme.spacing(5),
  },
  [theme.breakpoints.down("sm")]: {
    width: "95%",
    flexWrap: "wrap",
    gap: "30px",
    marginLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export const SectionFifteenWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(15),
  width: "100%",
  height: "auto",
  "& p": {
    lineHeight: "30px",
    color: "#000",
  },
  "& *": {
    zIndex: 0,
  },
  "& img": {
    transform: "scale(1)",
    transition: "0.3s",
    margin: "14px",
    boxShadow: "4px 0px 20px 0px #66666640",
    borderRadius: "12px",
    "&:hover": { transform: "scale(1.2)", zIndex: 99 },
  },
  [theme.breakpoints.down("lg")]: {
    width: "90%",
    marginLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  [theme.breakpoints.down("md")]: {
    width: "90%",
    marginLeft: theme.spacing(5),
  },
  [theme.breakpoints.down("sm")]: {
    width: "95%",
    marginLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));
